<template>
  <v-container fluid class="NewSale pa-1">
    <v-row v-if="sale">
      <v-col cols="9" class="pr-1 py-1">
        <v-card outlined height="100%">
          <v-container class="py-0">
            <v-row
              v-if="
                sale.status.name != 'PAGADO' && sale.status.name != 'CANCELADA'
              "
            >
              <v-col cols="9" class="pb-1">
                <ProductSelectInput></ProductSelectInput>
                <ServiceSelectInput v-if="sale.status.name == 'PREVENTA'"></ServiceSelectInput>
              </v-col>
              <v-col cols="3" class="d-flex align-center pb-1">
                <v-btn
                  color="primary"
                  @click="AddSaleProduct()"
                  :disabled="!product"
                  block
                >
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
            <v-divider
              v-if="
                sale.status.name != 'PAGADO' && sale.status.name != 'CANCELADA'
              "
            ></v-divider>
            <v-row>
              <v-col class="pt-0">
                <SaleProducts> </SaleProducts>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="3" class="pl-1 py-1">
        <SaleFolio class="mb-2"></SaleFolio>
        <SaleCustomer class="mb-2"></SaleCustomer>
        <SaleSummary></SaleSummary>
        <v-btn
          v-if="sale.status.name != 'PAGADO' && sale.status.name != 'CANCELADA'"
          block
          color="accent darken-1"
          class="mt-2 py-6"
          :disabled="!$store.getters.getSaleDetails.length"
          @click="presale()"
        >
          {{
            sale && sale.status.name == "PREVENTA" ? "PAGAR" : " Terminar Venta"
          }}
        </v-btn>
        <div v-if="sale.status.name != 'NUEVO'">
          <v-btn
            v-if="
              $store.getters.getSaleDetails.length > 4 &&
                sale.status.name != 'CANCELADA'
            "
            block
            outlined
            color="error"
            class="mt-2 py-6"
            @click="cancel()"
          >
            Cancelar Venta
          </v-btn>
          <v-btn
            v-if="$store.getters.getSaleDetails.length > 4"
            block
            outlined
            color="accent darken-1"
            class="mt-2 py-6"
            @click="returnToCashRegister()"
          >
            Volver a Caja
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="sale && sale.status.name != 'NUEVO'">
      <v-col cols="12" class="d-flex pb-0">
        <v-btn
          outlined
          color="accent darken-1"
          class="mr-2"
          @click="returnToCashRegister()"
        >
          Volver a Caja
        </v-btn>
        <v-btn
          v-if="sale.status.name != 'CANCELADA'"
          outlined
          color="error"
          @click="cancel()"
        >
          Cancelar Venta
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            $store.getters.getSaleDetails.length > 4 &&
              sale.status.name != 'PAGADO' &&
              sale.status.name != 'CANCELADA'
          "
          color="accent darken-1"
          class="px-3"
        >
          {{
            sale && sale.status.name == "PREVENTA" ? "PAGAR" : " Terminar Venta"
          }}
        </v-btn>
      </v-col>
    </v-row>
    <ClientSelectModal></ClientSelectModal>
    <SaleClientModal></SaleClientModal>
    <v-overlay :value="loading" :z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmCancelModal redirect="/paysales"></ConfirmCancelModal>
    <PaymentModal></PaymentModal>
    <TicketModal redirect="/paysales"></TicketModal>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import PaymentModal from "../CashRegister/PaymentModal";
import ConfirmCancelModal from "../ConfirmCancelModal";
import ProductSelectInput from "./ProductSelectInput";
import ServiceSelectInput from "./ServiceSelectInput";
import ClientSelectModal from "./ClientSelectModal";
import SaleClientModal from "./SaleClientModal";
import SaleProducts from "./SaleProducts";
import SaleCustomer from "./SaleCustomer";
import TicketModal from "../TicketModal";
import SaleSummary from "./SaleSummary";
import SaleFolio from "./SaleFolio";

export default {
  name: "NewSale",
  components: {
    ConfirmCancelModal,
    ProductSelectInput,
    ServiceSelectInput,
    ClientSelectModal,
    SaleClientModal,
    PaymentModal,
    SaleProducts,
    SaleCustomer,
    SaleSummary,
    TicketModal,
    SaleFolio,
  },
  created() {
    this.$store.commit("SET_SALE", null);
    this.$store.dispatch("FetchSale", this.$route.params.id);
  },
  computed: {
    ...mapFields({
      product: "sales.saleProduct",
      loading: "sales.loading" || "sales.updateSaleStatus",
      sale: "sales.sale"
    })
  },
  methods: {
    AddSaleProduct() {
      if (this.product != null) {
        this.$store
          .dispatch("AddSaleDetail", {
            quantity: 1,
            discount: 0,
            product_id: this.product.id,
            promotion: 0,
            type: this.product.type,
            sale_price: this.product.sale_price
          })
          .then(r => {
            if (!r.error) {
              this.product = null;
              this.$store.dispatch("FetchSale", this.$route.params.id);
              this.$store.dispatch("LoadBranchProduct");
            } else {
              this.$snotify.error("Error al Agregar Producto");
            }
          });
      }
    },
    presale() {
      if (this.sale.status.name == "PREVENTA") {
        this.$store.commit("SET_PAYSALE", this.sale);
        this.$store.commit("openPaymentModal");
      } else {
        this.$store
          .dispatch("UpdateStatus", {
            id: this.$route.params.id,
            status: "presale"
          })
          .then(r => {
            if (!r.error) {
              this.$store.commit("SET_SALE", null);
              this.$router.push({
                path: `/ventas`
              });
            } else {
              this.$snotify.error("Error al Terminar Venta");
            }
          });
      }
    },
    returnToCashRegister() {
      // this.$router.push({
      //   path: `/paysales`
      // });
      this.$router.go(-1);
    },
    cancel() {
      this.$store.commit("openCancelSaleModal");
    }
  }
};
</script>

<style lang="scss">
.NewSale {
  table {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
