<template>
  <v-data-table
    class="SaleProducts"
    :headers="headers"
    :items="$store.getters.getSaleDetails"
    :items-per-page="100"
    hide-default-footer
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-row v-if="item.product">
        <v-col cols="auto" class="px-0 py-1">
          <v-avatar color="transparent" size="100" tile>
            <v-img
              v-if="item.image"
              :src="`${item.image}`"
              alt="NOT FOUND"
            ></v-img>
            <v-icon v-else x-large>mdi-image-off</v-icon>
          </v-avatar>
        </v-col>
        <v-col class="px-1 py-1" style="width: 132px;">
          <v-card class="elevation-0">
            <v-list-item three-line class="px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold text-uppercase text-caption"
                >
                  {{ item.product }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                  {{ item.presentation || "... ... ..." }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-btn
                    v-if="status != 'PAGADO' && status != 'CANCELADA'"
                    text
                    color="error lighten-2"
                    small
                    @click="delete_product(item)"
                  >
                    Eliminar
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <span v-else></span>
    </template>
    <template v-slot:[`item.quantity`]="props">
      <div v-if="props.item.quantity">
        <v-edit-dialog
          v-if="status != 'PAGADO' && status != 'CANCELADA'"
          large
          persistent
          save-text="Guardar"
          cancel-text="Cancelar"
          @save="save(props.item)"
          @cancel="cancel"
          @open="open(props.item.quantity)"
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="props.item.quantity"
                single-line
                outlined
                hide-details
                dense
                readonly
                full-width
                :append-outer-icon="'mdi-plus'"
                :prepend-icon="'mdi-minus'"
                @click:append-outer="add_quantity(props.item)"
                @click:prepend="minus_quantity(props.item)"
                class="item-quantity"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <template v-slot:input>
            <div class="mt-1 title">
              Cantidad
            </div>
            <v-text-field
              v-model="temp_quantity"
              type="number"
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
        <span v-else>{{ props.item.quantity }}</span>
      </div>
      <span v-else></span>
    </template>
    <template v-slot:[`item.price`]="{ item }">
      {{ round(item.price) }}
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      {{ round(item.amount) }}
    </template>
    <template v-slot:no-data>
      <v-row class="mt-3" align-content="center" justify="center">
        <v-alert text color="secondary" icon="mdi-alert-circle">
          La venta no tiene productos
        </v-alert>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "SaleProducts",
  data: () => ({
    server: process.env.VUE_APP_API_SERVER,
    temp_quantity: null,
    headers: [
      {
        text: "Producto",
        align: "start",
        sortable: false,
        value: "name"
      },
      {
        text: "Cantidad",
        align: "center",
        sortable: false,
        value: "quantity",
        width: "160"
      },
      {
        text: "Precio",
        align: "end",
        sortable: false,
        value: "price",
        class: "text-center-header",
        width: "130"
      },
      {
        text: "Total",
        align: "start",
        sortable: false,
        value: "amount",
        class: "text-center-header",
        width: "130"
      }
    ]
  }),
  computed: {
    status() {
      return this.$store.state.sales.sale
        ? this.$store.state.sales.sale.status.name
        : null;
    }
  },
  methods: {
    add_quantity(item) {
      this.updateStock(item, item.quantity + 1);
    },
    minus_quantity(item) {
      this.updateStock(item, item.quantity - 1);
    },
    delete_product(item) {
      this.updateStock(item, 0);
    },
    open(quantity) {
      this.temp_quantity = quantity;
    },
    save(item) {
      this.updateStock(item, Number.parseInt(this.temp_quantity || 0));
    },
    cancel() {
      this.temp_quantity = null;
    },
    round(price) {
      var temp = Number.parseFloat(price).toFixed(2);
      return temp && !isNaN(temp) ? `$ ${temp}` : price;
    },
    updateStock(detail, quantity) {
      console.log(detail)
      this.$store
        .dispatch("UpdateStock", {
          id: detail.id,
          detail: {
            quantity: quantity,
            discount: 0,
            product_id: detail.product_id,
            promotion: 0,
            type: detail.type
          }
        })
        .then(r => {
          if (!r.error) {
            this.product = null;
            this.$store.dispatch("FetchSale", this.$route.params.id);
            this.$store.dispatch("LoadBranchProduct");
          } else {
            this.$snotify.error("Error Actualizando el Stock");
          }
        });
    }
  }
};
</script>

<style lang="scss">
.SaleProducts {
  .text-center-header {
    text-align: center !important;
  }
  .item-quantity {
    .v-text-field__slot input {
      text-align: center;
    }
  }
  table {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
