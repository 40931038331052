<template>
  <v-container>
    <ExamplePart></ExamplePart>
  </v-container>
</template>

<script>
import ExamplePart from "../components/Example/ExamplePart";

export default {
  name: "Home",
  components: {
    ExamplePart,
  },
};
</script>
