var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"SaleOptions ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[(_vm.sale.status.name == 'NUEVO')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.edit_sale()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent darken-2"}},[_vm._v("mdi-pencil")])],1)]}}],null,false,1005765252)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(_vm.sale.status.name == 'NUEVO')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.sale.amount == 0},on:{"click":function($event){return _vm.updateSaleStatus('presale')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-cash-register")])],1)]}}],null,false,4075903959)},[_c('span',[_vm._v("Terminar Venta")])]):_vm._e(),(_vm.sale.status.name == 'NUEVO' && _vm.sale.amount > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.cancel_sale()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-cancel")])],1)]}}],null,false,2805432875)},[_c('span',[_vm._v("Cancelar")])]):_vm._e(),(_vm.sale.status.name == 'PAGADO')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.print_sale()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent darken-1"}},[_vm._v("mdi-printer")])],1)]}}],null,false,4087222353)},[_c('span',[_vm._v("Imprimir")])]):_vm._e(),(_vm.sale.status.name != 'NUEVO')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.show_sale()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary darken-1"}},[_vm._v("mdi-eye")])],1)]}}],null,false,2361597326)},[_c('span',[_vm._v("Ver")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }