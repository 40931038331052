<template>
  <div class="SalesDetailTable">
    <div
      style="height: 320px; display: flex; flex-direction: column"
      class="ag-theme-alpine"
    >
      <ag-grid-vue
        style="flex: 1 1 auto;"
        :gridOptions="topGridOptions"
        @first-data-rendered="onFirstDataRendered"
        :columnDefs="columnDefs"
        :rowData="sales"
        :modules="modules"
        :animateRows="true"
        :localeText="localeText"
      ></ag-grid-vue>
      <ag-grid-vue
        style="height: 60px; flex: none;"
        :gridOptions="bottomGridOptions"
        :headerHeight="0"
        :columnDefs="columnDefs"
        :rowData="total"
        :modules="modules"
        :rowStyle="rowStyle"
        :animateRows="true"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";

import { AgGridVue } from "ag-grid-vue";
import { AllCommunityModules } from "@ag-grid-community/all-modules";

export default {
  name: "SalesDetailTable",
  props: {
    sales: {
      type: Array,
      default: () => []
    },
    total: {
      type: Array,
      default: () => []
    }
  },
  components: {
    AgGridVue
  },
  data: () => ({
    topGridOptions: null,
    bottomGridOptions: null,
    gridApi: null,
    columnApi: null,
    rowData: null,
    bottomData: null,
    columnDefs: null,
    athleteVisible: true,
    ageVisible: true,
    countryVisible: true,
    rowStyle: { fontWeight: "bold" },
    modules: AllCommunityModules,
    localeText: null
  }),
  beforeMount() {
    var formatNumber = function formatNumber(params) {
      var number = params.value;
      return number ? `$ ${Number.parseFloat(number).toFixed(2)}` : "";
    };

    this.topGridOptions = {
      alignedGrids: [],
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: false,
        flex: 1,
        minWidth: 100
      },
      suppressHorizontalScroll: true
    };
    this.bottomGridOptions = {
      alignedGrids: [],
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: false,
        flex: 1,
        minWidth: 100
      }
    };
    this.topGridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.topGridOptions);

    this.columnDefs = [
      { headerName: "Folio", field: "folio", filter: true },
      { headerName: "Producto Vendido", field: "product_name", filter: true },
      { headerName: "Codigo barras", field: "product_barcode", filter: true },
      { headerName: "Lote", field: "lot_code" },
      { headerName: "Tipo", field: "product_type", filter: true },
      { headerName: "Cantidad", field: "quantity" },
      {
        headerName: "Costo Compra",
        field: "product_purchase_price",
        valueFormatter: formatNumber
      },
      {
        headerName: "Precio de lista",
        field: "product_sale_price",
        valueFormatter: formatNumber
      },
      {
        headerName: "Subtotal",
        field: "amount_subtotal",
        valueFormatter: formatNumber
      },
      {
        headerName: "Descuento",
        field: "amount_discount",
        valueFormatter: formatNumber
      },
      {
        headerName: "Total",
        field: "amount_total",
        valueFormatter: formatNumber
      },
      { headerName: "Margen", field: "margin", valueFormatter: formatNumber }
    ];

    this.localeText = {
      // Number Filter & Text Filter
      filterOoo: "Filtrar...",
      equals: "Igual",
      notEqual: "No  igual",
      empty: "Elige uno",
      // Filter Conditions
      andCondition: "Y",
      orCondition: "O",
      // Text Filter
      contains: "Contiene",
      notContains: "No contiene",
      startsWith: "Inicia con",
      endsWith: "Termina con",
      // Other
      loadingOoo: "Cargando...",
      noRowsToShow: "No hay ventas para mostrar",
      enabled: "Activo"
    };
  },
  mounted() {
    this.gridApi = this.topGridOptions.api;
    this.gridColumnApi = this.topGridOptions.columnApi;
  },
  methods: {
    onFirstDataRendered: function() {
      this.gridColumnApi.autoSizeAllColumns();
    }
  }
};
</script>

<style lang="scss" scoped></style>
