<template>
  <v-form class="LotForm" ref="LotForm" v-model="valid" lazy-validation>
    <v-container class="pa-0">
      <ProductInfoCard
        v-if="product != null"
        :product="product"
      ></ProductInfoCard>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            id="select-product"
            v-model="product"
            :items="$store.getters.getOnlyProducts"
            chips
            label="Producto"
            item-text="name"
            item-value="id"
            return-object
            clearable
            :rules="[v => !!v || 'El Producto es obligatorio.']"
            :disabled="$store.state.lots.saveLoading"
            :filter="customSearch"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                @click="data.select"
                label
              >
                <v-avatar left>
                  <v-img
                    v-if="data.item.image"
                    :src="`${data.item.image}`"
                    alt="NOT FOUND"
                  ></v-img>
                  <v-icon v-else x-large>mdi-image-off</v-icon>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <v-img
                    v-if="data.item.image"
                    :src="`${data.item.image}`"
                    alt="NOT FOUND"
                  ></v-img>
                  <v-icon v-else x-large>mdi-image-off</v-icon>
                </v-list-item-avatar>
                <v-list-item-content style="max-width: 540px;">
                  <v-list-item-title class="text-uppercase">{{
                    data.item.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    class="text-caption"
                    v-text="data.item.presentation"
                  ></v-list-item-subtitle>

                  <v-list-item-subtitle
                    class="text-truncate"
                    v-text="data.item.description"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            id="select-branch"
            v-model="form.branch_id"
            :items="$store.getters.getBranches"
            chips
            label="Sucursal"
            item-text="name"
            item-value="id"
            clearable
            :rules="[v => !!v || 'La Sucursal es obligatorio.']"
            :disabled="$store.state.lots.saveLoading"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.code"
            label="Código del Lote"
            :rules="[v => !!v || 'El Código del Lote es obligatorio.']"
            required
            :disabled="$store.state.lots.saveLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="$store.state.lots.saveLoading"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Fecha de caducidad"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="date = parseDate(dateFormatted)"
                :disabled="$store.state.lots.saveLoading"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="date"
              :min="new Date().toISOString().substr(0, 10)"
              :max="
                new Date(new Date().setFullYear(new Date().getFullYear() + 20))
                  .toISOString()
                  .substr(0, 10)
              "
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.stock"
            label="Cantidad"
            :rules="[v => !!v || 'La Cantidad es obligatorio.']"
            required
            :disabled="$store.state.lots.saveLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.purchase_price"
            label="Precio Venta"
            :rules="[v => !!v || 'Precio Venta es obligatorio.']"
            required
            prefix="$"
            :disabled="$store.state.lots.saveLoading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.product.retail_price"
            label="Precio Menudeo"
            required
            :rules="[v => !!v || 'Precio Menudeo es obligatorio.']"
            prefix="$"
            :disabled="$store.state.lots.saveLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.product.wholesale_price"
            label="Precio Mayoreo"
            required
            :rules="[v => !!v || 'Precio Mayoreo es obligatorio.']"
            prefix="$"
            :disabled="$store.state.lots.saveLoading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end ma-0 pa-0">
        <v-btn
          class="mr-4"
          @click="
            $store.commit('closeModalLot');
            clearForm();
          "
          :disabled="$store.state.lots.saveLoading"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary darken-1"
          @click="saveLot()"
          :disabled="$store.state.lots.saveLoading"
          :loading="$store.state.lots.saveLoading"
        >
          Guardar
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ProductInfoCard from "./ProductInfoCard";
export default {
  name: "LotForm",
  components: {
    ProductInfoCard
  },
  data: () => ({
    valid: true,
    server: process.env.VUE_APP_API_SERVER,
    date: null,
    dateFormatted: null,
    menu: false,
    product: null,
    form: {
      code: null,
      stock: null,
      purchase_price: null,
      product_id: null,
      expiration_date: null,
      product: {
        retail_price: null,
        wholesale_price: null
      },
      branch_id: 1
    }
  }),
  mounted() {
    this.$store.dispatch("LoadProducts");
    this.$store.dispatch("getBranches");
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    saveLot() {
      if (this.$refs.LotForm.validate()) {
        this.form.product_id = this.product.id;
        this.form.expiration_date = this.date;
        console.log(this.form);
        this.$store.dispatch("StoreLot", this.form).then(r => {
          if (!r.error) {
            console.log("Lote Guardado");
            this.$snotify.success("Lote Agregado");
            this.$store.dispatch("LoadLots");
            this.clearForm();
          } else {
            console.log("Error al guardar");
            this.$snotify.error("Ocurrio un error");
            if (r.msg.response && r.msg.response.data) {
              Object.keys(r.msg.response.data.errors).forEach(e => {
                r.msg.response.data.errors[e].forEach(m => {
                  this.$snotify.error(m);
                });
              });
            }
          }
        });
      }
    },
    clearForm() {
      this.date = null;
      this.dateFormatted = null;
      this.menu = false;
      this.product = null;
      this.form = {
        code: null,
        stock: null,
        purchase_price: null,
        product_id: null,
        expiration_date: null,
        product: {
          retail_price: null,
          wholesale_price: null
        },
        branch_id: 1
      };
      this.$refs.LotForm.reset();
      this.$nextTick(() => {
        this.form.branch_id = 1;
      });
    },
    customSearch(item, queryText) {
      const textOne = item.barcode.toLowerCase() + item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    }
  }
};
</script>

<style lang="scss">
.LotForm {
  table {
    tr:hover {
      background-color: transparent !important;
    }
  }
  #select-product {
    .v-list-item {
      max-width: 650px;
    }
  }
}
</style>
