<template>
  <v-col cols="12">
    <h4 class="d-flex pb-3">Enfermedades hereditarias</h4>
    <ckeditor
      :editor="CKEditor.editor"
      :config="CKEditor.editorConfig"
      v-model="hereditaryDiseases"
      :disabled="readOnly"
    ></ckeditor>

    <h4 class="d-flex pb-3">Enfermedades patologicas</h4>
    <ckeditor
      :editor="CKEditor.editor"
      :config="CKEditor.editorConfig"
      v-model="pathological"
      :disabled="readOnly"
    ></ckeditor>

    <h4 class="d-flex pb-3">Enfermedades no patologicas</h4>
    <ckeditor
      :editor="CKEditor.editor"
      :config="CKEditor.editorConfig"
      v-model="non_pathological"
      :disabled="readOnly"
    ></ckeditor>
  </v-col>
</template>

<script>
/* import PathologicalHistory from "./../Consultation/PathologicalHistory"; */
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "ckeditor5-build-classic-spanish-advanced";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ClientHistory",
  props:['readOnly'],
  components: {
    ckeditor: CKEditor.component,
  },
  computed: {
    ...mapFields({
      hereditaryDiseases: "consultation.personalHistory.hereditary_diseases",
      non_pathological: "consultation.personalHistory.non_pathological",
      pathological: "consultation.personalHistory.pathological",
    }),
  },
  data() {
    return {
      step: 1,
      CKEditor: {
        editor: ClassicEditor,
        editorConfig: {
          removePlugins: [
            // "Essentials",
            "CKFinderUploadAdapter",
            "Alignment",
            "Autoformat",
            // "Bold",
            "Italic",
            "Underline",
            "Strikethrough",
            "BlockQuote",
            "CKFinder",
            "EasyImage",
            "Heading",
            "Image",
            "ImageCaption",
            "ImageStyle",
            "ImageToolbar",
            "ImageUpload",
            "Indent",
            "Link",
            // "List",
            "MediaEmbed",
            // "Paragraph",
            "PasteFromOffice",
            "Table",
            "TableToolbar",
            "TableProperties",
            "TableCellProperties",
            "Font",
          ],
          toolbar: [
            "bold",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch("fetchPersonalHistory", this.$route.params.id);
  },
  create() {},
};
</script>

<style>
.v-stepper__wrapper > .v-stepper > .v-stepper__step {
  flex-direction: row;
}
</style>
