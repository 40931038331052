import Vue from "vue";
import axios from "axios";
import EventBus from "../EventBus";

export const SET_BRANCHES = "SET_BRANCHES";
export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const SET_EMPLOYEE = "SET_EMPLOYEE";
export const SET_RESULT_EMPLOYEE = "SET_RESULT_EMPLOYEE";
export const SET_RESULT_USER = "SET_RESULT_USER";
export const SET_USER = "SET_USER";
export const SET_ROLE = "SET_ROLE";
export const RESET_DATA = "RESET_DATA";
export const RESET_USER = "RESET_USER";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";

export default {
  state: {
    branches: [],
    employees: [],
    employee: {
      id: "",
      name: "",
      lastName: "",
      maidenName: "",
      birthday: "",
      sex: "",
      address: "",
      phone: "",
      mobilPhone: "",
      email: "",
      state: "",
      employment: "",
      maritalStatus: "",
      bloodType: "",
      allergies: "",
      branch_id: ""
    },
    user: {
      id: "",
      user: "",
      password: "",
      passwordConfirm: "",
      roles: [],
      role: {
        id: "",
        name: "",
      },
    },
  },
  mutations: {
    [SET_BRANCHES](state, payload) {
      Vue.set(state, "branches", payload);
    },
    [SET_EMPLOYEES](state, payload) {
      Vue.set(state, "employees", payload);
    },
    [SET_EMPLOYEE](state, payload) {
      Vue.set(state.employee, "id", payload.id);
      // EventBus.$emit("EMPLOYEES::employeeUpdated");
    },
    [SET_RESULT_EMPLOYEE](state, payload) {
      Vue.set(state, "employee", payload);
    },
    [SET_RESULT_USER](state, payload) {
      payload.password = "";
      payload.passwordConfirm = "";
      console.log(payload);
      payload.role = payload.roles[0];
      Vue.set(state, "user", payload);
    },
    [SET_USER](state, payload) {
      Vue.set(state.user, "id", payload.id);
    },
    [SET_ROLE]() {
      EventBus.$emit("EMPLOYEES::userStore");
    },
    [RESET_DATA](state) {
      Vue.set(state, "employee", {
        id: "",
        name: "",
        lastName: "",
        maidenName: "",
        birthday: "",
        sex: "",
        address: "",
        phone: "",
        mobilPhone: "",
        email: "",
        state: "",
        employment: "",
        maritalStatus: "",
        bloodType: "",
        allergies: "",
        branch_id: "",
      });
      Vue.set(state, "user", {
        id: "",
        user: "",
        password: "",
        passwordConfirm: "",
        role: { id: "", name: "" },
      });
    },
    [RESET_USER](state, error) {
      Vue.set(state, "user", {
        id: "",
        user: "",
        password: "",
        passwordConfirm: "",
        role: { id: "", name: "" },
      });
      if (error && error.response) {
        EventBus.$emit("EMPLOYEES::errorUserMessage", error);
      }
    },
    [DELETE_EMPLOYEE](state, payload) {
      let index = state.employees.findIndex(item => item.id == payload.id);
      if(index >= 0) {
        state.employees.splice(index, 1);
        EventBus.$emit("EMPLOYEES::employeeDeleted");
      }
      else
        EventBus.$emit("EMPLOYEES::errorMessage", { error: { response: {status: 404 }}});
    }
  },
  actions: {
    // getBranches(context) {
    //   return axios
    //     .get(`/api/branches`)
    //     .then((response) => response.data.data)
    //     .then((branches) => context.commit(SET_BRANCHES, branches));
    // },
    getEmployees(context) {
      return axios
        .get(`/api/employees`)
        .then((response) => response.data.data)
        .then((employees) => context.commit(SET_EMPLOYEES, employees));
    },
    findEmployee(context, id) {
      return axios
        .get(`/api/employees/${id}`)
        .then((response) => response.data.data)
        .then((employee) => context.commit(SET_RESULT_EMPLOYEE, employee))
        .catch((error) => EventBus.$emit("EMPLOYEES::errorMessage", error));
    },
    storeEmployee(context) {
      let employee = {};
      employee.first_name = context.state.employee.name;
      employee.last_name = context.state.employee.lastName;
      employee.maiden_name = context.state.employee.maidenName;
      employee.birthday = context.state.employee.birthday;
      employee.sex = context.state.employee.sex == 'Masculino' ? 'M' : 'F';
      employee.address = context.state.employee.address;
      employee.phone = context.state.employee.phone;
      employee.mobil_phone = context.state.employee.mobilPhone;
      employee.email = context.state.employee.email;
      employee.state = context.state.employee.state;
      employee.employment = context.state.employee.employment;
      employee.marital_status = context.state.employee.maritalStatus;
      employee.blood_type = context.state.employee.bloodType;
      employee.allergies = context.state.employee.allergies;
      employee.branch_id = context.state.employee.branch_id;
      return axios
        .post(`/api/employees`, employee);
    },
    updateEmployee(context) {
      let employee = {
        first_name: context.state.employee.name,
        last_name: context.state.employee.lastName,
        maiden_name: context.state.employee.maidenName,
        birthday: context.state.employee.birthday,
        sex: context.state.employee.sex == 'Masculino' ? 'M' : 'F',
        address: context.state.employee.address,
        phone: context.state.employee.phone,
        mobil_phone: context.state.employee.mobilPhone,
        email: context.state.employee.email,
        state: context.state.employee.state,
        employment: context.state.employee.employment,
        marital_status: context.state.employee.maritalStatus,
        blood_type: context.state.employee.bloodType,
        allergies: context.state.employee.allergies,
        branch_id: context.state.employee.branch_id
      };
      return axios
        .put(
          `/api/employees/${context.state.employee.id}`,
          employee
        )
    },
    deleteEmployee(context, employee) {
      return axios.delete(`/api/employees/${employee.id}`)
      .then((response) => response.data.data)
      .then((employee) => { context.commit(DELETE_EMPLOYEE, employee) })
      .catch((error) => EventBus.$emit("EMPLOYEES::errorMessage", error))
    },
    findUser(context) {
      return axios
        .get(
          `/api/employees/${context.state.employee.id}/user`
        )
        .then((response) => response.data.data)
        .then((user) => context.commit(SET_RESULT_USER, user))
        .catch((error) => context.commit(RESET_USER, error));
    },
    storeUser(context) {
      let user = {
        employee_id: context.state.employee.id,
        user: context.state.user.user,
        email: context.state.employee.email,
        password: context.state.user.password,
        password_confirm: context.state.user.passwordConfirm,
      };
      return axios
        .post(`/api/users`, user)
        .then((response) => {
          context.commit(SET_USER, response.data.data);
          return axios.post(
            `/api/users/${response.data.data.id}/roles`,
            { roles: [context.state.user.role.id] }
          );
        });
    },
    updateUser(context) {
      let user = {
        employee_id: context.state.employee.id,
        user: context.state.user.user,
        email: context.state.employee.email,
        password: context.state.user.password,
        password_confirm: context.state.user.passwordConfirm,
      };
      return axios
        .put(`/api/users/${context.state.user.id}`, user)
        .then((response) => {
          context.commit(SET_USER, response.data.data);
          return axios.post(
            `/api/users/${response.data.data.id}/roles`,
            { roles: context.state.user.roles.map(item => item.id) }
          );
        })
    },
  },
  getters: {},
};
