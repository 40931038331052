<template>
  <v-autocomplete
    class="selectProduct"
    id="select-product"
    ref="selectProduct"
    autofocus
    v-model="product"
    :items="$store.getters.getBranchProducts"
    chips
    label="Producto"
    item-text="name"
    item-value="id"
    return-object
    clearable
    :disabled="$store.state.lots.saveLoading || loadingProducts"
    @keydown.enter="enterkey()"
    :loading="loadingProducts"
    :filter="customSearch"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        label
      >
        <v-avatar left>
          <v-img
            v-if="data.item.image_path"
            :src="`${data.item.image_path}`"
            alt="NOT FOUND"
          ></v-img>
          <v-icon v-else x-large>mdi-image-off</v-icon>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <v-img
            v-if="data.item.image_path"
            :src="`${data.item.image_path}`"
            alt="NOT FOUND"
          ></v-img>
          <v-icon v-else x-large>mdi-image-off</v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="selectProduct-listContent">
          <v-list-item-title class="text-uppercase">
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-bold">
            $ {{ data.item.retail_price }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-truncate">
            <v-icon>mdi-barcode</v-icon> {{ data.item.barcode }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="justify-center align-center">
          <p class="ma-0 caption grey--text">STOCK</p>
          <v-chip small outlined text-color="grey">
            {{ data.item.stock }}
          </v-chip>
        </v-list-item-action>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "ProductSelectInput",
  data: () => ({
    server: process.env.VUE_APP_API_SERVER,
    enter_switch: 0
  }),
  computed: {
    ...mapFields({
      product: "sales.saleProduct",
      loadingProducts: "products.loading"
    })
  },
  mounted() {
    this.$store.dispatch("LoadBranchProduct");
  },
  methods: {
    enterkey() {
      if (this.product) {
        this.$store
          .dispatch("AddSaleDetail", {
            quantity: 1,
            discount: 0,
            product_id: this.product.id,
            promotion: 0
          })
          .then(r => {
            if (!r.error) {
              this.product = null;
              this.$store.dispatch("FetchSale", this.$route.params.id);
              this.$store.dispatch("LoadBranchProduct");
            } else {
              this.$snotify.error("Error al Agregar Producto");
            }
          });
      }
    },
    customSearch(item, queryText) {
      const textOne = item.barcode.toLowerCase() + item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    }
  }
};
</script>

<style lang="scss">
@media (max-width: 991.98px) {
  .selectProduct-listContent {
    max-width: 450px;
  }
}
@media (max-width: 1199.98px) {
  .selectProduct-listContent {
    max-width: 450px;
  }
}
</style>
