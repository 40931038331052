import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import Snotify from "vue-snotify";
import axios from "axios";
import VueAxios from "vue-axios";

import BaseLayout from "./layouts/baseLayout.vue";
import PageLayout from "./layouts/pageLayout.vue";

Vue.config.productionTip = false;
Vue.component("base-layout", BaseLayout);
Vue.component("page-layout", PageLayout);

Vue.use(Snotify);
Vue.use(VueAxios, axios);

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

axios.defaults.baseURL = process.env.VUE_APP_API_SERVER
axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_API_SERVER;
axios.defaults.headers.Accept = 'application/json'
axios.defaults.withCredentials = true
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error == 'Error: Request failed with status code 419' || error=='Error: Request failed with status code 401' || error.response.status == 419){
    router.push('/')
    store.dispatch('eraseUser')
    Vue.snotify.error('Tu sesión ha caducado, vuelve a iniciar sesión',{
      timeout: 10000,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true
    })
  }
    return Promise.reject(error);
  }
);

const files = require.context("./components/Utils", true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
