<template>
  <v-dialog v-model="modalClient" max-width="650px" persistent>
    <v-card class="SaleInfoModal">
      <v-toolbar elevation="1" color="primary darken-1" dark>
        <v-toolbar-title>SELECCIONAR CLIENTE</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          icon
          small
          @click="$store.commit('closeSelectClientModal')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-0 pb-1">
        <v-card class="elevation-0">
          <v-app-bar flat>
            <v-row justify="space-between">
              <v-col cols="auto" lg="8" xl="6">
                <v-text-field
                  clearable
                  flat
                  solo
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Buscar"
                  v-model="search"
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col
                cols="auto"
                lg="4"
                xl="6"
                class="d-flex align-center justify-end"
              >
                <v-btn color="accent darken-2" @click="addSaleClient()">
                  Nuevo Cliente
                </v-btn>
              </v-col>
            </v-row>
          </v-app-bar>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="clients"
            :items-per-page="5"
            class="ClientSelectTable"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                outlined
                small
                color="accent darken-2"
                @click="selectClient(item)"
              >
                Seleccionar
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          class="mx-2"
          text
          @click="$store.commit('closeSelectClientModal')"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "ClientSelectModal",
  data: () => ({
    search: "",
    headers: [
      { text: "Nombre", value: "fullName" },
      { text: "Móvil", value: "mobil_phone" },
      { text: "Opciones", value: "actions", sortable: false, align: "center" }
    ]
  }),
  mounted() {
    this.$store.dispatch("getClientsAsync");
  },
  computed: {
    clients() {
      return this.$store.state.clients.allClients;
    },
    ...mapFields({
      modalClient: "sales.selectClientModal"
    })
  },
  methods: {
    selectClient(item) {
      console.log(item);
      this.$store
        .dispatch("UpdateClient", {
          id: this.$route.params.id,
          client: item.id
        })
        .then(r => {
          if (!r.error) {
            this.$store.dispatch("FetchSale", this.$route.params.id);
            this.$store.commit("closeSelectClientModal");
          } else {
            this.$snotify.error("Error al Actualizar Cliente");
          }
        });
    },
    addSaleClient() {
      this.$store.commit("closeSelectClientModal");
      this.$store.commit("openSaleClientModal");
    }
  }
};
</script>

<style lang="scss" scoped></style>
