import Vue from "vue";
import axios from "axios";

export const SET_CLIENT = "SET_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const SET_CLIENTS = "SET_CLIENTS";
export const STATUS_MODAL = "STATUS_MODAL";
export const ADD_CLIENT = "ADD_CLIENT";
export const SET_IMAGE = "SET_IMAGE";

export default {
  state: {
    allClients: [],
    image: null,
    client: {},
    modal: false,
    showClient: false,
    states: [
      { key: "AGS", name: "AGUASCALIENTES" },
      { key: "BC", name: "BAJA CALIFORNIA" },
      { key: "BCS", name: "BAJA CALIFORNIA SUR" },
      { key: "CHI", name: "CHIHUAHUA" },
      { key: "CHS", name: "CHIAPAS" },
      { key: "CMP", name: "CAMPECHE" },
      { key: "CMX", name: "CIUDAD DE MEXICO" },
      { key: "COA", name: "COAHUILA" },
      { key: "COL", name: "COLIMA" },
      { key: "DGO", name: "DURANGO" },
      { key: "GRO", name: "GUERRERO" },
      { key: "GTO", name: "GUANAJUATO" },
      { key: "HGO", name: "HIDALGO" },
      { key: "JAL", name: "JALISCO" },
      { key: "MCH", name: "MICHOACAN" },
      { key: "MEX", name: "ESTADO DE MEXICO" },
      { key: "MOR", name: "MORELOS" },
      { key: "NAY", name: "NAYARIT" },
      { key: "NL", name: "NUEVO LEON" },
      { key: "OAX", name: "OAXACA" },
      { key: "PUE", name: "PUEBLA" },
      { key: "QR", name: "QUINTANA ROO" },
      { key: "QRO", name: "QUERETARO" },
      { key: "SIN", name: "SINALOA" },
      { key: "SLP", name: "SAN LUIS POTOSI" },
      { key: "SON", name: "SONORA" },
      { key: "TAB", name: "TABASCO" },
      { key: "TLX", name: "TLAXCALA" },
      { key: "TMS", name: "TAMAULIPAS" },
      { key: "VER", name: "VERACRUZ" },
      { key: "YUC", name: "YUCATAN" },
      { key: "ZAC", name: "ZACATECAS" },
    ],
  },
  mutations: {
    [STATUS_MODAL](state, status) {
      state.modal = status;
    },
    [SET_CLIENT](state, client) {
      Vue.set(state.client, client);
    },
    [SET_IMAGE](state, image) {
      Vue.set(state.client.image, image);
    },
    [UPDATE_CLIENT](state, client) {
      let objIndex = state.allClients.findIndex((obj => obj.id == client.id));
      Vue.set(state.allClients, objIndex, { ...client })
    },
    [SET_CLIENTS](state, clients) {
      state.allClients = clients;
    },
    [ADD_CLIENT](state, client) {
      client.full_name = `${client.first_name} ${client.last_name} ${client.maiden_name}`
      client.fullName = `${client.first_name} ${client.last_name} ${client.maiden_name}`
      state.allClients.unshift(client);
    },

  },
  actions: {
    async storeClientAsync({ state }) {
      state.client.image_path = state.image;
      return axios
        .post(`/api/clients`, state.client)
    },
    async updateClientAsync({ commit, state }) {
      state.client.image_path = state.image;
      return axios
        .put(`/api/clients/${state.client.id}`, state.client)
        .then((client) => {
          commit(UPDATE_CLIENT, client.data.data);
          commit(STATUS_MODAL, false);
        })
    },
    async deleteClientAsync({ commit, state }, clientId) {
      return axios.delete(`api/clients/${clientId}`).then(() => {
        let updated = state.allClients.filter(function (item) {
          return item.id != clientId;
        });
        commit(SET_CLIENTS, updated)
      })
    },
    async getClientsAsync({ commit }) {
      axios
        .get(`/api/clients`)
        .then(({ data }) => commit(SET_CLIENTS, data.data))
        .catch((error) => console.log(error));
    },
    zapierPushClient(state,client){
      return fetch('https://hooks.zapier.com/hooks/catch/3293101/b428myd/',{
        method:'POST',
        body: JSON.stringify(client)
      })
    }
  },
  getters: {
  },
};
