<template>
  <div class="SalesTable">
    <v-card>
      <v-app-bar flat>
        <v-row>
          <v-col cols="auto">
            <v-menu offset-y :disabled="$store.state.sales.loading">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      class="pa-0"
                      color="accent darken-3"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      text
                      :disabled="$store.state.sales.loading"
                    >
                      {{ itemsPerPage }} <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Ventas por Página</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1"
                  color="accent darken-3"
                  v-bind="attrs"
                  v-on="on"
                  @click="$store.dispatch('LoadSales')"
                  :disabled="$store.state.sales.loading"
                >
                  <v-icon>mdi-update</v-icon>
                </v-btn>
              </template>
              <span>Recargar</span>
            </v-tooltip>
          </v-col>
          <v-col
            ><v-text-field
              clearable
              flat
              solo
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Buscar"
              v-model="search"
              :disabled="$store.state.sales.loading"
            ></v-text-field
          ></v-col>
          <v-col class="d-flex justify-end align-center" cols="auto">
            <v-btn
              color="accent darken-1"
              :disabled="$store.state.sales.loading"
              @click="create_new_sale()"
            >
              Nueva Venta
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-data-table
        :headers="headers"
        :items="$store.getters.getSales"
        :items-per-page.sync="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
        :page.sync="page"
        :search="search"
        :loading="$store.state.sales.loading"
      >
        <template v-slot:[`item.folio`]="{ item }">
          <strong>{{ item.folio }}</strong>
        </template>
        <template v-slot:[`item.client_name`]="{ item }">
          <span class="text-uppercase">{{item.client_name}}</span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          $ {{ item.amount }}
        </template>
        <template v-slot:[`item.status_name`]="{ item }">
          <v-chip
            x-small
            :color="colorStatus(item.status_name)"
            text-color="white"
          >
            {{ item.status_name }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <SaleOptions :sale="item"></SaleOptions>
        </template>
        <template v-slot:no-results>
          <v-row class="mt-3" align-content="center" justify="center">
            <v-alert
              outlined
              color="secondary"
              border="left"
              icon="mdi-alert-decagram-outline"
            >
              Ningún elemento coincide con la búsqueda
            </v-alert>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-row class="mt-3" align-content="center" justify="center">
            <v-alert
              outlined
              color="secondary"
              border="left"
              icon="mdi-alert-circle"
            >
              No hay datos disponibles
            </v-alert>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :disabled="$store.state.lots.loading"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import SaleOptions from "./SaleOptions";

export default {
  name: "SalesTable",
  components: {
    SaleOptions
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageArray: [5, 10, 25, 50],
    headers: [
      { text: "#", value: "index" },
      { text: "Folio", value: "folio", align: "center" },
      { text: "Fecha y Hora", value: "date", align: "center" },
      { text: "Cliente", value: "client_name" },
      { text: "Total", value: "amount" },
      { text: "Estatus", value: "status_name", align: "center" },
      { text: "Opciones", value: "actions", sortable: false, align: "center" }
    ],
    search: ""
  }),
  mounted() {
    this.$store.dispatch("LoadSales");
  },
  methods: {
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    colorStatus(status) {
      switch (status) {
        case "NUEVO":
          return "green";
        case "PREVENTA":
          return "orange";
        case "PAGADO":
          return "blue";
        case "CANCELADA":
          return "red";
        default:
          return "grey";
      }
    },
    create_new_sale() {
      this.$store.dispatch("CreateNewSale").then(r => {
        if (!r.error) {
          this.$router.push({
            path: `/ventas/${r.data.id}/create`
          });
        } else {
          if (r.msg.response && r.msg.response.data) {
            Object.keys(r.msg.response.data.errors).forEach(e => {
              r.msg.response.data.errors[e].forEach(m => {
                this.$snotify.error(m);
              });
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
