var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-iterator',{attrs:{"hide-default-footer":"","loading":_vm.$store.state.products.loading,"items":_vm.$store.getters.getProducts,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy.toLowerCase(),"sort-desc":_vm.sortDesc,"search":_vm.search,"page":_vm.page},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"header",fn:function(props){return [_c('v-toolbar',{staticClass:"mb-1 px-0",attrs:{"flat":"","color":"transparent"}},[(props.items.length)?_c('v-progress-linear',{attrs:{"active":_vm.$store.state.products.loading,"indeterminate":_vm.$store.state.products.loading,"absolute":"","bottom":"","color":"primary"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Buscar","hide-details":"","clearable":"","flat":"","prepend-inner-icon":"mdi-magnify","disabled":_vm.$store.state.products.loading},on:{"keydown":function($event){return _vm.onSearchChange()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('span',{staticClass:"grey--text"},[_vm._v("Productos por Página")]),_c('v-menu',{attrs:{"offset-y":"","disabled":_vm.$store.state.products.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"accent darken-3","icon":"","disabled":_vm.$store.state.products.loading}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","disabled":_vm.$store.state.products.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","color":"accent darken-3","disabled":_vm.$store.state.products.loading}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-type")])],1)]}}],null,true)},[_c('span',[_vm._v("Ordenar Por")])])]}}],null,true)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"accent darken-3"},model:{value:(_vm.selectedSort),callback:function ($$v) {_vm.selectedSort=$$v},expression:"selectedSort"}},_vm._l((_vm.sorts),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.sortBy = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","color":"accent darken-3","disabled":_vm.$store.state.products.loading},on:{"click":function($event){_vm.sortDesc = !_vm.sortDesc}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.sortDesc ? "mdi-sort-descending" : "mdi-sort-ascending"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sortDesc ? "Descendente" : "Ascendente"))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","color":"accent darken-3","disabled":_vm.$store.state.products.loading},on:{"click":function($event){return _vm.$store.dispatch('LoadProducts')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-update")])],1)]}}],null,true)},[_c('span',[_vm._v("Recargar")])])],1)]}},{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('ProductItem',{attrs:{"product":item}})],1)}),1)]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-2 px-5 flex-column",attrs:{"align":"center","justify":"center"}},[_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":5,"disabled":_vm.$store.state.products.loading},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]},proxy:true},{key:"loading",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Cargando Productos ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","rounded":"","height":"6"}})],1)],1)]},proxy:true},{key:"no-results",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-alert',{attrs:{"outlined":"","color":"secondary","border":"left","icon":"mdi-alert-decagram-outline"}},[_vm._v(" Ningún elemento coincide con la búsqueda ")])],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-alert',{attrs:{"outlined":"","color":"secondary","border":"left","icon":"mdi-alert-circle"}},[_vm._v(" No hay datos disponibles ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }