
import axios from "axios";

export const SET_APPOINTMENTS = 'SET_APPOINTMENTS'
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT'
export const SET_DATES = 'SET_DATES'
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT'

export default {
  state: {
    type: 'month',
    appointmentSelectedData: {},
    nodeSelectedAppointment: null,
    modelCalendar: "",
    appointments: [],
    startDate: null,
    endDate: null,
  },
  mutations: {
    [SET_APPOINTMENTS](state, data) {
      state.appointments = data;
    },
    [SET_DATES](state, date) {
      state.startDate = date.start;
      state.endDate = date.end;
    },
    [ADD_APPOINTMENT](state, appointment) {
      state.appointments.unshift(appointment);
    },
    [DELETE_APPOINTMENT](state) {
      let removeIndex = state.appointments.map(function (item) { return item.id; }).indexOf(state.appointmentSelectedData.id);
      state.appointments.splice(removeIndex, 1);
      this.state.appointments.cardaAppointmentState =false
    },
  },
  actions: {
    getAppointmentsByDates({ state, commit }, filter) {
      axios.post(`/api/dateslite`, {
        from: state.startDate,
        to: state.endDate,
        filter: filter
      })
        .then(({ data }) => commit('SET_APPOINTMENTS', data.data))
    },

    deleteAppointment({ state,commit }) {
      return axios
        .delete(`/api/appointments/${state.appointmentSelectedData.id}`)
        .then(() => {
          commit('DELETE_APPOINTMENT')
        }).catch(e => console.log(e));
    },
  },
  getters: {
  },
}
