<template>
  <v-dialog v-model="modalInfo" max-width="680px">
    <v-card class="SaleInfoModal">
      <v-toolbar elevation="1" color="primary darken-1" dark>
        <v-toolbar-title>DETALLE VENTA</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          icon
          small
          @click="$store.commit('closeSaleInfoModal')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pb-1 px-3">
        <v-row>
          <v-col cols="12" md="6">
            <div>Folio</div>
            <p class="mb-0 title text--primary">
              {{ sale ? sale.folio : "0000000" }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <div>Total</div>
            <p class="mb-0 title">$ {{ sale ? sale.amount : "000.00" }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <div>Cliente</div>
            <p class="mb-0 font-weight-bold text--primary text-uppercase">
              {{
                sale
                  ? `${sale.client.first_name} ${sale.client.last_name}`
                  : "... ... ..."
              }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <div>Empleado</div>
            <p class="mb-0 font-weight-bold text--primary text-uppercase">
              {{
                sale
                  ? `${sale.employee.first_name} ${sale.employee.last_name}`
                  : "... ... ..."
              }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <div>Fecha y Hora</div>
            <p class="mb-0 font-weight-bold text--primary">
              {{ sale ? sale.date : "..." }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <div>Estatus</div>
            <v-chip
              v-if="sale"
              x-small
              :color="colorStatus(sale.status.name)"
              text-color="white"
            >
              {{ sale.status.name }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>Productos</div>
            <v-simple-table dense v-if="sale">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Nombre
                    </th>
                    <th class="text-center">
                      Cantidad
                    </th>
                    <th class="text-center" style="min-width:75px">
                      Precio
                    </th>
                    <th class="text-center" style="min-width:75px">
                      Importe
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in sale.details" :key="item.id">
                    <td class="px-1">{{ item.product }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                    <td class="px-1">{{ round(item.price) }}</td>
                    <td class="px-1 text-right">{{ round(item.amount) }}</td>
                  </tr>
                  <!-- <tr>
                    <td></td>
                    <td></td>
                    <td class="font-weight-bold text-center">Total</td>
                    <td class="font-weight-bold text-right">
                      $ {{ sale.amount }}
                    </td>
                  </tr> -->
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="this.$route.name == 'paysales'"
          color="amber darken-3"
          text
          @click="edit()"
          >Editar</v-btn
        >
        <v-btn
          v-if="this.$route.name == 'paysales'"
          color="error darken-1"
          text
          @click="cancel()"
          >Cancelar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="this.$route.name == 'paysales'"
          class="px-8 mx-2"
          color="accent darken-1"
          @click="paid()"
          >Pagar</v-btn
        >
        <!-- <v-btn class="mx-2" text @click="$store.commit('closeSaleInfoModal')"
          >Cerrar</v-btn
        > -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "SaleInfoModal",
  mounted() {
    console.log(this.$route.name);
  },
  computed: {
    sale() {
      return this.$store.state.sales.sale;
    },
    ...mapFields({
      modalInfo: "sales.saleInfoModal"
    })
  },
  methods: {
    round(price) {
      var temp = Number.parseFloat(price).toFixed(2);
      return temp && !isNaN(temp) ? `$ ${temp}` : price;
    },
    paid() {
      this.$store.commit("SET_PAYSALE", this.sale);
      this.$store.commit("closeSaleInfoModal");
      this.$store.commit("openPaymentModal");
    },
    cancel() {
      this.$store.dispatch("FetchSale", this.sale.id).then(() => {
        this.$store.commit("closeSaleInfoModal");
        this.$store.commit("openCancelSaleModal");
      });
    },
    edit() {
      this.$router.push({
        path: `/ventas/${this.sale.id}/create`
      });
    },
    colorStatus(status) {
      switch (status) {
        case "NUEVO":
          return "green";
        case "PREVENTA":
          return "orange";
        case "PAGADO":
          return "blue";
        case "CANCELADA":
          return "red";
        default:
          return "grey";
      }
    }
  }
};
</script>

<style lang="scss">
.SaleInfoModal {
  table {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
