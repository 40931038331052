<template>
  <v-row>
    <v-col cols="9" class="pb-1">
      <v-autocomplete
        class="selectProduct"
        id="select-product"
        ref="selectProduct"
        v-model="product"
        :items="products"
        chips
        label="Servicio"
        item-text="name"
        item-value="id"
        return-object
        clearable
        :disabled="$store.state.lots.saveLoading || loadingProducts"
        @keydown.enter="enterkey()"
        :loading="loadingProducts"
        :search-input.sync="search"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
            label
          >
            <v-avatar left>
              <v-img
                v-if="data.item.image"
                :src="`${data.item.image}`"
                alt="NOT FOUND"
              ></v-img>
              <v-icon v-else x-large>mdi-image-off</v-icon>
            </v-avatar>
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-avatar>
              <v-img
                v-if="data.item.image"
                :src="`${data.item.image}`"
                alt="NOT FOUND"
              ></v-img>
              <v-icon v-else x-large>mdi-image-off</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="selectProduct-listContent">
              <v-list-item-title class="text-uppercase">
                {{ data.item.name }}
              </v-list-item-title>

              <v-list-item-subtitle></v-list-item-subtitle>

              <v-list-item-subtitle class="font-weight-bold">
                $ {{ data.item.retail_price }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="text-truncate">
                {{ data.item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="justify-center align-center">
              <p class="ma-0 caption grey--text">STOCK</p>
              <v-chip small outlined text-color="grey">
                {{ data.item.stock }}
              </v-chip>
            </v-list-item-action>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="3" class="d-flex align-center pb-1">
      <v-btn
        color="primary"
        @click="addService()"
        :disabled="!product"
        block
      >
        Agregar
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: 'FormService',

  data: () => ({
    loadingProducts: false,
    product: null,
    search: '',
    products: [],
  }),

  watch: {
    search (val) {
      if (this.isLoading) return

      this.isLoading = true

      axios.get(`/api/products?search=${val}&type=SERVICIO`)
        .then(res => {
          this.products = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
  
  methods: {
    addService() {
      this.$store.commit("ADD_PRODUCT", {
        appointment_id: this.$store.state.medication.appoiment_id,
        deleted_at: null,
        id: null,
        product_id: this.product.id,
        product_name: this.product.name,
        product_price: this.product.price,
        quantity: 1,
        status: "Activo",
        uses: 1
      });
      this.product = null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
