<template>
  <v-container>
    <v-navigation-drawer :value="drawerRight" app width="400" right temporary>
      <v-card class="mx-auto">
        <v-toolbar flat color="transparent">
          <v-app-bar-nav-icon @click="exchangeDrawer"></v-app-bar-nav-icon>
          <v-toolbar-title>Extra</v-toolbar-title>
        </v-toolbar>

      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    search: "",
  }),

  computed: {
    ...mapGetters({
      drawerRight: "getDrawerRight",
    }),
  },

  methods: {
    ...mapMutations({
      exchangeDrawer : 'EXCHANGE_DRAWER_RIGHT',
    }),
  },
};
</script>

<style>
</style>
