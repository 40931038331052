<template>
  <v-row justify="center">
    <v-card min-width="290px">
      <v-list shaped>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Citas</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-list-item-group v-model="itemSeleccionado">
          <v-list-item
            :active-class="primaryColor"
            @click="filterAppointments(null)"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title>Todas</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  :color="primarySelectedColor"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            :active-class="primaryColor"
            @click="filterAppointments('FINALIZADA')"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title>Concluidas</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  :color="primarySelectedColor"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item
            :active-class="primaryColor"
            @click="filterAppointments('CANCELADA')"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title>Canceladas</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  :color="primarySelectedColor"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item
            :active-class="primaryColor"
            @click="filterAppointments('PENDIENTE')"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title>Programadas</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  :color="primarySelectedColor"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    itemSeleccionado: 0,
    primaryColor: "primary--text text--accent-4",
    primarySelectedColor: "primary accent-4",
  }),
  computed: {
    ...mapGetters({
      drawerRight: "getDrawerRight",
    }),
  },
  methods: {
    ...mapMutations({
      exchangeDrawer: "EXCHANGE_DRAWER_RIGHT",
    }),
    filterAppointments(filter) {
      this.$store.dispatch("getAppointmentsByDates", filter);
    },
  },
  created: function () {},
};
</script>

<style>
</style>
