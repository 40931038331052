<template>
  <v-container class="Consultation">
    <client-card
      :client="client"
      :detail="false"
      class="mb-4"
      historyBtnText="Mostrar compras y servicios"
    >
      <template v-slot:info>
        <v-card-text style="width:100%">
          <v-skeleton-loader
            v-if="!client_ready"
            type="article"
          ></v-skeleton-loader>
          <v-row v-else>
            <v-col>
              <span
                >Nombre: {{ client.first_name }} {{ client.last_name }}</span
              >
              <br />
              <span>Tipo de sangre: {{ client.blood_type }}</span>
              <br />
              <span>Alergias: {{ client.allergies }}</span>
            </v-col>
            <v-col>
              <span>Edad: {{ edad }}</span>
              <br />
              <span>Ocupacion: {{ client.employment }}</span>
              <br />
              <span>Estado civil: {{ client.marital_status }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </client-card>
    <consultation :part="part" :client_ready="client_ready"></consultation>
  </v-container>
</template>

<script>
import Consultation from "@/components/Consultation/index.vue";
import ClientCard from "@/components/Client/ClientCard";

export default {
  name: "MedicalConsultation",
  components: {
    Consultation,
    ClientCard
  },
  computed: {
    client() {
      return this.$store.state.consultation.client;
    },
    edad() {
      let hoy = new Date();
      let cumpleanos = new Date(this.client.birthday);
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();
      let m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }

      return edad;
    },
  },
  data: () => ({
    part:1,
    client_ready: false
  }),
  async mounted() {
    await this.$store
      .dispatch("fetchAppointmentDetails", this.$route.params.appointment_id)
      .then(({ data }) => {
        this.$store.commit("SET_APPOINTMENT_DETAILS", data);
        this.client_ready = true;
        if(data.status=='FINALIZADA')
          this.part = 5;
      });
    this.$store.dispatch("loadDataSessions", this.$route.params.client_id);
  },
  
};
</script>

<style></style>
