<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <select v-model="camera">
          <option
            v-for="device in devices"
            :key="device.deviceId"
            :value="device.deviceId"
          >
            {{ device.label }}
          </option>
        </select>
      </v-col>
      <v-col>
        <v-btn @click="onCapture">Tomar foto</v-btn>
        <v-btn v-if="state" @click="onStop">Detener cámara</v-btn>
        <v-btn v-else @click="onStart">Iniciar cámara</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h2>
          Cámara actual
          <code v-if="device" class="caption">{{ device.label }}</code>
        </h2>
        <vue-web-cam
          height="90%"
          ref="webcam"
          :device-id="deviceId"
          @started="onStarted"
          @stopped="onStopped"
          @error="onError"
          @cameras="onCameras"
          @camera-change="onCameraChange"
        >
        </vue-web-cam>
      </v-col>
      <v-col cols="6">
        <h2>Foto actual</h2>
        <template >
          <v-img v-if="image == null && client.image_path != null" :src="`${server}/${client.image_path}`"></v-img>
          <v-img v-else :src="image"></v-img>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { WebCam } from "vue-web-cam";
export default {
  components: {
    "vue-web-cam": WebCam,
  },
  data() {
    return {
      server: process.env.VUE_APP_API_SERVER,
      camera: null,
      deviceId: null,
      devices: [],
      state: false,
    };
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
    ...mapFields({
      image : "clients.image",
      client: "clients.client",
      modal: "clients.modal",
    }),
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      const [first, ...tail] = this.devices;
      console.log(tail);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
    modal: function () {
      if (this.modal == true) {
        this.onStart();
      }
    },
  },
  methods: {
    onCapture() {
      this.image = this.$refs.webcam.capture();
    },
    onStarted(stream) {
      this.state = stream.active;
    },
    onStopped(stream) {
      this.state = stream.active;
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
  },
};
</script>
