<template>
  <v-container class="Lots">
    <h1 class="text-center mb-1">Lotes</h1>
    <LotsTable></LotsTable>
    <LotModal></LotModal>
    <LotDetailModal></LotDetailModal>
  </v-container>
</template>

<script>
import LotsTable from "@/components/Lots/LotsTable";
import LotModal from "@/components/Lots/LotModal";
import LotDetailModal from "@/components/Lots/LotDetailModal";

export default {
  name: "Lots",
  components: {
    LotsTable,
    LotModal,
    LotDetailModal
  }
};
</script>

<style lang="scss" scoped></style>
