<template>
  <v-card outlined class="BranchAndDate">
    <v-card-text class="py-0">
      <v-row justify="center" align="center">
        <v-col cols="5">
          <v-autocomplete
            v-model="branch"
            label="Sucursal"
            :items="branches"
            item-text="name"
            item-value="id"
            :disabled="loading"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="loading"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col class="pr-1">
              <v-btn
                color="accent darken-2"
                dark
                block
                large
                @click="updateReportSales()"
                :loading="loading"
              >
                Actualizar
              </v-btn>
            </v-col>
            <!-- <v-col class="pl-1" cols="auto">
              <v-btn
                large
                :loading="loading"
                color="primary darken-1"
                @click="updateReportSales()"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "BranchAndDate",
  data: () => ({
    branch: 1,
    date: new Date()
      .toLocaleDateString("es-MX", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      })
      .split("/")
      .reverse()
      .join("-"),
    menu: false
  }),
  computed: {
    ...mapFields({
      branches: "transfers.branches"
    }),
    loading() {
      return this.$store.state.sales.loading;
    }
  },
  mounted() {
    this.$store.dispatch("getBranches");
  },
  methods: {
    updateReportSales() {
      console.log({
        branchId: this.branch,
        data: {
          date: this.date
        }
      });
      this.$store.dispatch("FecthSalesReport", {
        branchId: this.branch,
        data: {
          date: this.date
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
