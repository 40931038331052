<template>
  <v-card>
    <v-tabs
      v-model="tab"
      background-color="secondary"
      dark
    >
      <v-tab>
        Notas de procedimiento
      </v-tab>
      <v-tab>
        Consentimiento informado
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
						<v-expansion-panels focusable popout v-model="panel">
							<!-- form -->
							<v-expansion-panel>
								<v-expansion-panel-header>
									Nueva nota de procedimiento
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-row>
										<v-col cols="12">
											<v-autocomplete
												v-model="product_id"
												:items="services"
												color="primary"
												item-text="name"
												item-value="id"
												label="Seleccione procedimiento"
											></v-autocomplete>
										</v-col>
										<v-col cols="12">
											<ckeditor
												:editor="CKEditor.editor"
												:config="CKEditor.editorConfig"
												v-model="note"
												required
												:disabled="readOnly"
												placeholder="Describa el procedimiento"
											></ckeditor>
										</v-col>
										<v-col cols="12" class="text-center">
											<v-btn color="primary" :disabled="readOnly" :loading="saving" @click="saveNote">Guardar</v-btn>
										</v-col>
									</v-row>
								</v-expansion-panel-content>
							</v-expansion-panel>
							<!-- history -->
							<v-expansion-panel v-for="(item, i) in $store.getters.proceduralNotes" :key="i">
								<v-expansion-panel-header>
									{{item.product.name}} - {{ getFecha(item.created_at) }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div v-html="item.note"></div>
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel v-if="$store.getters.proceduralNotes.length < 1">
								<v-expansion-panel-header>
									Sin notas de procedimiento
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									Este paciente no tiene notas de procedimientos registradas
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-card-text>
        </v-card>
      </v-tab-item>
			<v-tab-item>
				<v-card flat>
					<v-card-text>
						
						<v-row>
							<v-col cols="9">
								<v-file-input
									chips
									dense
									counter
									show-size
									truncate-length="15"
									clearable
									accept="image/png, image/jpeg, image/bmp, application/pdf"
									label="Consentimiento informado"
									placeholder="Selecciona el archivo"
									v-model="consent"
								></v-file-input>
							</v-col>
							<v-col cols="3">
								<v-btn block :loading="saving" @click="saveFile">subir</v-btn>
							</v-col>
							<v-col cols="12">
								<v-card>
									<v-list flat>
										<v-subheader>Consentimientos previos</v-subheader>
										<v-list-item-group>
											<v-list-item
												v-for="(item, i) in $store.getters.informedConsents"
												:key="i"
												@click="selectFile(item)"
											>
												<v-list-item-icon>
													<v-icon>mdi-file</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title>
														{{ item.name }}
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-list-item v-if="$store.getters.informedConsents.length == 0">
												<v-list-item-content>
													<v-list-item-title
														>No se han subido estudios</v-list-item-title
													>
												</v-list-item-content>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-card>
							</v-col>
						</v-row>

					</v-card-text>
				</v-card>
      </v-tab-item>
    </v-tabs-items>
		<v-dialog v-model="showFile">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <v-row class="justify-space-between">
            {{ fileShowed.name }}
            <v-icon @click="showFile = false">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <embed :src="fileShowed.url" style="width: 100%; min-height: 600px" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "ckeditor5-build-classic-spanish-advanced";
import { mapFields } from "vuex-map-fields";
export default {
  name: "proceduralNotes",
  props: {
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  components: { ckeditor: CKEditor.component },
  data() {
    return {
			tab: null,
      panel: 0,
      services: [],
      saving: false,
			showFile: false,
			fileShowed: {},
      CKEditor: {
        editor: ClassicEditor,
        editorConfig: {
          removePlugins: [
            "CKFinderUploadAdapter",
            "Alignment",
            "Autoformat",
            "Italic",
            "Underline",
            "Strikethrough",
            "BlockQuote",
            "CKFinder",
            "EasyImage",
            "Heading",
            "Image",
            "ImageCaption",
            "ImageStyle",
            "ImageToolbar",
            "ImageUpload",
            "Indent",
            "Link",
            "MediaEmbed",
            "PasteFromOffice",
            "Table",
            "TableToolbar",
            "TableProperties",
            "TableCellProperties",
            "Font",
          ],
          toolbar: [
            "bold",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "undo",
            "redo",
          ],
          placeholder: "Describa el procedimiento",
        },
      },
    };
  },
  computed: {
    ...mapFields({
      note_id: "consultation.proceduralNote.id",
      note: "consultation.proceduralNote.note",
      product_id: "consultation.proceduralNote.product_id",
			consent: "consultation.files.consent"
    }),
  },
  async mounted() {
    this.$store.dispatch("fetchProceduralNotes");
		this.$store.dispatch("fetchStudies");
    this.fetchServices();
  },
  methods: {
    fetchServices() {
      this.axios
        .get(`/api/services`)
        .then((res) => {
          this.services = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveNote() {
      this.saving = true;
      this.$store
        .dispatch("saveProceduralNote")
        .then(() => {
          this.$store.dispatch("fetchProceduralNotes");
          this.$store.commit("SET_PROCEDURAL_NOTE", {
            id: undefined,
            note: "",
            product_id: null,
          });
          this.panel++;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getFecha(date) {
      return new Date(date).toLocaleDateString("Es", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
		selectFile(file) {
      this.showFile = true;
      this.fileShowed = file;
    },
		saveFile() {
      this.saving = true;
      this.$store
        .dispatch("saveInformedConsent")
        .then(() => {
          this.$store.commit("SET_CONSENT_FILE", null);
					this.$store.dispatch("fetchStudies");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$snotify.error(
              "archivos validos: imagenes y pdf",
              "Archivo invalido"
            );
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style></style>
