<template>
  <v-card
    class="ProductItem"
    :style="[colorBorder, colorHover]"
    min-height="380"
    max-width="320"
    outlined
  >
    <v-chip class="rounded-sm float-left" label x-small :color="colorChip">
      {{ product.type }}
    </v-chip>
    <div class="d-flex justify-center align-center w-100 mt-8">
      <v-avatar :color="colorImg" size="164" tile>
        <v-img
          v-if="product.image"
          :src="`${product.image}`"
          alt="NOT FOUND"
        ></v-img>
        <v-icon v-else x-large>mdi-image-off</v-icon>
      </v-avatar>
    </div>
    <v-card-subtitle class="text-center ma-1 pa-0 text-caption">
      {{ product.category }}
    </v-card-subtitle>
    <v-card-title class="pt-0">
      <p class="product-name">
        {{ product.name }}
      </p>
    </v-card-title>

    <v-card-subtitle class="pb-0 subtitle-2">
      $ {{ product.retail_price }}
    </v-card-subtitle>

    <v-card-text class="caption pb-0">
      {{ product.description }}
    </v-card-text>
    <v-card-actions style="position: absolute; right: 0;bottom: 0;">
      <v-spacer></v-spacer>

      <v-btn icon @click.stop="productDetail()">
        <v-icon color="primary darken-2">mdi-eye</v-icon>
      </v-btn>

      <v-btn icon @click.stop="editProduct()">
        <v-icon color="accent darken-2">mdi-pencil</v-icon>
      </v-btn>

      <v-btn icon @click.stop="deleteProduct()">
        <v-icon color="red lighten-1">mdi-delete-outline</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      default: () => ({
        id: null,
        name: "Nombre del Producto",
        price: "0000.00",
        description: "Descripción del Producto",
        type: "PRODUCTO",
        image: null,
        category: "SIN CATEGORIA"
      })
    }
  },
  data: () => ({
    server: process.env.VUE_APP_API_SERVER
  }),
  computed: {
    colorBorder() {
      return {
        borderColor: `var(${
          this.product.type == "PRODUCTO"
            ? "--v-primary-darken2"
            : "--v-accent-darken2"
        })`
      };
    },
    colorChip() {
      return this.product.type == "PRODUCTO"
        ? "primary darken-2"
        : "accent darken-2";
    },
    colorHover() {
      return {
        "--color-hvr": `var(${
          this.product.type == "PRODUCTO"
            ? "--v-primary-darken2"
            : "--v-accent-darken2"
        })`
      };
    },
    colorImg() {
      return this.product.image ? "transparent" : "blue-grey lighten-5";
    }
  },
  methods: {
    editProduct() {
      this.$store.commit("SET_PRODUCT", { ...this.product });
      this.$store.commit("openModalProduct");
    },
    productDetail() {
      this.$store.commit("SET_PRODUCT", { ...this.product });
      this.$store.commit("openDrawerProduct");
    },
    deleteProduct() {
      console.log(this.product);
      console.log("Eliminar");
    }
  }
};
</script>

<style lang="scss">
.ProductItem {
  --color-hvr: var(--v-primary-darken2);

  margin-right: auto;
  margin-left: auto;
  transition: all 0.5s;

  &:hover {
    box-shadow: 0px 0px 8px var(--color-hvr);
  }

  &:focus {
    box-shadow: 0px 0px 8px var(--color-hvr);
  }

  .v-card__text {
    max-height: 72px !important;
    text-align: justify;
    display: block;
    display: -webkit-box;
    line-height: 1.1;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .product-name {
    max-height: 40px !important;
    text-align: justify;
    margin-bottom: 0;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.1;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    display: block;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }
}
</style>
