import Vue from "vue";
import axios from "axios";

export const SET_EMPLOYEES = 'SET_EMPLOYEES'
export const SET_CLIENTS = 'SET_CLIENTS'
export const SET_SERVICES = 'SET_SERVICES'
export const SET_DEFAULT = 'SET_DEFAULT'
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT'

export default {
  state: {
    employees: [],
    clients: [],
    services: [],

    appointment: {
      id:null,
      client_id: null,
      employee_id: null,
      status:'PENDIENTE',
      date: new Date().toISOString().substr(0, 10),
      hour: "",
      hour_end: "",
      products: [],
      observation: null
    },
    defaultAppointment: {
      id:null,
      client_id: null,
      employee_id: null,
      status:'PENDIENTE',
      date: new Date().toISOString().substr(0, 10),
      hour: "",
      hour_end: "",
      products: [],
      observation: null
    },
  },
  mutations: {
    [SET_EMPLOYEES](state, data) {
      state.employees = data;
    },
    [SET_CLIENTS](state, data) {
      state.clients = data;
    },
    [SET_SERVICES](state, data) {
      state.services = data;
    },
    [SET_DEFAULT](state) {
      state.appointment = Object.assign({},state.defaultAppointment);
    },
    [UPDATE_APPOINTMENT]( state,data) {
      let objIndex = this.state.appointments.calendar.appointments.findIndex((obj => obj.id == data.id));
      Vue.set(this.state.appointments.calendar.appointments, objIndex, { ...data })
      this.state.appointments.calendar.appointmentSelectedData = { ...data }
    },
  },
  actions: {
    getEmployeesForm({commit}) {
      return axios
        .get(`/api/allemployes`)
        .then(({ data }) => commit(SET_EMPLOYEES, data.data));
    },
    getClientsForm({commit}) {
      return axios
        .get(`/api/clients`)
        .then(({ data }) => commit(SET_CLIENTS, data.data));
    },
    getServicesForm({commit, state}) {
      return axios
        .get(`/api/services?client_id=${state.appointment.client_id}}`)
        .then(({ data }) => commit(SET_SERVICES, data.data));
    },
    storeAppointment({commit,state}) {
      return axios
        .post(`/api/appointments`,state.appointment)
        .then(({data}) => {
          commit('ADD_APPOINTMENT', data.data)
        });
    },
    updateAppointment({commit,state}) {
      return axios
        .put(`/api/appointments/${state.appointment.id}`,state.appointment)
        .then(({data}) => {
          commit('UPDATE_APPOINTMENT', data.data);
        }).catch(e=>console.log(e));
    },
    
  },
}
