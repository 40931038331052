import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";

import Login from "../views/Login";
import Example from "../views/Example.vue";
import Appointment from "../views/Appointment.vue";
import Client from "../views/Client.vue";
import Employee from "../views/Employee";
import Employees from "../views/Employees";
import Consultation from "../views/Consultation.vue";
import Products from "../views/Products";
import Branch from "../views/Branch";
import Lots from "../views/Lots";
import Sales from "../views/Sales";
import CreateSale from "../views/CreateSale";
import Transfers from "../views/Transfers.vue";
import Medication from "../views/Medication.vue";
import PaySales from "../views/PaySales.vue";
import SalesReport from "../views/SalesReport.vue";

import store from "../store/index.js";

Vue.use(VueRouter);

const defaultLayout = "page";

const appointment = "citas";
const client = "clientes";
const employees = "empleados";
const consultation = "consulta";
const products = "productos";
const branches = "sucursales";
const lots = "lotes";
const sales = "ventas";
const transfers = "transferencias";
const salesreport = "reportedeventas";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      layout: "base",
      title: "Login"
    }
  },
  {
    path: "/example",
    name: "Example",
    component: Example,
    meta: {
      layout: "page",
      title: "Ejemplo"
    }
  },
  {
    path: `/${appointment}`,
    name: "Appointment",
    component: Appointment,
    meta: {
      layout: defaultLayout,
      title: appointment
    }
  },
  {
    path: `/${transfers}`,
    name: "transfers",
    component: Transfers,
    meta: {
      layout: defaultLayout,
      title: transfers
    }
  },

  {
    path: `/${client}`,
    name: "clients",
    component: Client,
    meta: {
      layout: defaultLayout,
      title: client
    }
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      layout: "page",
      title: "Inicio"
    }
  },
  {
    path: `/${employees}`,
    name: "employee",
    component: Employees,
    meta: {
      layout: defaultLayout,
      title: employees
    }
  },
  {
    path: `/${employees}/:id`,
    name: "employee.show",
    component: Employee,
    props: true,
    meta: {
      layout: defaultLayout,
      title: "empleado"
    }
  },
  {
    path: `/${employees}/nuevo`,
    name: "employee.new",
    component: Employee,
    props: true,
    meta: {
      layout: defaultLayout,
      title: "empleado"
    }
  },
  {
    path: `/${appointment}/:appointment_id/${consultation}/${client}/:client_id`,
    name: "consultations.create",
    component: Consultation,
    props: true,
    meta: {
      layout: defaultLayout,
      title: consultation
    }
  },
  {
    path: `/${products}`,
    name: "products",
    component: Products,
    props: true,
    meta: {
      layout: defaultLayout,
      title: products
    }
  },
  {
    path: `/${branches}`,
    name: "branches",
    component: Branch,
    meta: {
      layout: defaultLayout,
      title: branches
    }
  },
  {
    path: `/${lots}`,
    name: "lots",
    component: Lots,
    props: true,
    meta: {
      layout: defaultLayout,
      title: lots
    }
  },
  {
    path: `/${sales}`,
    name: "sales",
    component: Sales,
    props: true,
    meta: {
      layout: defaultLayout,
      title: sales
    }
  },
  {
    path: `/${sales}/:id/create`,
    name: "newsales",
    component: CreateSale,
    props: true,
    meta: {
      layout: defaultLayout,
      title: "Nueva Venta"
    }
  },
  {
    path: "/tratamientos",
    name: "tratamientos",
    component: Medication,
    meta: {
      layout: defaultLayout,
      title: "Tratamientos"
    }
  },
  {
    path: "/paysales",
    name: "paysales",
    component: PaySales,
    meta: {
      layout: defaultLayout,
      title: "Caja"
    }
  },
  {
    path: `/${salesreport}`,
    name: salesreport,
    component: SalesReport,
    meta: {
      layout: defaultLayout,
      title: "Reporte Ventas"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (!store.getters.checkUser && to.path != "/") {
    console.log("has sido redirigido al login, por no iniciar sesion :(");
    next("/");
  }
  let title = process.env.VUE_APP_NAME
    ? process.env.VUE_APP_NAME
    : document.title.split("|")[0].trim();
  document.title = to.meta.title
    ? title + " | " + to.meta.title
    : title + " | " + to.name;
  next();
});

export default router;
