<template>
  <div class="LotsTable">
    <v-card>
      <v-app-bar flat>
        <v-row>
          <v-col cols="auto">
            <v-menu offset-y :disabled="$store.state.lots.loading">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      class="pa-0"
                      color="accent darken-3"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      text
                      :disabled="$store.state.lots.loading"
                    >
                      {{ itemsPerPage }} <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Lotes por Página</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1"
                  color="accent darken-3"
                  v-bind="attrs"
                  v-on="on"
                  @click="$store.dispatch('LoadLots')"
                  :disabled="$store.state.lots.loading"
                >
                  <v-icon>mdi-update</v-icon>
                </v-btn>
              </template>
              <span>Recargar</span>
            </v-tooltip>
          </v-col>
          <v-col
            ><v-text-field
              clearable
              flat
              solo
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Buscar"
              v-model="search"
              :disabled="$store.state.lots.loading"
            ></v-text-field
          ></v-col>
          <v-col class="d-flex justify-end align-center" cols="auto">
            <v-btn
              color="accent darken-1"
              :disabled="$store.state.lots.loading"
              @click="$store.commit('openModalLot')"
            >
              Agregar
              <v-icon right dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-data-table
        :headers="headers"
        :items="$store.getters.getLots"
        :items-per-page.sync="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
        :page.sync="page"
        :search="search"
        :loading="$store.state.lots.loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="actionItem(item)">
            <v-icon color="primary">mdi-eye</v-icon>
          </v-btn>
          <v-edit-dialog
            :return-value.sync="item.stock"
            large
            persistent
            @save="updateStock(item)"
            save-text="Guardar"
            cancel-text="Cancelar"
          > 
            <!-- default  -->
            <v-btn icon>
              <v-icon color="warning"> mdi-plus </v-icon>
            </v-btn>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Actualizar stock
              </div>
              <v-text-field
                v-model="item.stock"
                label="Nuevo stock"
                single-line
                type="number"
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.stock`]="{ item }">
          {{ item.current_stock }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
          $ {{ item.price }}
        </template>
        <template v-slot:no-results>
          <v-row class="mt-3" align-content="center" justify="center">
            <v-alert
              outlined
              color="secondary"
              border="left"
              icon="mdi-alert-decagram-outline"
            >
              Ningún elemento coincide con la búsqueda
            </v-alert>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-row class="mt-3" align-content="center" justify="center">
            <v-alert
              outlined
              color="secondary"
              border="left"
              icon="mdi-alert-circle"
            >
              No hay datos disponibles
            </v-alert>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :disabled="$store.state.lots.loading"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "LotsTable",
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageArray: [5, 10, 25, 50],
    headers: [
      { text: "#", value: "index" },
      { text: "Código", value: "code" },
      { text: "Producto", value: "product" },
      { text: "Stock", value: "stock" },
      { text: "Precio", value: "price"},
      { text: "Opciones", value: "actions", sortable: false, align: "center" }
    ],
    search: ""
  }),
  mounted() {
    this.$store.dispatch("LoadLots");
  },
  methods: {
    actionItem(item) {
      this.$store.dispatch("FetchProduct", item.product_id);
      this.$store.commit("SET_LOT", item);
      this.$store.commit("openDetailModalLot");
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    updateStock(item){
      this.$store.dispatch("updateStockLot", { id: item.id, stock: item.stock })
      .then(()=>{
        this.$snotify.success('Stock actualizado con éxito')
      })
      .catch( ({response}) =>{
        if (response.status == 422) {
          this.$snotify.error(JSON.stringify(Object.values(response.data.errors)).replaceAll(/[{}'"[\]]/g,''), "Error validando");
        } else
          this.$snotify.error(response.data.message,'No se actualizo el stock');
      })
      .finally(()=>{
        this.$store.dispatch('LoadLots')
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>
