<template>
  <v-dialog v-model="modalTicket" max-width="650px" persistent>
    <v-card class="TicketModal">
      <v-toolbar elevation="1" color="primary darken-1" dark>
        <v-toolbar-title>TICKET DE VENTA</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" icon small @click="close_modal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <object
          :data="`data:application/pdf;base64,${base64}`"
          type="application/pdf"
        ></object>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TicketModal",
  props: {
    redirect: {
      type: String,
      default: null
    }
  },
  computed: {
    modalTicket() {
      return this.$store.state.sales.modalTicket;
    },
    base64() {
      return this.$store.state.sales.ticketb64 || "";
    }
  },
  methods: {
    close_modal() {
      if (this.redirect) {
        this.$router.push({
          path: this.redirect
        });
      }
      this.$store.commit("closeModalTicket");
    }
  }
};
</script>

<style lang="scss">
.TicketModal {
  object {
    width: 100%;
    height: 500px;
  }
}
</style>
