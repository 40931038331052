<template>
  <v-form ref="form">
    <web-cam ref="webcam"></web-cam>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.first_name"
            :readonly="showClient"
            :counter="191"
            :rules="[(v) => !!v || 'Campo requerido']"
            label="Nombre(s)"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.last_name"
            :readonly="showClient"
            :counter="191"
            :rules="[(v) => !!v || 'Campo requerido']"
            label="Primer Apellido"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.maiden_name"
            :readonly="showClient"
            :counter="191"
            :rules="[]"
            label="Segundo Apellido"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="client.sex"
            :readonly="showClient"
            :items="gender"
            label="Genero"
            :rules="[]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.birthday"
            v-mask="'####-##-##'"
            label="Fecha de Nacimiento"
            :rules="dateRules"
            hint="YYYY-MM-DD"
            persistent-hint
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="client.marital_status"
            :readonly="showClient"
            :items="maritalItems"
            :rules="[]"
            label="Estado Civil"
            required
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="client.blood_type"
            :readonly="showClient"
            :rules="[(v) => !!v || 'Campo requerido']"
            :items="bloodTypes"
            label="Tipo de sangre"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.allergies"
            :readonly="showClient"
            :counter="191"
            :rules="[(v) => !!v || 'Campo requerido']"
            label="Alergias"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.employment"
            :readonly="showClient"
            :counter="191"
            :rules="[]"
            label="Empleo"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="client.address"
            :readonly="showClient"
            :counter="191"
            :rules="[]"
            label="Dirección"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="client.state"
            :readonly="showClient"
            :items="states"
            item-text="name"
            label="Estado"
            :rules="[]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.phone"
            :readonly="showClient"
            :counter="10"
            v-mask="'##########'"
            :rules="[]"
            label="Teléfono"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.mobil_phone"
            :readonly="showClient"
            :counter="10"
            v-mask="'##########'"
            :rules="[(v) => !!v || 'Campo requerido']"
            label="Celular"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="client.email"
            :readonly="showClient"
            :counter="191"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn v-if="!showClient" color="primary" class="mr-4" @click="validate" :loading="saving"
        >{{ clientExist ? "Actualizar" : "Guardar" }}
      </v-btn>
      <v-btn color="primary" class="mr-4" @click="cancel">
        {{ clientExist ? "Cerrar" : "Cancelar" }}
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import WebCam from "./WebCam";
import moment from "moment";

export default {
  name: "clientForm",
  components: { WebCam },
  props: {
    onSave: {
      type: Function,
      default: () => {}
    },
  },
  data: () => {
    const validbirthdate = (date) => {
      if( date=='' ) return true;
      var birthdate = moment(date, "YYYY-MM-DD", true).format("YYYY-MM-DD");
      var max = moment.max(moment(date), moment()).format("YYYY-MM-DD");
      var min = moment
        .min(moment(date), moment("1900-01-01"))
        .format("YYYY-MM-DD");
      if (birthdate === max || birthdate === min) {
        return false;
      }
      return true;
    };
    return {
      dialog: false,
      gender: ["M", "F"],
      maritalItems: [
        "SOLTERO(A)",
        "CASADO(A)",
        "UNIÓN LIBRE",
        "DIVORCIADO(A)",
        "VIUDO(A)",
        "SEPARADO",
      ],
      bloodTypes: ["ORH+", "ORH-", "A+", "A-", "B+", "B-", "AB+", "AB-","NO DISPONIBLE"],
      dateRules: [
        // v => !!v || "Campo requerido",
        v => /(\d{4}-\d{2}-\d{2})|^$/.test(v) || "Formato Incorrecto (YYYY-MM-DD)",
        v => validbirthdate(v) || "Fecha Invalida."
      ],
      emailRules: [v => /(^[^@]+@[^@]+\.[a-zA-Z]{2,}$|^$)/.test(v) || "formato de email incorrecto"],
      saving: false
    };
  },

  computed: {
    ...mapFields({
      image: "clients.image",
      modal: "clients.modal",
      client: "clients.client",
      states: "clients.states",
      showClient: "clients.showClient",
    }),
    clientExist() {
      return this.client.id;
    },
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.saving=true;
        if (this.clientExist) {
          this.$store
            .dispatch("updateClientAsync")
            .then(() => {
              this.$refs.form.reset();
              this.$snotify.success("Actualizado correctamente");
            })
            .catch((e) => {
              if (e.response.status == 422) {
                this.$snotify.error(JSON.stringify(Object.values(e.response.data.errors)).replaceAll(/[{}'"[\]]/g,''), "Error validando");
              } else
                this.$snotify.error(e.response.data.message,'Error');
            })
            .finally(()=>{
              this.saving=false;
            });
        } else {
          this.$store
            .dispatch("storeClientAsync")
            .then(({data}) => {
              console.log(data)
              this.$store.dispatch("zapierPushClient",data.data)
              this.$store.commit('ADD_CLIENT', data.data);
              this.$store.commit('STATUS_MODAL', false);
              this.$refs.form.reset();
              this.$snotify.success("Agregado correctamente");
              this.onSave();
            })
            .catch((e) => {
              if (e.response.status == 422) {
                this.$snotify.error(JSON.stringify(Object.values(e.response.data.errors)).replaceAll(/[{}'"[\]]/g,''), "Error validando");
              }
              else
                this.$snotify.error(e.response.data.message,'Error');
            })
            .finally(()=>{
              this.saving=false;
            })
        }
      }
    },
    startCamera() {
      this.$refs.webcam.onStart();
    },
    cancel() {
      (this.showClient = false), (this.modal = false);
      this.image = null;
      this.client = {};
      this.$refs.webcam.onStop();
      this.$refs.form.reset();
    },
  },

  mounted() {},
};
</script>
