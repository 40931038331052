<template>
  <v-row class="SaleOptions ma-0 pa-0">
    <v-col cols="12" class="ma-0 pa-0">
      <v-tooltip top v-if="sale.status.name == 'NUEVO'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="edit_sale()">
            <v-icon color="accent darken-2">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <v-tooltip top v-if="sale.status.name == 'NUEVO'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="updateSaleStatus('presale')"
            :disabled="sale.amount == 0"
          >
            <v-icon color="orange">mdi-cash-register</v-icon>
          </v-btn>
        </template>
        <span>Terminar Venta</span>
      </v-tooltip>
      <v-tooltip top v-if="sale.status.name == 'NUEVO' && sale.amount > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="cancel_sale()">
            <v-icon color="error">mdi-cancel</v-icon>
          </v-btn>
        </template>
        <span>Cancelar</span>
      </v-tooltip>
      <v-tooltip top v-if="sale.status.name == 'PAGADO'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="print_sale()">
            <v-icon color="accent darken-1">mdi-printer</v-icon>
          </v-btn>
        </template>
        <span>Imprimir</span>
      </v-tooltip>
      <!-- <v-icon
        color="grey lighten-2"
        v-else-if="sale.status.name == 'CANCELADA'"
      >
        mdi-dots-horizontal
      </v-icon> -->
      <v-tooltip
        left
        v-if="sale.status.name != 'NUEVO'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="show_sale()">
            <v-icon color="primary darken-1">mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Ver</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SaleOptions",
  props: {
    sale: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    updateSaleStatus(status) {
      this.$store
        .dispatch("UpdateStatus", {
          id: this.sale.id,
          status: status
        })
        .then(r => {
          if (!r.error) {
            this.$store.dispatch("LoadSales");
          } else {
            this.$snotify.error("Error al Terminar Venta");
          }
        });
    },
    edit_sale() {
      this.$router.push({
        path: `/ventas/${this.sale.id}/create`
      });
    },
    show_sale() {
      this.$store.dispatch("FetchSale", this.sale.id).then(() => {
        this.$store.commit("openSaleInfoModal");
      });
    },
    print_sale() {
      this.$store.dispatch("FecthTicket", this.sale.id).then(r => {
        if (!r.error) {
          this.$store.commit("SET_TICKET", r.data);
          this.$store.commit("openModalTicket");
        } else {
          this.$snotify.error("Error al Generar Ticket");
        }
      });
    },
    cancel_sale() {
      this.$store.dispatch("FetchSale", this.sale.id).then(() => {
        this.$store.commit("openCancelSaleModal");
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
