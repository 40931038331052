<template>
  <v-row>
    <v-col cols="3">
      <HeadCard title="Todas" :amount="todas"></HeadCard>
    </v-col>
    <v-col cols="3">
      <HeadCard title="Programadas" :amount="programadas"></HeadCard>
    </v-col>
    <v-col cols="3">
      <HeadCard title="Completadas" :amount="completadas"></HeadCard>
    </v-col>
    <v-col cols="3">
      <HeadCard title="Canceladas" :amount="canceladas"></HeadCard>
    </v-col>
  </v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import HeadCard from "./HeadCard.vue";
export default {
  components: { HeadCard },

  computed: {
    ...mapFields({
      appointments: "appointments.calendar.appointments",
    }),
    todas() {
      return this.appointments.length;
    },
    programadas() {
      return this.appointments.filter(appointment => appointment.status === "PENDIENTE").length ;
    },
    completadas() {
      return this.appointments.filter(appointment => appointment.status === "FINALIZADA").length ;
    },
    canceladas() {
      return this.appointments.filter(appointment => appointment.status === "CANCELADA").length ;
    },
  },
};
</script>

<style>
</style>