<template>
  <v-stepper alt-labels non-linear v-model="part">
    <v-stepper-header>
      <v-stepper-step editable step="1"> Antecedentes </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step editable step="2"> Padecimiento actual </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step editable step="3"> Exploracion fisica </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step editable step="4"> Estudios de gabinete </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step editable step="5"> Procedimientos </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step editable step="6"> Diagnostico y tratamiento </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <template v-if="part == 1 && client_ready">
          <History :readOnly="readOnly"></History>
          <v-btn
            block
            color="primary"
            @click="saveHistory()"
            :loading="loadingHistory"
            v-if="!readOnly"
          >
            Guardar y Continuar
          </v-btn>
        </template>
        <v-skeleton-loader v-else type="paragraph"></v-skeleton-loader>
      </v-stepper-content>

      <v-stepper-content step="2">
        <template v-if="part == 2">
          <v-btn class="ma-2" @click="part--" color="primary" outlined>
            <v-icon left dark> mdi-arrow-left</v-icon>
            Regresar
          </v-btn>
          <CurrentCondition :readOnly="readOnly"></CurrentCondition>
          <v-btn
            block
            color="primary"
            @click="saveCurrentCondition()"
            :loading="loadingCurrentCondition"
            v-if="!readOnly"
          >
            Guardar y Continuar
          </v-btn>
        </template>
      </v-stepper-content>

      <v-stepper-content step="3">
        <template v-if="part == 3">
          <v-btn class="ma-2" @click="part--" color="primary" outlined>
            <v-icon left dark> mdi-arrow-left</v-icon>
            Regresar
          </v-btn>
          <PhysicalExploration :readOnly="readOnly"></PhysicalExploration>
          <v-btn
            block
            color="primary"
            @click="savePhysicalExploration()"
            :loading="loadingPhysicalExploration"
            v-if="!readOnly"
          >
            Guardar y Continuar
          </v-btn>
        </template>
      </v-stepper-content>

      <v-stepper-content step="4">
        <template v-if="part == 4">
          <v-btn class="ma-2" @click="part--" color="primary" outlined>
            <v-icon left dark> mdi-arrow-left</v-icon>
            Regresar
          </v-btn>
          <files :readOnly="readOnly"></files>
          <v-btn
            block
            color="primary"
            @click="saveFiles()"
            :loading="loadingFiles"
            v-if="!readOnly"
          >
            Guardar y Continuar
          </v-btn>
        </template>
      </v-stepper-content>

      <v-stepper-content step="5">
        <template v-if="part == 5">
          <v-btn class="ma-2" @click="part--" color="primary" outlined>
            <v-icon left dark> mdi-arrow-left</v-icon>
            Regresar
          </v-btn>
          <Procedures :readOnly="readOnly"></Procedures>
          <v-btn
            block
            color="primary"
            @click="part++"
            class="my-3"
          >
            Continuar
          </v-btn>
        </template>
      </v-stepper-content>

      <v-stepper-content step="6">
        <template v-if="part == 6">
          <v-btn class="ma-2" @click="part--" color="primary" outlined>
            <v-icon left dark> mdi-arrow-left</v-icon>
            Regresar
          </v-btn>
          <Consultation :readOnly="readOnly"></Consultation>
        </template>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import History from "@/components/Consultation/History.vue";
import PhysicalExploration from "@/components/Consultation/PhysicalExploration.vue";
import CurrentCondition from "@/components/Consultation/CurrentCondition.vue";
import Files from "@/components/Consultation/Files.vue";
import Procedures from "@/components/Consultation/ProceduralNotes.vue";
import Consultation from "@/components/Consultation/Consultation.vue";
export default {
  name: "Index",
  props: {
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    step: {
      type: Number,
      default: () => 1,
    },
    client_ready:{
        type: Boolean,
        default: () => false,
    }
  },
  mounted() {
      this.part = this.step;
  },
  components: {
    History,
    PhysicalExploration,
    CurrentCondition,
    Files,
    Consultation,
    Procedures
  },
  data() {
    return {
      loadingHistory: false,
      loadingPhysicalExploration: false,
      loadingCurrentCondition: false,
      loadingFiles: false,
      part:1
    };
  },
  methods: {
    saveHistory() {
      this.loadingHistory = true;
      this.$store
        .dispatch("savePersonalHistory")
        .then(() => {
          this.part = 2;
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$snotify.error(e.response.data.errors, "error de validacion");
          }
        })
        .then(() => {
          this.loadingHistory = false;
        });
    },
    saveCurrentCondition() {
      this.loadingCurrsaveCurrentCondition = true;
      this.$store
        .dispatch("saveCurrentCondition")
        .then(() => {
          this.part = 3;
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$snotify.error(e.response.data.errors, "error de validacion");
          }
        })
        .then(() => {
          this.loadingCurrsaveCurrentCondition = false;
        });
    },
    savePhysicalExploration() {
      this.loadingPhysicalExploration = true;
      this.$store
        .dispatch("savePhysicalExploration")
        .then(() => {
          this.part = 4;
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$snotify.error(e.response.data.errors, "error de validacion");
          }
        })
        .then(() => {
          this.loadingPhysicalExploration = false;
        });
    },
    saveFiles() {
      this.loadingFiles = true;
      this.$store
        .dispatch("saveFiles")
        .then(() => {
          this.part = 5;
          this.$store.commit("SET_CABINETS_FILES", []);
          this.$store.commit("SET_LABORATORIES_FILES", []);
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$snotify.error(
              "archivos validos: imagenes y pdf",
              "Archivo invalido"
            );
          }
        })
        .then(() => {
          this.loadingFiles = false;
        });
    },
  },
};
</script>

<style>
</style>