import Vue from "vue";
import axios from "axios";
import EventBus from "../EventBus";

export const SET_BRANCHES = "SET_BRANCHES";
export const RESET_DATA = "RESET_DATA";
export const EDIT_BRANCH = "EDIT_BRANCH";
export const ADD_BRANCH = "ADD_BRANCH";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const DELETE_BRANCH = "DELETE_BRANCH";

export default {
  state: {
    branches: [],
    branch: {
      id: "",
      name: "",
      address: "",
      rfc: "",
      phone: "",
      logo: ""
    }
  },

  mutations: {
    [SET_BRANCHES](state, payload) {
      Vue.set(state, "branches", payload);
    },
    [RESET_DATA](state) {
      Vue.set(state, "branch", { id: "", name: "", address: "" });
    },
    [EDIT_BRANCH](state, payload) {
      Vue.set(state, "branch", {
        id: payload.id,
        name: payload.name,
        address: payload.address,
        rfc: payload.rfc,
        phone: payload.phone
      });
    },
    [ADD_BRANCH](state, payload) {
      state.branches.push(payload);
      EventBus.$emit("Branches::branchCreated");
    },
    [UPDATE_BRANCH](state, payload) {
      let index = state.branches.findIndex(item => item.id == payload.id);
      Vue.set(state.branches, index, payload);
      EventBus.$emit("Branches::branchUpdated");
    },
    [DELETE_BRANCH](state, payload) {
      let branches = state.branches.filter(item => item.id != payload.id);
      Vue.set(state, "branches", branches);
      EventBus.$emit("Branches::branchDeleted");
    }
  },

  actions: {
    getBranches(context) {
      return axios
        .get(`/api/branches`)
        .then(response => response.data.data)
        .then(branches => context.commit(SET_BRANCHES, branches));
    },
    storeBranch(context) {
      let branch = {};
      branch.name = context.state.branch.name;
      branch.address = context.state.branch.address;
      branch.rfc = context.state.branch.rfc;
      branch.phone = context.state.branch.phone;
      return axios
        .post(`/api/branches`, branch)
        .then(response => response.data)
        .then(newbranch => context.commit(ADD_BRANCH, newbranch.data));
    },
    updateBranch(context) {
      let branch = {};
      branch.name = context.state.branch.name;
      branch.address = context.state.branch.address;
      branch.rfc = context.state.branch.rfc;
      branch.phone = context.state.branch.phone;
      return axios
        .put(`/api/branches/${context.state.branch.id}`, branch)
        .then(response => response.data)
        .then(newbranch => context.commit(UPDATE_BRANCH, newbranch.data));
    },
    deleteBranch(context) {
      return axios
        .delete(`/api/branches/${context.state.branch.id}`)
        .then(response => response.data)
        .then(() => context.commit(DELETE_BRANCH, context.state.branch));
    }
  },

  getters: {
    getBranches: state => state.branches
  }
};
