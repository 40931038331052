<template>
  <v-menu
    transition="slide-x-reverse-transition"
    :close-on-content-click="true"
    max-width="280"
    v-model="profile_menu"
  >
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            fab
            small
            outlined
            color="primary"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-avatar size="40">
              <img
                :src="
                  $store.state.user.avatar ||
                    'http://intranet.difoaxaca.gob.mx/images/user.png'
                "
                alt="NC"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-list-item class="px-0">
          <v-list-item-content class="py-1" style="width: min-content;">
            <v-list-item-title
              >{{ $store.state.user.first_name }}
              {{ $store.state.user.last_name }}</v-list-item-title
            >
            <v-list-item-subtitle>{{
              $store.state.user.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-tooltip>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              :src="
                $store.state.user.avatar ||
                  'http://intranet.difoaxaca.gob.mx/images/user.png'
              "
              alt="NC"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >{{ $store.state.user.first_name }}
              {{ $store.state.user.last_name }}</v-list-item-title
            >
            <v-list-item-subtitle>{{
              $store.state.user.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item @click="goTo(`/users/${$store.state.user.id}`)">
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mi Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ProfileMenu",
  data: () => ({
    profile_menu: false,
  }),
  methods: {
    logout() {
      this.axios.post("/logout").then(() => {}).catch(()=>{}).then(() =>{
        this.$store.dispatch("eraseUser");
        this.$router.replace("/");
      });
    },
    goTo(ruta) {
      this.$router.push(ruta).catch((err) => {
        err;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
