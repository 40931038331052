<template>
  <v-container class="Employees text-center">
    <h1>Empleados</h1>
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-btn class="ml-2" depressed color="primary" @click="createEmployee">Nuevo</v-btn>
          </v-card-title>
          <v-data-table :headers="headers" :items="employees" :search="search">
            <template v-slot:item.actions="{ item }">
              <v-btn icon depressed color="warning" @click="$router.push({ name: 'employee.show', params: { id: item.id } })">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon depressed color="error" @click="askForConfirm(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Esta seguro ?
        </v-card-title>
        <v-card-text>Si el empleado tiene un usuario ya no podrá acceder al sistema.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteEmployee"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EventBus from '../EventBus';

export default {
  name: "Empleados",

  data: () => ({
    dialog: false,
    search: "",
    deleted: null,
    headers: [
      {
        text: "#",
        align: "start",
        filterable: false,
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "Primer Apellido", value: "lastName" },
      { text: "Segundo Apellido", value: "maidenName" },
      { text: "Genéro", value: "sex" },
      { text: "Puesto", value: "employment" },
      { text: "Móvil", value: "mobilPhone" },
      { text: "Acciones", value: "actions", filterable: false },
    ],
  }),

  computed: {
    employees() {
      return this.$store.state.employees.employees;
    },
  },

  mounted() {
    this.loadEmployees();

    EventBus.$on("EMPLOYEES::employeeDeleted", () => {
      this.$snotify.success("Empleado eliminado");
      this.cancelDelete();
    });
  },

  methods: {
    loadEmployees() {
      this.$store.dispatch("getEmployees");
      this.$store.dispatch("getBranches");
    },
    createEmployee() {
      this.$store.commit("RESET_DATA");
      this.$router.push({ name: 'employee.new' });
    },
    askForConfirm(item) {
      this.dialog = true;
      this.deleted = item;
    },
    cancelDelete() {
      this.dialog = false;
      this.deleted = null;
    },
    deleteEmployee() {
      this.$store.dispatch("deleteEmployee", { id: this.deleted.id });
    }
  },
};
</script>

<style lang="scss" scoped></style>
