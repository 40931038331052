var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"PaymentForm",staticClass:"PaymentForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_vm._v("Folio")]),_c('p',{staticClass:"mb-0 title text--primary"},[_vm._v(" "+_vm._s(_vm.sale ? _vm.sale.folio : "0000000")+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_vm._v("Total")]),_c('p',{staticClass:"mb-0 title"},[_vm._v("$ "+_vm._s(_vm.sale ? _vm.sale.amount : "000.00"))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_vm._v("Cliente")]),_c('p',{staticClass:"mb-0 font-weight-bold text--primary text-uppercase"},[_vm._v(" "+_vm._s(_vm.sale ? ((_vm.sale.client.first_name) + " " + (_vm.sale.client.last_name)) : "... ... ...")+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.paymentTypes,"label":"Tipo de Pago","disabled":_vm.paymentTypesLoading,"rules":[function (v) { return !!v || 'Tipo de Pago es obligatorio.'; }],"item-text":"name","item-value":"id"},model:{value:(_vm.form.payment_type_id),callback:function ($$v) {_vm.$set(_vm.form, "payment_type_id", $$v)},expression:"form.payment_type_id"}})],1)],1),(_vm.form.payment_type_id == 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Pago","required":"","rules":[
            function (v) { return !!v || 'Pago es obligatorio.'; },
            function (v) { return (v &&
                Number.parseFloat(v) >=
                  Number.parseFloat(_vm.sale ? _vm.sale.amount : 0)) ||
              'El Pago no es valido'; }
          ],"prefix":"$","autofocus":""},model:{value:(_vm.form.payment),callback:function ($$v) {_vm.$set(_vm.form, "payment", $$v)},expression:"form.payment"}})],1)],1):_vm._e(),(
        Number.parseFloat(_vm.form.payment) >=
          Number.parseFloat(_vm.sale ? _vm.sale.amount : 0)
      )?_c('v-row',[_c('v-col',{staticClass:"pt-0 d-inline-flex align-end",attrs:{"cols":"12"}},[_c('div',[_vm._v("Cambio:")]),_c('p',{staticClass:"mb-0 ml-1 font-weight-bold subtitle-1"},[_vm._v(" $ "+_vm._s(Number.parseFloat( Number.parseFloat(_vm.form.payment) - Number.parseFloat(_vm.sale ? _vm.sale.amount : 0) ).toFixed(2))+" ")])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end pb-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){return _vm.$store.commit('closePaymentModal')}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary darken-1"},on:{"click":function($event){return _vm.paid()}}},[_vm._v(" Finalizar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }