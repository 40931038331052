<template>
  <v-card
    class="ProductInfoCard mt-2"
    outlined
    :loading="$store.state.products.loading"
  >
    <v-row>
      <v-col class="py-0 pr-0" cols="12" md="4">
        <v-card
          style="height: 100%;"
          class="d-flex justify-center align-center elevation-0"
          label
        >
          <v-chip
            style="position: absolute; bottom: 5px;"
            label
            x-small
            class="rounded-sm"
            outlined
          >
            {{ product.category }}
          </v-chip>
          <div class="d-flex justify-center align-center" style="height: 100%;">
            <v-avatar :color="colorImg" size="100" tile>
              <v-img
                v-if="product.image"
                :src="`${product.image}`"
                alt="NOT FOUND"
              ></v-img>
              <v-icon v-else x-large>mdi-image-off</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col class="py-0 pl-0" cols="12" md="8">
        <v-list-item three-line class="pa-0">
          <v-divider vertical></v-divider>
          <v-list-item-content class="px-2">
            <v-list-item-title class="text-uppercase font-weight-bold">{{
              product.name
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              product.presentation
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{
              product.description
            }}</v-list-item-subtitle>
            <v-divider class="my-0"></v-divider>

            <p class="text-caption text-center font-weight-bold">
              PRECIOS ACTUALES
            </p>
            <v-divider class="my-0"></v-divider>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      S/IVA
                    </th>
                    <th class="text-center">
                      Venta
                    </th>
                    <th class="text-center">
                      Menudeo
                    </th>
                    <th class="text-center">
                      Mayoreo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">$ {{ round(product.price/1.16) }}</td>
                    <td class="text-center">$ {{ product.price }}</td>
                    <td class="text-center">$ {{ product.retail_price }}</td>
                    <td class="text-center">$ {{ product.wholesale_price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ProductInfoCard",
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    server: process.env.VUE_APP_API_SERVER
  }),
  computed: {
    colorImg() {
      return this.product.image ? "transparent" : "blue-grey lighten-5";
    }
  },
  methods: {
    round(price) {
      return Number.parseFloat(price).toFixed(2);
    }
  }
};
</script>

<style lang="scss">
.ProductInfoCard {
  table {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
