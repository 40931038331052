import Vue from "vue";
import axios from "axios";

export const SET_CONSULTATION_CLIENT = "SET_CONSULTATION_CLIENT";
export const SET_MEDICAL_CONSULTATIONS = "SET_MEDICAL_CONSULTATIONS";
export const SET_PERSONAL_HISTORIES = "SET_PERSONAL_HISTORIES";
export const SET_PHYSICAL_EXPLORATION = "SET_PHYSICAL_EXPLORATION";
export const SET_CURRENT_CONDITION = "SET_CURRENT_CONDITION";
export const SET_STUDIES = "SET_STUDIES";
export const SET_CABINETS_FILES = "SET_CABINETS_FILES";
export const SET_LABORATORIES_FILES = "SET_LABORATORIES_FILES";
export const SET_PROCEDURAL_NOTES = "SET_PROCEDURAL_NOTES";

export const SET_MEDICAL_CONSULTATION = "SET_MEDICAL_CONSULTATION";
export const SET_CLIENT_HISTORIES = "SET_CLIENT_HISTORIES";
export const SET_PERSONAL_HISTORY = "SET_PERSONAL_HISTORY";
export const SET_PATHOLOGICAL_HISTORY = "SET_PATHOLOGICAL_HISTORY";
export const SET_PROCEDURAL_NOTE = "SET_PROCEDURAL_NOTE";
export const SET_CONSENT_FILE = "SET_CONSENT_FILE";

export const SET_APPOINTMENT_DETAILS = "SET_APPOINTMENT_DETAILS";
export default {
  state: {
    client: {},
    medicalConsultations: [],
    medicalConsultation: {
      id: undefined,
      medical_diagnostic: '',
      medication: '',
      evolution: ''
    },
    personalHistory: {},
    appointmet_status: 'wait data base',
    physicalExploration: {},
    currentCondition: {},
    studies: [],
    files:{
      laboratory: [],
      cabinet: [],
      consent: null
    },
    proceduralNotes: [],
    proceduralNote: {
      id: undefined,
      note: '',
    },
    services:[]
  },
  mutations: {
    [SET_CONSULTATION_CLIENT](state, payload) {
      Vue.set(state, "client", payload);
    },
    [SET_PERSONAL_HISTORY](state, payload) {
      Vue.set(state, "personalHistory", payload);
    },
    [SET_PHYSICAL_EXPLORATION](state, payload) {
      Vue.set(state, "physicalExploration", payload);
    },
    [SET_CURRENT_CONDITION](state, payload) {
      Vue.set(state, "currentCondition", payload);
    },
    [SET_CABINETS_FILES](state, payload){
      Vue.set(state.files, 'cabinet', payload)
    },
    [SET_LABORATORIES_FILES](state, payload){
      Vue.set(state.files, 'laboratory', payload)
    },
    [SET_STUDIES](state, payload){
      Vue.set(state, 'studies', payload)
    },
    [SET_MEDICAL_CONSULTATIONS](state, payload){
      Vue.set(state, 'medicalConsultations', payload)
    },
    [SET_MEDICAL_CONSULTATION](state, payload){
      Vue.set(state, 'medicalConsultation', payload)
    },
    [SET_APPOINTMENT_DETAILS](state, payload){
      Vue.set(state, 'client', payload.client)
      Vue.set(state,'appointmet_status',payload.status)
      Vue.set(state, 'services', payload.details.map( d=>{
        let obj= d.product
        obj.price_sale=parseFloat(obj.price)
        obj.price=obj.price_sale
        obj.quantity=1
        return obj;
      } ))
    },
    [SET_PROCEDURAL_NOTES](state, payload){
      Vue.set(state,'proceduralNotes',payload)
    },
    [SET_PROCEDURAL_NOTE](state, payload){
      Vue.set(state,'proceduralNote',payload)
    },
    [SET_CONSENT_FILE](state, payload){
      Vue.set(state.files,'consent',payload)
    }
  },
  actions: {
    fetchClient(context, id){
      return axios
        .get(`api/clients/${id}`)
    },
    fetchAppointmentDetails(context, id){
      return axios
        .get(`api/appointments/${id}/details`)
    },
    fetchMedicalConsultations(context) {
      return axios
        .get(
          `api/client/${context.state.client.id}/medicalConsultations`
        )
        .then( ({data}) => data.data)
        .then( medicalConsultations =>{
          if(context.state.appointmet_status!='PENDIENTE' && medicalConsultations.length>0){
            context.commit(SET_MEDICAL_CONSULTATION, medicalConsultations[medicalConsultations.length-1])
            context.commit(SET_MEDICAL_CONSULTATIONS, medicalConsultations.slice(0,-1))
          }
          else{
            context.commit(SET_MEDICAL_CONSULTATION, {
              id: undefined,
              medical_diagnostic: '',
              medication: '',
              evolution: ''
            })
            context.commit(SET_MEDICAL_CONSULTATIONS, medicalConsultations)
          }
        });
    },
    fetchPersonalHistory(context) {
      return axios
        .get(
          `api/client/${context.state.client.id}/personalHistory`
        )
        .then( ({data}) => data.data)
        .then( personalHistories => {
          if( personalHistories.length == 0 )
            context.commit(SET_PERSONAL_HISTORY, {
              hereditary_diseases: "",
              non_pathological: "",
            })
          else
            context.commit(SET_PERSONAL_HISTORY, personalHistories)
        });
    },
    fetchPhysicalExploration(context) {
      return axios.get(`api/client/${context.state.client.id}/physicalExploration`)
      .then(({data})=>data.data)
      .then(physicalExploration => {
        if( physicalExploration.length == 0 )
          context.commit(SET_PHYSICAL_EXPLORATION, {
            topography: '',
            morphology: '',
            remnants_skin_and_attachments:''
          })
        else
          context.commit(SET_PHYSICAL_EXPLORATION, physicalExploration)
      })
    },
    fetchCurrentCondition(context) {
      return axios.get(`api/client/${context.state.client.id}/currentCondition`)
      .then(({data})=>data.data)
      .then(currentCondition => {
        if( currentCondition.length == 0 )
          context.commit(SET_CURRENT_CONDITION, {
            current_condition: '',
            symptoms: '',
            treatments: '',
            evolution: '',
          })
        else
          context.commit(SET_CURRENT_CONDITION, currentCondition)
      })
    },
    fetchStudies(context){
      return axios.get(`api/client/${context.state.client.id}/studies`)
      .then(({data})=>data.data)
      .then(studies => {
        context.commit(SET_STUDIES, studies)
      })
    },
    fetchProceduralNotes(context){
      return axios.get(`api/client/${context.state.client.id}/proceduralNotes`)
      .then(({data})=>data.data)
      .then(notes=>{
        context.commit(SET_PROCEDURAL_NOTES,notes)
      })
    },
    savePersonalHistory(context){
      if( context.state.personalHistory.client_id == undefined ){
        return axios.post(`/api/client/${context.state.client.id}/personalHistory`,context.state.personalHistory)
      }
      return axios.put(`/api/personalHistory/${context.state.personalHistory.client_id}`,context.state.personalHistory)
    },
    savePhysicalExploration(context) {
      if( context.state.physicalExploration.id == undefined ) {
        return axios.post(`api/client/${context.state.client.id}/physicalExploration`,context.state.physicalExploration)
      }
      return axios.put(`api/physicalExploration/${context.state.physicalExploration.id}`,context.state.physicalExploration)
    },
    saveCurrentCondition(context) {
      if( context.state.currentCondition.id == undefined ) {
        return axios.post(`api/client/${context.state.client.id}/currentCondition`,context.state.currentCondition)
      }
      return axios.put(`api/currentCondition/${context.state.currentCondition.id}`,context.state.currentCondition)
    },
    saveFiles(context) {
      const formData = new FormData();
      for (const i of Object.keys(context.state.files.cabinet)) {
        formData.append('cabinet[]', context.state.files.cabinet[i])
      }
      for (const i of Object.keys(context.state.files.laboratory)) {
        formData.append('laboratory[]', context.state.files.laboratory[i])
      }
      return axios.post(`api/client/${context.state.client.id}/studies`, formData)
    },
    saveConsultation(context,consultation_id){
      if(consultation_id)
        return axios.put(`api/medicalConsultations/${consultation_id}`,context.state.medicalConsultation)
      else
        return axios.post(`api/client/${context.state.client.id}/medicalConsultations`,context.state.medicalConsultation)
    },
    saveSale(context, appointment_id){
      return axios.post(`api/appointments/${appointment_id}/sales`,{services:context.state.services})
    },
    saveProceduralNote(context){
      if(context.state.proceduralNote.id){
        return axios.put(`api/proceduralNotes/${context.state.proceduralNote.id}`,context.state.proceduralNote)
      } else {
        return axios.post(`api/client/${context.state.client.id}/proceduralNotes`,context.state.proceduralNote)
      }
    },
    saveInformedConsent(context){
      const formData = new FormData();
      formData.append('consent', context.state.files.consent);
      return axios.post(`api/client/${context.state.client.id}/studies`, formData)
    }
  },
  getters: {
    pathologicalHistories: state => {
      return state.pathological_diseases;
    },
    cabinetStudies: state => {
      return state.studies.filter(e=>e.type=='cabinet').map(f=>{
        let p  = f.url.split('/')
        let c = p.length
        f.name = p[c-1]
        return f
      })
    },
    laboratoryStudies: state => {
      return state.studies.filter(e=>e.type=='laboratory').map(f=>{
        let p  = f.url.split('/')
        let c = p.length
        f.name = p[c-1]
        return f
      })
    },
    medicalConsultations: state => {
      return state.medicalConsultations
    },
    proceduralNotes: ({proceduralNotes}) => proceduralNotes.reverse(),
    informedConsents: state => {
      return state.studies.filter(e=>e.type=='consent').map(f=>{
        let p  = f.url.split('/')
        let c = p.length
        f.name = p[c-1]
        return f
      })
    }
  }
};
