<template>
  <v-menu
    v-model="cardaAppointmentState"
    :close-on-content-click="false"
    :activator="nodeSelectedAppointment"
    offset-x
  >
    <v-card color="grey lighten-4" min-width="350px" flat>
      <v-toolbar :color="appointmentSelectedData.color" dark>
        <v-btn icon @click="editItem()">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-toolbar-title
          v-html="appointmentSelectedData.status"
        ></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="deleteItem()">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p>Cliente: {{ fullNameClient }}</p>
        <p>Atendido por: {{ fullNameEmployee }}</p>
        <p>Fecha y hora: {{ appointmentSelectedData.start }}</p>
        <p>
          Hora estimada para finalizar:
          {{ appointmentSelectedData.hour_end }}
        </p>
        <p>Servicios:</p>
        <p
          v-if="
            appointmentSelectedData.products &&
            appointmentSelectedData.products.length === 0
          "
        >
          Sin Servicios registrados
        </p>
        <p
          v-for="producto in appointmentSelectedData.products"
          :key="producto.id"
        >
          {{ producto.product ? producto.product.name : "No definido" }}
        </p>
        <p>
          Observación: {{ appointmentSelectedData.observation }}
        </p>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapFields({
      cardaAppointmentState: "appointments.cardaAppointmentState",
      nodeSelectedAppointment: "appointments.calendar.nodeSelectedAppointment",
      appointmentSelectedData: "appointments.calendar.appointmentSelectedData",

      id: "appointments.form.appointment.id",
      client: "appointments.form.appointment.client_id",
      employee: "appointments.form.appointment.employee_id",
      date: "appointments.form.appointment.date",
      startTime: "appointments.form.appointment.hour",
      endTime: "appointments.form.appointment.hour_end",
      servicesSelected: "appointments.form.appointment.products",
      observation: "appointments.form.appointment.observation",

      exchangeForm: "EXCHANGE_FORM_CREATE_APPOINTMENT",
    }),
    fullNameClient() {
      var client = this.appointmentSelectedData.cliente;
      if (client) {
        return (
          client.first_name + " " + client.maiden_name + " " + client.last_name
        );
      }
      return "";
    },
    fullNameEmployee() {
      var employee = this.appointmentSelectedData.empleado;
      if (employee) {
        return (
          employee.first_name +
          " " +
          employee.maiden_name +
          " " +
          employee.last_name
        );
      }
      return "";
    },
  },
  methods: {
    ...mapActions({
      deleteAppointment: "deleteAppointment"
    }),
    ...mapMutations({
      exchangeForm: "EXCHANGE_FORM_CREATE_APPOINTMENT",
    }),
    deleteItem() {
      if (confirm("Desea cancelar esta cita?")) {
        this.deleteAppointment().then(this.$snotify.success('Eliminado correctamente'))
      }
    },
    editItem() {
      this.id = this.appointmentSelectedData.id;
      this.client = this.appointmentSelectedData.client_id;
      this.employee = this.appointmentSelectedData.employee_id;
      this.date = this.appointmentSelectedData.date;
      this.startTime = this.appointmentSelectedData.hour;
      this.endTime = this.appointmentSelectedData.hour_end;
      this.servicesSelected = this.pluck(
        this.appointmentSelectedData.products,
        "product_id"
      );
      this.observation = this.appointmentSelectedData.observation;
      this.$store.dispatch('getServicesForm');
      this.exchangeForm();
    },
    pluck(array, key) {
      return array ? array.map((o) => o[key]) : [];
    },
  },
};
</script>