<template>
  <v-form class="PaymentForm" ref="PaymentForm" v-model="valid" lazy-validation>
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12" md="6">
          <div>Folio</div>
          <p class="mb-0 title text--primary">
            {{ sale ? sale.folio : "0000000" }}
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <div>Total</div>
          <p class="mb-0 title">$ {{ sale ? sale.amount : "000.00" }}</p>
        </v-col>
        <v-col cols="12">
          <div>Cliente</div>
          <p class="mb-0 font-weight-bold text--primary text-uppercase">
            {{
              sale
                ? `${sale.client.first_name} ${sale.client.last_name}`
                : "... ... ..."
            }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :items="paymentTypes"
            label="Tipo de Pago"
            v-model="form.payment_type_id"
            :disabled="paymentTypesLoading"
            :rules="[v => !!v || 'Tipo de Pago es obligatorio.']"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="form.payment_type_id == 1">
        <v-col cols="12">
          <v-text-field
            v-model="form.payment"
            label="Pago"
            required
            :rules="[
              v => !!v || 'Pago es obligatorio.',
              v =>
                (v &&
                  Number.parseFloat(v) >=
                    Number.parseFloat(sale ? sale.amount : 0)) ||
                'El Pago no es valido'
            ]"
            prefix="$"
            autofocus
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        v-if="
          Number.parseFloat(form.payment) >=
            Number.parseFloat(sale ? sale.amount : 0)
        "
      >
        <v-col cols="12" class="pt-0 d-inline-flex align-end">
          <div>Cambio:</div>
          <p class="mb-0 ml-1 font-weight-bold subtitle-1">
            $
            {{
              Number.parseFloat(
                Number.parseFloat(form.payment) -
                  Number.parseFloat(sale ? sale.amount : 0)
              ).toFixed(2)
            }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-end pb-0">
          <v-btn class="mr-2" text @click="$store.commit('closePaymentModal')">
            Cancelar
          </v-btn>
          <v-btn color="primary darken-1" @click="paid()">
            Finalizar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "PaymentForm",
  data: () => ({
    valid: true,
    form: {
      payment_type_id: 1,
      payment: null
    }
  }),
  computed: {
    sale() {
      return this.$store.state.sales.paysale;
    },
    paymentTypes() {
      return this.$store.getters.getPaymentTypes;
    },
    paymentTypesLoading() {
      return this.$store.state.sales.paymentTypesLoading;
    }
  },
  mounted() {
    this.$store.dispatch("LoadPaymentTypes");
  },
  methods: {
    paid() {
      if (this.$refs.PaymentForm.validate()) {
        this.$store
          .dispatch("UpdateStatus", {
            id: this.sale.id,
            status: "paid",
            data: this.form
          })
          .then(r => {
            if (!r.error) {
              this.$store.commit("closePaymentModal");
              this.clearForm();
              this.$store.dispatch("FecthTicket", r.data.data.id).then(r => {
                if (!r.error) {
                  this.$store.commit("SET_TICKET", r.data);
                  this.$store.commit("openModalTicket");
                } else {
                  this.$snotify.error("Error al Generar Ticket");
                }
              });
              if (this.$route.name == "newsales") {
                this.$store.dispatch("FetchSale", this.sale.id);
              } else {
                this.$store.dispatch("LoadPaySales");
              }
              this.$store.commit("SET_PAYSALE", null);
            } else {
              this.$snotify.error("Error al Pagar Venta");
            }
          });
      }
    },
    clearForm() {
      this.form = {
        payment_type_id: 1,
        payment: null
      };
      this.$refs.PaymentForm.resetValidation();
    }
  }
};
</script>

<style lang="scss" scoped></style>
