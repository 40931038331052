<template>
  <v-navigation-drawer v-model="drawer" width="400" right app>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Carro de transferencias</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon small @click="drawer = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid>
      <v-row >
        <v-list three-line>
          <v-subheader>Transferencias en esta sesión</v-subheader>
          <template v-for="(lot, index) in carTransfer">
            <v-list-item :key="index+'a'">
              <v-list-item-content>
                <p>{{lot.product}}</p>
                <v-list-item-subtitle>Se ha transferido un total de {{lot.transfer}} desde {{lot.branch_name}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  data: () => ({}),
  methods: {},
  computed: {
    ...mapFields({
      branchFrom: "transfers.branchFromID",
      branchTo: "transfers.branchToID",
      carTransfer: "transfers.carTransfer",
      drawer: "transfers.drawer",
    }),
  },
};
</script>

<style>
</style>