<template>
  <div class="px-2">
    <div class="text-subtitle-1 text-center transition-swing">Servicios comprados</div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Servicios</th>
            <th class="text-center">Fecha</th>
            <th class="text-center">Cantidad</th>
            <th class="text-center">Estatus</th>
            <th class="text-center">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $store.getters.sessions" :key="item.name">
            <td>{{ item.product }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ onCar(item.id) ? 'AGREGADO' : 'DISPONIBLE' }}</td>
            <td>
              <v-btn icon color="primary" @click="addService(item)" :disabled="onCar(item.id)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="$store.getters.sessions.length==0">
            <td colspan="5">Este pacinte no tiene servicios comprados</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "ShoppingHistory",

  props: {
    client: {
      type: Object,
      default: () => ({})
    },
  },

  data: () => ({
  }),

  methods: {
    addService(item) {
      this.$store.commit("ADD_PRODUCT", {
        appointment_id: this.$store.state.medication.appoiment_id,
        deleted_at: null,
        id: null,
        product_id: item.product_id,
        product_name: item.product,
        product_price: 0,
        quantity: item.quantity,
        status: "Activo",
        uses: 1,
        session_id: item.id
      });
      // this.$store.commit("CHANGE_STATUS", { product_id: item.product_id, status: "Agregado" });
    },
    onCar(id){
      return this.$store.getters.medicationcart.find(e=>e.session_id==id) != undefined
    }
  },
};
</script>

<style lang="scss" scoped></style>
