<template>
  <v-dialog v-model="modalCancel" max-width="450px" persistent>
    <v-card class="SaleInfoModal">
      <v-toolbar elevation="1" color="error darken-1" dark>
        <v-toolbar-title>CANCELAR VENTA</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          icon
          small
          @click="$store.commit('closeCancelSaleModal')"
          :disabled="loading"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pb-1 px-3">
        <v-row>
          <v-col cols="12" md="6">
            <div>Folio</div>
            <p class="mb-0 title text--primary">
              {{ sale ? sale.folio : "0000000" }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <div>Total</div>
            <p class="mb-0 title">$ {{ sale ? sale.amount : "000.00" }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <div>Fecha y Hora</div>
            <p class="mb-0 font-weight-bold text--primary">
              {{ sale ? sale.date : "..." }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <div>Cliente</div>
            <p class="mb-0 font-weight-bold text--primary text-uppercase">
              {{
                sale
                  ? `${sale.client.first_name} ${sale.client.last_name}`
                  : "... ... ..."
              }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          :disabled="loading"
          :loading="loading"
          class="px-2 mx-2"
          color="error darken-1"
          @click="cancel()"
          >Cancelar</v-btn
        >
        <v-btn
          :disabled="loading"
          class="mx-2"
          text
          @click="$store.commit('closeCancelSaleModal')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "ConfirmCancelModal",
  props: {
    callback: {
      type: String,
      default: null
    },
    redirect: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapFields({
      modalCancel: "sales.saleCancelModal",
      sale: "sales.sale",
      loading: "sales.updateSaleStatus"
    })
  },
  methods: {
    cancel() {
      this.$store
        .dispatch("UpdateStatus", {
          id: this.sale.id,
          status: "cancel"
        })
        .then(r => {
          if (!r.error) {
            if (this.callback) this.$store.dispatch(this.callback);
            this.$store.commit("closeCancelSaleModal");
            console.log(this.redirect);
            if (this.redirect) {
              this.$router.push({
                path: this.redirect
              });
            }
          } else {
            this.$snotify.error("Error al Cancelar Venta");
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
