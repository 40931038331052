<template>
<v-container class="Products">
    <h1 class="text-center mb-1">Clientes</h1>
    <ClientsList :modalHistoryClinic.sync="showClinic" :modalHistoryPayment.sync="showPayment"></ClientsList>
    <!-- create form -->
    <v-dialog
      v-model="create"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Cliente</span>
        </v-card-title>
        <v-card-text>
          <ClientForm></ClientForm>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- expediente clinico -->
    <v-dialog
      v-model="showClinic"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Expediente</span>
        </v-card-title>
        <v-card-text>
          <consultation :readOnly="true" :client_ready="true" v-if="showClinic"></consultation>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" block @click="showClinic=false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- expediente de compras -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="showPayment"
    >
        <v-card>
          <v-toolbar color="primary" dark>Historial de cliente</v-toolbar>
          <v-card-text>
            <div class="text-h5 my-3">Servicios (historial)</div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Producto
                    </th>
                    <th class="text-left">
                      Fecha
                    </th>
                    <th class="text-left">
                      Cantidad
                    </th>
                    <th class="text-left">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in $store.getters.sessionClient"
                    :key="item.name"
                  >
                    <td>{{ item.product }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.amount }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="text-h5 my-3">Compras - Productos (historial)</div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Producto
                    </th>
                    <th class="text-left">
                      Fecha
                    </th>
                    <th class="text-left">
                      Cantidad
                    </th>
                    <th class="text-left">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in $store.getters.productClient"
                    :key="item.name"
                  >
                    <td>{{ item.product }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.amount }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="secondary" block @click="showPayment = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import ClientForm from "../components/Client/ClientForm";
import ClientsList from "../components/Client/ClientsList";
import Consultation from "@/components/Consultation/index";

export default {
  components:{
    ClientForm,
    ClientsList,
    Consultation
  },
  computed: {
    ...mapFields({
      create: "clients.modal",
    }),
  },
  data: ()=>({
    showClinic: false,
    showPayment: false,
  })
};
</script>
