<template>
  <v-container>
    <v-row>
      <v-autocomplete
        :items="searchedAppointments"
        :filter="customSearch"
        :search-input.sync="search"
        :loading="searching"
        item-text="id"
        label="Escribe para buscar"
        hint="Buscar por nombre ó telefono"
        class=""
        solo-inverted
        hide-no-data
        return object
      >
        <template v-slot:item="data">
          <v-list-item-icon class="mr-2 ml-0 pt-3 pl-0">
            <v-icon color="secondary" v-if="data.item.status=='FINALIZADA'">mdi-checkbox-marked-circle</v-icon>
            <v-icon color="red" v-if="data.item.status=='CANCELADA'">mdi-cancel</v-icon>
            <v-icon color="success" v-if="data.item.status=='PENDIENTE'">mdi-minus-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-html="data.item.client"
            ></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.employee"
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              v-html="data.item.services"
            ></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
              <v-list-item-action-text v-text="`${data.item.start} - ${data.item.hour_end}`">
              </v-list-item-action-text>
          </v-list-item-action>
        </template>
      </v-autocomplete>
    </v-row>
    <v-row v-show="cargaInicial" justify="center">
      <v-skeleton-loader
        max-width="1000px"
        type="list-item, card-heading, divider, date-picker-options, date-picker-days, actions"
      ></v-skeleton-loader>
    </v-row>
    <v-row>
      <Cards></Cards>
    </v-row>
    <v-row v-show="!cargaInicial" no-gutters>
      <v-col cols="12" sm="4" md="4">
        <v-col>
          <MiniCalendar></MiniCalendar>
        </v-col>
        <v-col>
          <ListElementsCalendar></ListElementsCalendar>
        </v-col>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <CalendarAppointments></CalendarAppointments>
      </v-col>
    </v-row>
    <CreateAppointment>
    </CreateAppointment>
    <ButtonPlusCreate></ButtonPlusCreate>
    <DrawerRight></DrawerRight>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CreateAppointment from "@/components/Appointment/formCreate/CreateAppointment";
import CalendarAppointments from "@/components/Appointment/calendar/CalendarAppointments";
import DrawerRight from "@/components/Appointment/layout/DrawerRight";
import Cards from "@/components/Appointment/cards/Cards";

import MiniCalendar from "@/components/Appointment/basicComponents/MiniCalendar";
import ListElementsCalendar from "@/components/Appointment/basicComponents/ListElementsCalendar";
import ButtonPlusCreate from "@/components/Appointment/basicComponents/ButtonPlusCreate";
import axios from 'axios'

export default {
  name: "Citas",
  data: () => ({
    searchedAppointments: [],
    search: '',
    searching: false,
  }),
  components: {
    CalendarAppointments,
    CreateAppointment,
    DrawerRight,
    MiniCalendar,
    ListElementsCalendar,
    ButtonPlusCreate,
    Cards,
  },
  computed: {
    ...mapGetters({
      cargaInicial: "getLoadAppointmentsState",
    }),
  },
  methods: {
    searchAppointments: async (value, self) => {
      if (value==""|!value) {
        self.searchedAppointments = [];
        return
      }
      if (self.searching) {
        return
      }
      self.searching = true
      axios
        .get(`api/appointments?search=${value}`)
        .then(({data})=>{
          self.searchedAppointments=data.data.map(e=>{
            e.client=`${e.cliente.first_name} ${e.cliente.last_name} ${e.cliente.maiden_name || ''}`.toUpperCase()
            e.employee=`${e.empleado.first_name} ${e.empleado.last_name} ${e.empleado.maiden_name || ''}`.toUpperCase()
            e.services=JSON.stringify(e.products.map(p=>p.product.name)).replaceAll(/[[\]{}"']/g,'')
            e.disabled=true
            return e;
          })
        }).catch(()=>{

        }).finally(()=>{
          self.searching=false
        })
    },
    customSearch(item, queryText) {
      const textOne =
        item.cliente.mobil_phone.toLowerCase() + item.client.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
  },
  watch: {
    search(value) {
      // Debounce the input and wait for a pause of at 
      // least 200 milliseconds. This can be changed to 
      // suit your needs.
      // debounce(this.makeSearch, 200)(value, this)
      this.searchAppointments(value,this)
    }
  }
};
</script>
