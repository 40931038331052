<template>
  <v-container class="Sales">
    <h1 class="text-center mb-1">Ventas</h1>
    <SalesTable></SalesTable>
    <SaleInfoModal></SaleInfoModal>
    <TicketModal></TicketModal>
    <ConfirmCancelModal callback="LoadSales"></ConfirmCancelModal>
  </v-container>
</template>

<script>
import SalesTable from "@/components/Sales/SalesTable";
import SaleInfoModal from "@/components/Sales/SaleInfoModal";
import TicketModal from "@/components/Sales/TicketModal";
import ConfirmCancelModal from "@/components/Sales/ConfirmCancelModal";

export default {
  name: "Sales",
  components: {
    SalesTable,
    SaleInfoModal,
    TicketModal,
    ConfirmCancelModal
  }
};
</script>

<style lang="scss"></style>
