<template>
  <v-container>
      <v-col><BranchesSelect></BranchesSelect></v-col>
    <v-row>
      <v-col>
        <ProductsList></ProductsList>
      </v-col>
    </v-row>
    <ProductDetailDrawer></ProductDetailDrawer>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import BranchesSelect from "../components/Transfers/BranchesSelect";
import ProductsList from "../components/Transfers/ProductsList";
import ProductDetailDrawer from "../components/Transfers/Drawer";

export default {
  components: {
    BranchesSelect,
    ProductsList,
    ProductDetailDrawer,
  },
  computed: {
    ...mapFields({
    }),
  },
};
</script>
