<template>
  <v-container class="Products">
    <h1 class="text-center mb-1">Productos</h1>
    <ListOfProducts></ListOfProducts>
    <ProductModal></ProductModal>
    <ButtonAddProduct></ButtonAddProduct>
    <ProductDetailDrawer></ProductDetailDrawer>
  </v-container>
</template>

<script>
import ButtonAddProduct from "@/components/Products/ButtonAddProduct";
import ListOfProducts from "@/components/Products/ListOfProducts";
import ProductModal from "@/components/Products/ProductModal";
import ProductDetailDrawer from "@/components/Products/ProductDetailDrawer";

export default {
  name: "Products",
  components: {
    ProductModal,
    ListOfProducts,
    ButtonAddProduct,
    ProductDetailDrawer
  }
};
</script>

<style lang="scss"></style>
