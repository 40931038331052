<template>
  <v-text-field
    :label="description"
    v-model="entrada_de_formulario"
    :rules="not_rules ? [] : rules.entrada_de_formulario"
    :counter="46"
    @input="changeEvent($event)"
    cleareble
    required
  ></v-text-field>
</template>
<script>
export default {
  props: ["valor_entrada_campo", "description", "not_rules"],
  data() {
    return {
      entrada_de_formulario: this.valor_entrada_campo,
      rules: {
        entrada_de_formulario: [
          (v) => !!v || "Este campo es Obligatorio",
          (v) => (v && v.length <= 46) || "Menos de 46 Caracteres",
          (v) =>
            /^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ'])+[\s]?([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ']+)$/.test(
              v
            ) || "Valores no válidos",
        ],
      },
    };
  },
  methods: {
    changeEvent: function () {
      this.$emit("update:valor_entrada_campo", this.entrada_de_formulario);
    },
  },
  watch: {
    valor_entrada_campo: function (newVal) {
      this.entrada_de_formulario = newVal;
    },
  },
};
</script>
