

<template>
  <v-row>
    <v-col
      ><v-select
        v-model="branchFrom"
        :items="branches"
        item-text="name"
        item-value="id"
        label="Sucursal seleccionada"
        @change="cargarProductos"
      ></v-select
    ></v-col>
    <v-col
      ><v-select
        v-model="branchTo"
        :items="branches"
        item-text="name"
        item-value="id"
        label="Sucursal destino"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  data: () => ({}),
  computed: {
    ...mapFields({
      branches: "transfers.branches",
      products: "transfers.products",
      branchFrom: "transfers.branchFromID",
      branchTo: "transfers.branchToID",
    }),
  },
  methods: {
    cargarProductos() {
      this.$store.dispatch("getProducts");
    },
  },
  mounted() {
    this.$store.dispatch("getBranches");
  },
};
</script>
