<template>
  <v-row>
    <v-col cols="10">
      <v-autocomplete
        class="selectService"
        id="select-service"
        ref="selectService"
        v-model="service"
        :items="services"
        label="Servicio"
        item-text="name"
        item-value="id"
        return-object
        clearable
        :disabled="$store.state.lots.saveLoading || loadingServices"
        :loading="loadingServices"
        :filter="customSearch"
        @change="defineSalePrice"
      >
        <!-- @keydown.enter="enterkey()" -->
        <!-- <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
            label
          >
            {{ data.item.name }}
          </v-chip>
        </template>
 -->        <template v-slot:item="data">
          <template>
            <v-list-item-content class="selectService-listContent">
              <v-list-item-title class="text-uppercase">
                {{ data.item.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-weight-bold">
                $ {{ data.item.retail_price }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="2">
      <v-text-field
        v-model="sale_price"
        label="precio"
        @keyup="valPrice"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "SelectSelectInput",
  data: () => ({
    server: process.env.VUE_APP_API_SERVER,
    enter_switch: 0,
    services: [],
    loadingServices: false,
    sale_price:0
  }),
  computed: {
    ...mapFields({
      service: "sales.saleProduct",
    }),
  },
  mounted() {
    this.loadingServices = true;
    this.axios
      .get(`/api/services`)
      .then((res) => {
        this.services = res.data.data.map((s) => {
          s.label = `${s.name} - $${s.price}`;
          return s;
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loadingServices = false;
      });
  },
  methods: {
    /* enterkey() {
      if (this.product) {
        this.$store
          .dispatch("AddSaleDetail", {
            quantity: 1,
            discount: 0,
            product_id: this.product.id,
            promotion: 0
          })
          .then(r => {
            if (!r.error) {
              this.product = null;
              this.$store.dispatch("FetchSale", this.$route.params.id);
              this.$store.dispatch("LoadBranchProduct");
            } else {
              this.$snotify.error("Error al Agregar Producto");
            }
          });
      }
    }, */
    customSearch(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    defineSalePrice(){
      if(this.service){
        this.sale_price = parseInt(this.service.retail_price)
        this.service.sale_price = parseInt(this.service.retail_price)
      }
    },
    valPrice() {
      if(!this.service) return;
      if (this.sale_price > parseInt(this.service.retail_price)) {
        this.sale_price=parseInt(this.service.retail_price)
        this.$snotify.info("No se puede superar el precio de lista");
      }
      this.service.sale_price=this.sale_price
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 991.98px) {
  .selectService-listContent {
    max-width: 450px;
  }
}
@media (max-width: 1199.98px) {
  .selectService-listContent {
    max-width: 450px;
  }
}
</style>
