<template>
  <v-flex>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :label="etiqueta"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        ref="picker"
        v-if="menu"
        v-model="date"
        :min="min"
        full-width
        @change="actualizarModelo()"
      ></v-time-picker>
    </v-menu>
  </v-flex>
</template>
<script>
export default {
  props: ["modelo", "label","min"],
  data() {
    return {
      date: this.modelo,
      menu: false,
      etiqueta: this.label,
    };
  },
  watch: {

    modelo: function (newVal) {
      this.date = newVal;
    },
  },
  methods: {
    actualizarModelo: function (date) {
      this.$refs.menu.save(date);
      this.$emit("update:modelo", this.date);
    },
  },
};
</script>
