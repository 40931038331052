var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"pt-0",attrs:{"prepend-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","color":"accent darken-3","disabled":_vm.$store.state.sales.loading},on:{"click":function($event){return _vm.$store.dispatch('ReloadCashRegister')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-update")])],1)]}}])},[_c('span',[_vm._v("Recargar")])])],1),_c('v-data-table',{staticClass:"px-3",attrs:{"headers":_vm.headers,"items":_vm.$store.getters.getPaySales,"items-per-page":5,"single-select":"","item-key":"id"},on:{"item-selected":_vm.SaleSelected,"click:row":_vm.SaleClick},scopedSlots:_vm._u([{key:"item.folio",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.folio))])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.client_name))])]}},{key:"item.employee_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.employee_name))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.amount)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.paid(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent darken-2"}},[_vm._v("mdi-cash")])],1)]}}],null,true)},[_c('span',[_vm._v("Pagar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showSale(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary darken-1"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editSale(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"amber darken-3"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancelSale(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancelar")])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }