<template>
  <v-card outlined>
    <v-card-title class="justify-center body-2 blue-grey lighten-5 px-1 py-3">
      <strong class="grey--text text--darken-2">RESUMEN DE VENTA</strong>
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>PRODUCTOS</td>
            <td class="text-end">
              {{
                $store.state.sales.sale
                  ? $store.state.sales.sale.details.length
                  : 0
              }}
            </td>
          </tr>
          <tr>
            <td>TOTAL</td>
            <td class="text-end font-weight-bold">
              $
              {{ $store.state.sales.sale ? $store.state.sales.sale.amount : 0 }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "SaleSummary"
};
</script>

<style lang="scss" scoped></style>
