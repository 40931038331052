import axios from 'axios'

export const EXCHANGE_MODAL_CARGA_INICIAL = 'EXCHANGE_MODAL_CARGA_INICIAL'

export default {
  state: {
    esperaCargaInicial: false,
  },
  mutations: {
    [EXCHANGE_MODAL_CARGA_INICIAL](state) {
      state.esperaCargaInicial = false
    },
  },
  actions: {
    LoadAllBirthdays: async function (context) {
      let formData = new FormData()
      formData.append('month_start', context.state.month_start)
      formData.append('month_end', context.state.month_end)
      axios
        .post('/api/ruta/example', formData)
        .then(({ data }) => {
          context.commit('currentbirthdays', data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  getters: {
    getallevents: state => {
      return state.events
    },
  }
}
