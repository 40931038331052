<template>
  <div class="ButtonAddProduct">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="accent darken-1"
          dark
          fab
          large
          fixed
          bottom
          right
          v-bind="attrs"
          v-on="on"
          @click="newProduct()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Agregar Producto</span>
    </v-tooltip>
  </div>
</template>

<script>
import EventBus from "@/EventBus";
export default {
  name: "ButtonAddProduct",
  methods: {
    newProduct() {
      EventBus.$emit("ProductForm:resetValidation");
      this.$store.commit("openModalNewProduct");
    }
  }
};
</script>

<style lang="scss" scoped></style>
