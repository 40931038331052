<template>
    <v-expansion-panels elevation="4" v-model="panel" multiple>
      <!-- consulta -->
      <v-expansion-panel>
        <v-expansion-panel-header> <h3> Consulta </h3> </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-dialog v-model="historyDialog" width="650">
              <template v-slot:activator="{ on, attrs }">
                <v-row class="justify-end pt-3 pr-5">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Mostrar historial de consultas
                  </v-btn>
                </v-row>
              </template>
              <v-card>
                <v-card-title class="secondary">
                  <v-row class="justify-space-between align-center">
                    Consutas previas
                    <v-icon @click="historyDialog = false">mdi-close</v-icon>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-timeline
                    align-top
                    :dense="$vuetify.breakpoint.smAndDown"
                    v-if="$store.getters.medicalConsultations.length > 0"
                  >
                    <v-timeline-item
                      v-for="(item, i) in $store.getters.medicalConsultations"
                      :key="i"
                      fill-dot
                      color="accent"
                    >
                      <v-card color="primary">
                        <v-card-title>
                          {{
                            new Date(item.created_at).toLocaleDateString("Es", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          }}
                        </v-card-title>
                        <v-card-text class="white text--primary">
                          <h3 class="mb-1">Diagnostico:</h3>
                          <div v-html="item.medical_diagnostic"></div>
                          <h3 class="mb-1">Tratamiento:</h3>
                          <div v-html="item.medication"></div>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                  <!-- <v-card v-else> -->
                  <v-card-text class="text-center" v-else>
                    Sin consultas previas
                  </v-card-text>
                  <!-- </v-card> -->
                </v-card-text>
              </v-card>
            </v-dialog>
              <v-form @submit.prevent="">
                <v-row>
                <v-col cols="12">
                  <h4>Diagnostico</h4>
                </v-col>
                <v-col cols="12">
                  <ckeditor
                    :editor="CKEditor.editor"
                    :config="CKEditor.editorConfig"
                    v-model="diagnostic"
                    required
                    :disabled="readOnly"
                  ></ckeditor>
                </v-col>
                <v-col cols="12">
                  <h4>Tratamiento</h4>
                </v-col>
                <v-col cols="12">
                  <ckeditor
                    :editor="CKEditor.editor"
                    :config="CKEditor.editorConfig"
                    v-model="medication"
                    required
                    :disabled="readOnly"
                  ></ckeditor>
                </v-col>
                <v-col cols="12" v-if="subsecuente">
                  <h4>Evolución</h4>
                </v-col>
                <v-col cols="12" v-if="subsecuente">
                  <ckeditor
                    :editor="CKEditor.editor"
                    :config="CKEditor.editorConfig"
                    v-model="evolution"
                    required
                    :disabled="readOnly"
                  ></ckeditor>
                </v-col>
                </v-row>
              </v-form>
              <v-btn
                block
                color="primary"
                @click="saveConsultation"
                :loading="loadingConsultation"
                v-if="!readOnly"
              >
                {{ consultation_id ? "ACTUALIZAR" : "GUARDAR" }}
              </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- receta -->
      <v-expansion-panel>
        <v-expansion-panel-header>Receta</v-expansion-panel-header>
        <v-expansion-panel-content>
          <recipe :modal.sync="recipeModal"></recipe>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- venta -->
      <v-expansion-panel v-if="isNew && !readOnly">
        <v-expansion-panel-header>
          Servicios brindados
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- <v-card v-show="isNew && saved"> -->
            <!-- <v-card-title>  </v-card-title> -->
            <!-- <v-card-text> -->
              <v-row class="pl-3 align-center">
                <v-col cols="9">
                  <!-- <v-select :items="services" chips></v-select> -->
                  <v-autocomplete
                    v-model="serviceSelected"
                    :items="services"
                    :filter="servicesFilter"
                    color="primary"
                    item-text="label"
                    multiple
                    clearable
                    return-object
                  ></v-autocomplete>
                  <!-- @change="updateServices" -->
                </v-col>
                <v-col cols="3" class="">
                  <v-btn block @click="addService">agregar</v-btn>
                </v-col>
              </v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Servicio</th>
                      <th class="text-left">Costo</th>
                      <th class="text-left">Cantidad</th>
                      <th class="text-left">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in servicesSelected"
                      :key="item.id"
                    >
                      <td>{{ item.name }}</td>
                      <td>
                        <v-text-field
                          v-model="item.price_sale"
                          label="precio"
                          @keyup="valPrice(index)"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="item.quantity"
                          label="cantidad"
                          type="number"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-btn small text rounded @click="removeService(index)"
                          ><v-icon color="red">mdi-delete</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {{
                      servicesSelected.length
                    }}
                    servicios aplicados
                  </tfoot>
                </template>
              </v-simple-table>
            <!-- </v-card-text> -->
            <!-- <v-card-actions> -->
              <v-btn
                block
                color="primary"
                @click="saveSale"
                :loading="loadingSale"
                >FINALIZAR</v-btn
              >
            <!-- </v-card-actions> -->
          <!-- </v-card> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "ckeditor5-build-classic-spanish-advanced";
import { mapFields } from "vuex-map-fields";
import recipe from "./recipe.vue"

export default {
  name: "Consultation",
  components: {
    ckeditor: CKEditor.component,
    recipe
  },
  props: ["updateServices", 'readOnly'],
  data() {
    return {
      CKEditor: {
        editor: ClassicEditor,
        editorConfig: {
          removePlugins: [
            // "Essentials",
            "CKFinderUploadAdapter",
            "Alignment",
            "Autoformat",
            // "Bold",
            "Italic",
            "Underline",
            "Strikethrough",
            "BlockQuote",
            "CKFinder",
            "EasyImage",
            "Heading",
            "Image",
            "ImageCaption",
            "ImageStyle",
            "ImageToolbar",
            "ImageUpload",
            "Indent",
            "Link",
            // "List",
            "MediaEmbed",
            // "Paragraph",
            "PasteFromOffice",
            "Table",
            "TableToolbar",
            "TableProperties",
            "TableCellProperties",
            "Font",
          ],
          toolbar: [
            "bold",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "undo",
            "redo",
          ],
        },
      },
      historyDialog: false,
      services: [],
      serviceSelected: [],
      loadingConsultation: false,
      loadingSale: false,
      panel: [0,1],
      recipeModal: false
    };
  },
  watch: {},
  computed: {
    ...mapFields({
      consultation_id: "consultation.medicalConsultation.id",
      diagnostic: "consultation.medicalConsultation.medical_diagnostic",
      medication: "consultation.medicalConsultation.medication",
      evolution: "consultation.medicalConsultation.evolution",
      servicesSelected: "consultation.services",
    }),
    isNew() {
      return this.$store.state.consultation.appointmet_status == "PENDIENTE";
    },
    servicesPreSelected(){
      return this.$store.state.consultation.services
    },
    subsecuente() {
      return this.$store.getters.medicalConsultations.length > 0
    }
  },
  async mounted() {
    await this.$store.dispatch("fetchMedicalConsultations");
    this.fetchServices();
  },
  methods: {
    fetchServices() {
      this.axios
        .get(`/api/services`)
        .then((res) => {
          this.services = res.data.data.map((s) => {
            s.label = `${s.name} - $${s.price}`;
            return s;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    servicesFilter(item, queryText) {
      const text = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text.indexOf(searchText) > -1;
    },
    addService() {
      console.log("agregando");
      this.serviceSelected.forEach((element) => {
        let inArray =
          this.servicesSelected.find((s) => s.id == element.id) != undefined;
        if (!inArray) {
          let obj = Object.assign({}, element);
          obj.price_sale = parseFloat(obj.price);
          obj.price = obj.price_sale;
          obj.quantity = 1;
          this.servicesSelected.push(obj);
        }
      });
      this.serviceSelected = [];
    },
    removeService(index) {
      this.servicesSelected.splice(index, 1);
    },
    valPrice(index) {
      let element = this.servicesSelected[index];
      if (element.price_sale > element.price) {
        element.price_sale = element.price;
        this.$snotify.info("No se puede superar el precio de lista");
      }
    },
    saveConsultation() {
      this.loadingConsultation = true;
      this.$store
        .dispatch("saveConsultation",this.consultation_id)
        .then(({data}) => {
          this.consultation_id=data.data.id
          this.$snotify.success("Consulta guardada correctamente")
          this.panel = this.isNew ? [1,2] : [1]
          this.recipeModal=true
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$snotify.html(
              `<div class="snotifyToast__title"><b>Error de validación</b></div>
              <div class="snotifyToast__body">${Object.values(
                e.response.data.errors
              ).join("<br>")}</div>`,
              { type: "error", timeOut: 6000 }
            );
          }
        })
        .finally(() => {
          this.loadingConsultation = false;
        });
    },
    saveSale() {
      this.loadingSale = true;
      this.$store
        .dispatch("saveSale", this.$route.params.appointment_id)
        .then(() => {
          this.$router.replace("/tratamientos");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$snotify.html(
              `<div class="snotifyToast__title"><b>Error de validación</b></div>
              <div class="snotifyToast__body">${Object.values(
                e.response.data.errors
              ).join("<br>")}</div>`,
              { type: "error", timeOut: 6000 }
            );
          }
        })
        .finally(() => {
          this.loadingSale = false;
        });
    },
    closeRecipeModal(){
      this.recipeModal=false;
    }
  },
};
</script>

<style></style>
