<template>
  <v-navigation-drawer
    class="ProductDetailDrawer"
    v-model="$store.state.products.drawer"
    width="320"
    temporary
    right
    app
  >
    <v-toolbar dense elevation="1" dark :color="color">
      <v-toolbar-title>{{ product.type || "PRODUCTO" }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon small @click="closeDrawer()">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-avatar :color="colorImg" size="170" tile>
          <v-img
            v-if="product.image"
            :src="`${product.image}`"
          ></v-img>
          <v-icon v-else x-large>mdi-image-off</v-icon>
        </v-avatar>
      </v-row>
      <v-row align="center" justify="center">
        <p class="subtitle-2 text-center my-3 mx-3" style="line-height: 1;">
          {{ product.name || "NOMBRE" }}
        </p>
      </v-row>
      <v-row align="center" justify="center">
        <v-chip class="mb-3" label x-small outlined>
          {{ product.category || "SIN CATEGORIA" }}
        </v-chip>
      </v-row>
      <v-row align="center" class="mx-2"> </v-row>
      <v-row align="center" justify="center">
        <barcode
          :value="product.barcode || '123456'"
          :options="barcodeOptions"
        ></barcode>
      </v-row>
      <v-row class="mx-2 my-2 flex-column">
        <p class="caption mb-0 mt-2 text-left grey--text">
          Presentación:
        </p>
        <p class="body-2 mb-0 text-left mx-1">
          {{ product.presentation || "..." }}
        </p>
      </v-row>
      <v-row class="mx-2 my-2 flex-column">
        <p class="caption mb-0 mt-2 text-left grey--text">
          Descripción:
        </p>
        <p class="body-2 mb-0 text-left mx-1">
          {{ product.description || "..." }}
        </p>
      </v-row>
      <v-simple-table dense class="mt-3 mx-2">
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="caption grey--text text-left">Precio S/IVA:</td>
              <td class="body-2 text-left">
                $ {{ round(product.price / 1.16) || "000.00" }}
              </td>
            </tr>
            <tr>
              <td class="caption grey--text text-left">Precio Compra:</td>
              <td class="body-2 text-left">
                $ {{ product.price || "000.00" }}
              </td>
            </tr>
            <tr>
              <td class="caption grey--text text-left">Precio Menudeo:</td>
              <td class="body-2 text-left">
                $ {{ product.retail_price || "000.00" }}
              </td>
            </tr>
            <tr>
              <td class="caption grey--text text-left">Precio Mayoreo:</td>
              <td class="body-2 text-left">
                $ {{ product.wholesale_price || "000.00" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table dense class="mt-6 mx-2">
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="mt-5 caption grey--text text-left">
                Fecha de Creación:
              </td>
              <td class="caption grey--text text-right">
                {{
                  product.created_at
                    ? dateAndTimeFormat(product.created_at)
                    : "..."
                }}
              </td>
            </tr>
            <tr>
              <td class="caption grey--text text-left">
                Última Actualización:
              </td>
              <td class="caption grey--text text-right">
                {{
                  product.updated_at
                    ? dateAndTimeFormat(product.updated_at)
                    : "..."
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row align="center" justify="center" class="mt-3">
        <v-btn
          class="mx-1"
          icon
          large
          @click.stop="
            $store.commit('closeDrawerProduct');
            $store.commit('openModalProduct');
          "
        >
          <v-icon large color="accent darken-2">mdi-pencil</v-icon>
        </v-btn>

        <v-btn class="mx-1" icon large @click.stop="deleteProduct()">
          <v-icon large color="red lighten-1">mdi-delete-outline</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import EventBus from "@/EventBus";
import VueBarcode from "@chenfengyuan/vue-barcode";
import moment from "moment";
moment.locale("es-us");

export default {
  name: "ProductDetailDrawer",
  components: { barcode: VueBarcode },
  data: () => ({
    server: process.env.VUE_APP_API_SERVER,
    barcodeOptions: {
      height: 45,
      width: 1,
      fontSize: 14,
      margin: 0,
      lineColor: "#263238"
    }
  }),
  computed: {
    product() {
      return this.$store.state.products.product;
    },
    colorImg() {
      return this.product.image ? "transparent" : "blue-grey lighten-5";
    },
    color() {
      return this.product.type == "PRODUCTO"
        ? "primary darken-2"
        : "accent darken-2";
    }
  },
  methods: {
    dateAndTimeFormat(date) {
      return moment(date).format("L") + " " + moment(date).format("LT");
    },
    deleteProduct() {
      console.log(this.product);
      console.log("Eliminar");
    },
    closeDrawer() {
      EventBus.$emit("ProductForm:resetValidation");
      this.$store.commit("closeDrawerProduct");
    },
    round(price) {
      return Number.parseFloat(price).toFixed(2);
    }
  }
};
</script>

<style lang="scss">
.ProductDetailDrawer {
  table {
    tr:hover {
      background-color: transparent !important;
    }
    td {
      border-bottom: none !important;
      padding: 0 !important;
    }
  }
}
</style>
