<template>
  <v-dialog v-model="modal" persistent max-width="420px">
    <v-card>
      <v-toolbar elevation="1" color="primary darken-1" dark>
        PAGO DE VENTA
      </v-toolbar>
      <v-card-text>
        <PaymentForm></PaymentForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PaymentForm from "./PaymentForm";

export default {
  name: "PaymentModal",
  components: {
    PaymentForm
  },
  computed: {
    modal() {
      return this.$store.state.sales.paymentModal;
    }
  }
};
</script>

<style lang="scss" scoped></style>
