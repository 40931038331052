<template>
  <v-card flat>
    <v-card-title>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
        autofocus
        class="pt-0"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="mx-1"
            color="accent darken-3"
            v-bind="attrs"
            v-on="on"
            @click="$store.dispatch('ReloadCashRegister')"
            :disabled="$store.state.sales.loading"
          >
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </template>
        <span>Recargar</span>
      </v-tooltip>
    </v-card-title>
    <v-data-table
      v-model="selected"
      class="px-3"
      :headers="headers"
      :items="$store.getters.getPaySales"
      :items-per-page="5"
      single-select
      item-key="id"
      @item-selected="SaleSelected"
      @click:row="SaleClick"
    >
      <template v-slot:[`item.folio`]="{ item }">
        <strong>{{ item.folio }}</strong>
      </template>
      <template v-slot:[`item.client_name`]="{ item }">
        <span class="text-uppercase">{{ item.client_name }}</span>
      </template>
      <template v-slot:[`item.employee_name`]="{ item }">
        <span class="text-uppercase">{{ item.employee_name }}</span>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        $ {{ item.amount }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.stop="paid(item)">
              <v-icon color="accent darken-2">mdi-cash</v-icon>
            </v-btn>
          </template>
          <span>Pagar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.stop="showSale(item)">
              <v-icon color="primary darken-1">mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.stop="editSale(item)">
              <v-icon color="amber darken-3">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.stop="cancelSale(item)">
              <v-icon color="error">mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span>Cancelar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "PaySalesTable",
  data: () => ({
    search: "",
    headers: [
      { text: "Folio", value: "folio" },
      { text: "Fecha y Hora", value: "date" },
      { text: "Cliente", value: "client_name" },
      { text: "Empleado", value: "employee_name" },
      { text: "Total", value: "amount" },
      { text: "Opciones", value: "actions", align: "center", sortable: false }
    ],
    selected: []
  }),
  mounted() {
    this.$store.dispatch("LoadPaySales");
    this.$store.commit("SET_PAYSALE", null);
  },
  methods: {
    showSale(sale) {
      console.log(sale);
      this.$store.dispatch("FetchSale", sale.id).then(() => {
        this.$store.commit("openSaleInfoModal");
      });
    },
    SaleSelected(data) {
      if (data.value) {
        console.log(data.item);
        this.$store.commit("SET_PAYSALE", data.item);
      } else {
        this.$store.commit("SET_PAYSALE", null);
      }
    },
    SaleClick(sale, opt) {
      opt.select(!opt.isSelected);
      console.log(sale);
    },
    paid(sale) {
      console.log("Pagar Venta");
      this.$store.commit("SET_PAYSALE", sale);
      this.$store.commit("openPaymentModal");
    },
    cancelSale(sale) {
      this.$store.dispatch("FetchSale", sale.id).then(() => {
        this.$store.commit("openCancelSaleModal");
      });
    },
    editSale(sale) {
      this.$router.push({
        path: `/ventas/${sale.id}/create`
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
