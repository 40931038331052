
import axios from "axios";
export const SET_BRANCHES_TRANSFER = "SET_BRANCHES_TRANSFER";
export const SET_PRODUCTS_TRANSFER = "SET_PRODUCTS_TRANSFER";
export const ADD_TO_CAR_TRANSFER = "ADD_TO_CAR_TRANSFER";

export default {
  state: {
    branches: [],
    products: [],
    carTransfer: [],
    drawer:false,

    branchFromID:null,
    branchToID:null,
  },
  mutations: {
    [SET_BRANCHES_TRANSFER](state, data) {
      state.branches = data
    },
    [SET_PRODUCTS_TRANSFER](state, data) {
      state.products = data
    },
    [ADD_TO_CAR_TRANSFER](state, data) {
      state.carTransfer.push(data)
    },
  },
  actions: {
    async getBranches({ commit }) {
      axios
        .get(`/api/branches`)
        .then(response => commit(SET_BRANCHES_TRANSFER, response.data.data));
    },

    async getProducts({ state,commit }) {
      axios
        .get(`/api/products/branch/${state.branchFromID}`)
        .then(({data}) => commit(SET_PRODUCTS_TRANSFER, data.data));
    },

  },
  getters: {
  },

  modules: {
  }
}