<template>
  <v-dialog v-model="modalStatus" transition="dialog-bottom-transition" max-width="830" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-row justify="center">
        <v-spacer></v-spacer>
        <v-btn color="secondary" dark v-bind="attrs" v-on="on">
          <v-icon>mdi-eye</v-icon>
          Ver
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-print="'recipe'" color="primary"
          ><v-icon>mdi-printer</v-icon> Imprimir</v-btn
        >
        <v-spacer></v-spacer>
      </v-row>
    </template>
    <v-card>
      <v-card-title
        >Receta <v-spacer></v-spacer
        ><v-btn icon @click="modalStatus=false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text style="height: 400px">
        <v-sheet
          id="recipe"
          elevation="0"
          style="
            width: 780px;
            height: 1010px;
            display: grid;
            justify-context: space-beetwen;
          "
        >
          <!-- cabecera -->
          <div>
            <v-row>
              <v-col cols="6">
                <img
                  src="../../assets/imagotipo.png"
                  alt="CLINICA"
                  style="width: 300px"
                />
              </v-col>
              <v-col cols="6" class="text-right">
                <p class="mb-n1 py-0">Dra. Claudia Baños Segura</p>
                <!-- <v-divider style="width:50%;"></v-divider> -->
                <p
                  class="my-n1 py-0 grey--text text-darken-1 text-caption"
                  style="
                    border-top: solid darkgray 2px;
                    margin-left: 97px;
                    font-size: 0.65rem !important;
                  "
                >
                  Dermatóloga / Cirugía Dermatoncológica
                </p>
                <p
                  class="mb-n2 py-0 text-caption"
                  style="font-size: 0.7rem !important"
                >
                  Cp. 6417725
                </p>
                <p
                  class="mb-n1 py-0 text-caption"
                  style="font-size: 0.7rem !important"
                >
                  Ced. Esp 09269566
                </p>
                <p
                  class="
                    mb-n2
                    py-0
                    grey--text
                    text-darken-1 text-caption text-caption
                  "
                  style="font-size: 0.65rem !important"
                >
                  Certificada por el Consejo Mexicano de Dermatología
                </p>
                <p
                  class="mb-n2 py-0 grey--text text-darken-1 text-caption"
                  style="font-size: 0.65rem !important"
                >
                  No. de Certificado/1386
                </p>
              </v-col>
            </v-row>
            <v-row
              justify="space-between"
              class="text-body-2 ml-2 mr-1 px-2"
              style="border-bottom: solid darkgray 2px"
            >
              <span> <strong> Nombre: </strong> {{ nombre }} </span>
              <span> <strong> Fecha: </strong> {{ fecha }} </span>
              <span> <strong> Prox. Cita: </strong> </span>
              <span style="color: white"> &nbsp;</span>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <p class="text-body-2" v-html="tratamiento" style="text-align: justify !important;"></p>
            </v-col>
          </v-row>
          <v-row align="end"
            ><!-- style="position:absolute;bottom:0px;with:100%;" -->
            <v-col>
              <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n1 py-0"
              >
                Palmeras #615
              </p>
              <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n1 py-0"
              >
                Col. Reforma C.P. 68050
              </p>
              <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n1 py-0"
              >
                Oaxaca de Juárez, Oaxaca Mex.
              </p>
              <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n1 py-0"
              >
                Cel / Whatsapp: 5523002594, Tel: 9512052954
              </p>
            </v-col>
            <v-col class="text-right">
              <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n1 py-0"
              >
                <span><v-icon small>mdi-twitter</v-icon></span
                >@DermaCLINICA <span><v-icon small>mdi-facebook</v-icon></span
                >Kutánea
              </p>
              <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n1 py-0"
              >
                www.CLINICA.com.mx
              </p>
              <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n1 py-0"
              >
                Email: dra.claudiabanos@dermaqueen.mx
              </p>
              <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n2 py-0"
              >
                Horario de consulta a partir de las 10 am
              </p>
              <!-- <p
                style="font-size: 0.6rem !important"
                class="text-caption my-n1 py-0"
              >
                mar y jue de 10am a 6pm, sab de 10am a 2pm
              </p> -->
            </v-col>
          </v-row>
          <!-- </v-container> -->
        </v-sheet>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="modalStatus=false">
          Cerrar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="modalStatus=false" v-print="'recipe'"> <v-icon>mdi-printer</v-icon> Imprimir </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
function printSection(el, binding) {
  // insertar css especial para la impresión, esto mostrar un contenedor especial y ocultara el resto de contenido
  let style = document.createElement("style");
  style.type = "text/css";
  style.appendChild(
    document.createTextNode(
      "@media screen{#printSection{display:none}}@media print{#printSection,body{overflow:visible!important}body *{visibility:hidden}#printSection,#printSection *{visibility:visible}#printSection{position:absolute;left:0;top:0;right:0}#printSection table{width:100%!important}}"
    )
  );
  document.body.appendChild(style);

  // busco si existe mi sección para imprimir
  let printSection = document.getElementById("printSection");

  // si no existe la creo y la inserto en el body
  if (!printSection) {
    let contenedor = document.createElement("div");
    printSection = document.createElement("div");
    printSection.id = "printSection";
    printSection.style.maxHeight = "1050px !important;";
    printSection.style.Height = "1050px !important;";
    contenedor.appendChild(printSection);
    let app = document.getElementById("app");
    app.appendChild(contenedor);
  }

  // creo el evento del click para cada que se de click en imprimir
  el.addEventListener("click", () => {
    // busco el contenido a imprimir, esto ya que como parametro tenia el id
    var elemToPrint = document.getElementById(binding.value);

    //si existe el contenido entonces lo imprimiré
    if (elemToPrint) {
      printSection.innerHTML = ""; // limpio el contenido anterior de mi sección a imprimir
      printElement(elemToPrint);
    }
  });

  // funcion para imprimir
  function printElement(elem) {
    // creo un nodo y lo inserto dentro de mi sección a imprimir
    let domClone = elem.cloneNode(true);
    printSection.appendChild(domClone);
    window.print(); //mando a imprimir
  }
}
import { mapFields } from "vuex-map-fields";
export default {
  name: "recibed",
  directives: {
    print: {
      bind(el, binding) {
        printSection(el, binding);
      },
    },
  },
  props: ['modal'],
  computed: {
    ...mapFields({
      diagnostico: "consultation.medicalConsultation.medical_diagnostic",
      tratamiento: "consultation.medicalConsultation.medication",
    }),
    nombre() {
      return `${this.$store.state.consultation.client.first_name} ${
        this.$store.state.consultation.client.last_name
      } ${this.$store.state.consultation.client.maiden_name || ""}`;
    },
    modalStatus: {
      get() {
        return this.modal;
      },
      set(value) {
        this.$emit("update:modal", value);
      }
    }
  },
  data: () => ({
    fecha: new Date().toLocaleString()
  }),
};
</script>