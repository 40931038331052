<template>
  <v-card outlined>
    <v-row class="text-center aling-center">
      <v-col cols="12" :md="detail?6:12">
        <v-avatar class="ma-3" size="125" tile>
          <v-img :src="client_photo"></v-img>
        </v-avatar>
        <div style="width:100%">
          <slot name="info">
            <v-card-title class="headline">
              <span class="text-center" style="width:100%;">{{client.client}}</span>
            </v-card-title>
            <v-card-subtitle>
              <span>Correo: {{ client.client_email }}</span
              ><br />
              <span>Tel. Móvil: {{ client.client_phone }}</span>
            </v-card-subtitle>
          </slot>
          <v-card-actions>
            <v-btn
              text
              :loading="loading"
              color="primary"
              @click="loadMoreInformation"
              small
            >
              {{ historyBtnText }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="6" cols="12" v-if="detail && Object.entries(client).length !== 0">
        <shopping-history
          :client="client"
          :products="products"
          :services="services"
        ></shopping-history>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Historial de cliente</v-toolbar>
          <v-card-text>
            <div class="text-h5 my-3">Servicios (historial)</div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Producto
                    </th>
                    <th class="text-left">
                      Fecha
                    </th>
                    <th class="text-left">
                      Cantidad
                    </th>
                    <th class="text-left">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in $store.getters.sessionClient"
                    :key="item.name"
                  >
                    <td>{{ item.product }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.amount }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="text-h5 my-3">Compras - Productos (historial)</div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Producto
                    </th>
                    <th class="text-left">
                      Fecha
                    </th>
                    <th class="text-left">
                      Cantidad
                    </th>
                    <th class="text-left">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in $store.getters.productClient"
                    :key="item.name"
                  >
                    <td>{{ item.product }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.amount }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import EventBus from "@/EventBus";
import ShoppingHistory from "./ShoppingHistory";

export default {
  name: "ClientCard",

  components: { ShoppingHistory },

  props: {
    detail: {
      type: Boolean,
      default: () => false,
    },
    client: {
      type: Object,
      default: () => ({
        client_photo: "https://cdn.vuetifyjs.com/images/cards/halcyon.png",
        first_name: "Shanna",
        last_name: "Zboncak",
        maiden_name: "Gottlieb",
        email: "ankunding.beverly@example.org",
        mobil_phone: "9906080648",
      }),
    },
    historyBtnText: {
      type: String,
      default: () => "Más Información",
    },
  },

  data: () => ({
    loading: false,
    dialog: false,
    products: [],
    services: [],
  }),

  computed: {
    ...mapFields({
      sessions: "medication.sessions",
    }),
    client_photo(){
      if( !Object.entries(this.client).length ) return ''
      let foto = this.client.client_photo || this.client.photo
      let url = foto || `https://ui-avatars.com/api/?name=${this.client.first_name}+${this.client.last_name}`
      return url
    }
  },

  mounted() {
    EventBus.$on("History::loaded", (data) => {
      this.products = data.salesProducts;
      this.services = data.salesServices;
      this.sessions = data.sessions;
    });
  },

  methods: {
    loadMoreInformation() {
      // this.loading = true;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
