<template>
  <v-container class="Products">
    <h1 class="text-center mb-1">Tratamientos</h1>
    <v-btn class="ma-2" @click="backCustomer()" color="primary" outlined v-if="detail">
      <v-icon
        left
        dark
      >
        mdi-arrow-left
      </v-icon>
      Regresar
    </v-btn>
    <client-card v-if="client && client.id" :client="client" :detail="detail" class="mb-4"></client-card>
    <template v-if="!detail">
      <h3 class="mt-2">Tabla de citas programadas</h3>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Nombre del cliente
              </th>
              <th class="text-left">
                Hora
              </th>
              <th class="text-left">
                Tipo de cliente
              </th>
              <th class="text-left">
                Estatus de la cita
              </th>
              <th class="text-left">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in appointments" :key="item.id">
              <td>{{ item.client }}</td>
              <td>{{ item.start }} hrs.</td>
              <td>
                <v-chip :color="item.client_type == 'Verde' ? 'green' : item.client_type == 'Dorado' ? '#efb810' : '#e3e4e5'">{{ item.client_type }}</v-chip>
                </td>
              <td>
                <v-chip :color="item.status == 'PENDIENTE' ? 'primary' : 'default'" :outlined="item.status != 'PENDIENTE'">
                  {{ item.status }}
                </v-chip>
              </td>
              <td>
                  <v-btn small depressed @click="previewCustomer(item)" title="Visualizar la información en la parte de arriba" class="mr-1">
                    Ver Información
                  </v-btn>
                  <v-btn small depressed color="error"  v-if="item.status == 'PENDIENTE'" title="Cancelar" @click="cancelAppointment(item)" class="mr-1">
                    Cancelar
                  </v-btn>
                  <v-btn small depressed color="primary" v-if="item.status == 'PENDIENTE' && $store.getters.hasAnyPermission(permissionToCosmetologa)" title="Atender" @click="selectCustomer(item)" class="mr-1">
                    Atención estética
                  </v-btn>
                  <v-btn small depressed color="primary" v-if="item.status == 'PENDIENTE' && $store.getters.hasAnyPermission(permissionToDoctor)" title="Atender" @click="$router.push(`/citas/${item.id}/consulta/clientes/${item.client_id}`)" class="mr-1">
                    Atención clínica
                  </v-btn>
                  <v-btn small depressed color="primary" v-if="item.status == 'FINALIZADA' && $store.getters.hasAnyPermission(permissionToDoctor)" title="Revisar" @click="$router.push(`/citas/${item.id}/consulta/clientes/${item.client_id}`)" class="mr-1">
                    Editar consulta
                  </v-btn>
              </td>
            </tr>
            <tr v-if="appointments.length <= 0">
                <td colspan="5" class="text-center">No hay datos</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    <v-row v-if="detail">
      <v-col cols="12" md="9">
        <services-table :dateAppointment="client.start" :clientId="client.id" :details="client.details"></services-table>
      </v-col>
      <v-col cols="12" md="3">
        <v-card outlined>
          <v-card-title class="justify-center body-2 blue-grey lighten-5 px-1 py-3">
            <strong class="grey--text text--darken-2">RESUMEN DE CITA</strong>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>SERVICIOS NUEVOS</td>
                  <td class="text-end">
                    {{ $store.getters.newservices }}
                  </td>
                </tr>
                <tr>
                  <td>SERVICIOS ANTERIORES USADOS</td>
                  <td class="text-end">{{ $store.getters.oldservices }}</td>
                </tr>
                <tr class="">
                  <td>TOTAL</td>
                  <td class="text-end font-weight-bold">
                    ${{ $store.getters.total }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-btn color="primary" block class="mt-2" @click="saveSale" :loading="saving">Terminar Cita</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
      >
      <template v-slot:default="dialog">
          <v-card>
          <v-toolbar
              color="primary"
              dark
          >Venta registrada</v-toolbar>
          <v-card-text>
              <div class="text-h2 text-center pa-12">Folio de la venta: {{ folio }}</div>
          </v-card-text>
          <v-card-actions class="justify-end">
              <v-btn
              text
              @click="closeDialog(dialog)"
              >Cerrar</v-btn>
          </v-card-actions>
          </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import ClientCard from "@/components/Client/ClientCard";
import ServicesTable from "@/components/Medication/ServicesTable"
import moment from 'moment';
import EventBus from "@/EventBus";

export default {
  name: "Medication",

  components: { ClientCard, ServicesTable },

  data: () => ({
    dialog: false,
    detail: false,
    folio: '',
    appointments: [],
    client: {},
    permissionToDoctor: ['personal_histories.create'],
    permissionToCosmetologa: ['tratamiento.estetico'],
    saving: false
  }),

  mounted() {
    this.loadInitialData();
    EventBus.$on("History::loaded", (data) => {
      this.products = data.salesProducts;
      this.services = data.salesServices;
      this.sessions = data.sessions;
    });
  },

  methods: {
    loadInitialData() {
      let from = moment().format('Y-MM-DD');
      axios.post(`/api/dates`, {from: from, to: from}).then(response => {
        this.appointments = response.data.data;
      }).catch(error => {
        console.log(error)
      })
    },
    selectCustomer(customer) {
      this.client = customer;
      this.detail = true;
      this.$store.commit("SET_CART", customer.details.map(element => {
        this.$set(element,'uses',1)
        return element
      }));
      this.$store.commit("SET_APPOIMENT_ID", customer.id);
      this.$store.dispatch("loadDataSessions", customer.client_id);
    },
    cancelAppointment(appointment) {
      let date = appointment.start.split(' ');

      axios.put(`/api/appointments/${appointment.id}`, { date: date[0], hour: date[1], status: 'CANCELADA' }).then(response => {
        this.$snotify.success("Cita cancelada correctamente");
        let index = this.appointments.findIndex(item => item.id == appointment.id);
        this.appointments[index].status = response.data.data.status;
      }).catch(error => {
        this.$snotify.error("Ocurrio un error");
        console.log(error)
      })
    },
    backCustomer() {
      this.client = {};
      this.detail = false;
      this.$store.commit("CLEAN_DATA")
    },
    previewCustomer(customer) {
      this.client = customer;
      this.$store.dispatch("loadDataSessions", customer.client_id);
    },
    saveSale() {
      this.saving=true
      this.$store.dispatch('createSaleMedication', this.client.client_id)
      .then(data => {
        this.$snotify.success("Cita atendida correctamente");
        this.dialog = true;
        this.detail = false;
        this.folio = data.data.folio;
        this.loadInitialData()
      })
      .catch((e) => {
        if (e.response.status == 422) {
          this.$snotify.error(JSON.stringify(Object.values(e.response.data.errors)).replaceAll(/[{}'"[\]]/g,''), "Error validando");
        } else
          this.$snotify.error(e.response.data.message,'Error');
      })
      .finally(()=>{
        this.saving=false
      });
    },
    closeDialog(dialog) {
      dialog.value = false;
      this.folio = '';
    }
  }
};
</script>

<style lang="scss" scoped></style>
