<template>
  <v-dialog v-model="$store.state.products.modal" persistent max-width="650px">
    <v-card>
      <v-toolbar elevation="1" color="primary darken-1" dark>{{
        (form.id ? "EDITAR " : "NUEVO ") + (form.type || "PRODUCTO")
      }}</v-toolbar>
      <v-card-text>
        <ProductForm></ProductForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductForm from "./ProductForm";

export default {
  name: "ProductModal",
  components: {
    ProductForm
  },
  computed: {
    form() {
      return this.$store.state.products.product;
    }
  }
};
</script>

<style lang="scss" scoped></style>
