<template>
  <v-data-iterator
    hide-default-footer
    :loading="$store.state.products.loading"
    :items="$store.getters.getProducts"
    :items-per-page.sync="itemsPerPage"
    :sort-by="sortBy.toLowerCase()"
    :sort-desc="sortDesc"
    :search="search"
    :page="page"
  >
    <template v-slot:header="props">
      <v-toolbar flat color="transparent" class="mb-1 px-0">
        <v-progress-linear
          v-if="props.items.length"
          :active="$store.state.products.loading"
          :indeterminate="$store.state.products.loading"
          absolute
          bottom
          color="primary"
        ></v-progress-linear>
        <v-text-field
          v-model="search"
          label="Buscar"
          hide-details
          clearable
          flat
          prepend-inner-icon="mdi-magnify"
          @keydown="onSearchChange()"
          :disabled="$store.state.products.loading"
        ></v-text-field>
        <v-spacer></v-spacer>
        <span class="grey--text">Productos por Página</span>
        <v-menu offset-y :disabled="$store.state.products.loading">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2"
              color="accent darken-3"
              v-bind="attrs"
              v-on="on"
              icon
              :disabled="$store.state.products.loading"
            >
              {{ itemsPerPage }} <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(number, index) in itemsPerPageArray"
              :key="index"
              @click="updateItemsPerPage(number)"
            >
              <v-list-item-title>{{ number }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y :disabled="$store.state.products.loading">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  icon
                  class="mx-1"
                  color="accent darken-3"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  :disabled="$store.state.products.loading"
                >
                  <v-icon>mdi-format-list-bulleted-type</v-icon>
                </v-btn>
              </template>
              <span>Ordenar Por</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item-group v-model="selectedSort" color="accent darken-3">
              <v-list-item
                v-for="(item, index) in sorts"
                :key="index"
                @click="sortBy = item.value"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="mx-1"
              color="accent darken-3"
              v-bind="attrs"
              v-on="on"
              @click="sortDesc = !sortDesc"
              :disabled="$store.state.products.loading"
            >
              <v-icon>{{
                sortDesc ? "mdi-sort-descending" : "mdi-sort-ascending"
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{ sortDesc ? "Descendente" : "Ascendente" }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="mx-1"
              color="accent darken-3"
              v-bind="attrs"
              v-on="on"
              @click="$store.dispatch('LoadProducts')"
              :disabled="$store.state.products.loading"
            >
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Recargar</span>
        </v-tooltip>
      </v-toolbar>
    </template>
    <template v-slot:default="props">
      <v-row>
        <v-col
          v-for="item in props.items"
          :key="item.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <ProductItem :product="item"></ProductItem>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer>
      <v-row class="mt-2 px-5 flex-column" align="center" justify="center">
        <v-pagination
          v-model="page"
          :length="numberOfPages"
          :total-visible="5"
          :disabled="$store.state.products.loading"
        ></v-pagination>
      </v-row>
    </template>
    <template v-slot:loading>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          Cargando Productos
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </template>
    <template v-slot:no-results>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-alert
          outlined
          color="secondary"
          border="left"
          icon="mdi-alert-decagram-outline"
        >
          Ningún elemento coincide con la búsqueda
        </v-alert>
      </v-row>
    </template>
    <template v-slot:no-data>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-alert
          outlined
          color="secondary"
          border="left"
          icon="mdi-alert-circle"
        >
          No hay datos disponibles
        </v-alert>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import ProductItem from "./ProductItem";

export default {
  name: "ListOfProducts",
  components: { ProductItem },
  data: () => ({
    page: 1,
    itemsPerPage: 8,
    itemsPerPageArray: [4, 8, 12, 24],
    sortDesc: false,
    sortBy: "name",
    search: "",
    filter: {},
    selectedSort: 0,
    sorts: [
      { name: "Nombre", value: "name" },
      { name: "Precio", value: "price" },
      { name: "Tipo", value: "tipo" },
      { name: "Categoria", value: "categoria" }
    ]
  }),
  computed: {
    numberOfPages() {
      return Math.ceil(
        this.$store.getters.getProducts.length / this.itemsPerPage
      );
    }
  },
  mounted() {
    this.$store.dispatch("LoadProducts");
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    onSearchChange() {
      this.page = 1;
    }
  }
};
</script>

<style lang="scss" scoped></style>
