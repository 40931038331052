<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" @click="newItem()">Nuevo</v-btn>
    </v-card-title>
    <v-data-table :headers="headers" :search="search" :items="clients">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="showItem(item)">
          <v-icon color="primary darken-2">mdi-eye</v-icon>
        </v-btn>

        <v-btn icon @click="editItem(item)">
          <v-icon color="accent darken-2">mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon @click="showHistory(item)">
          <v-icon color="primary darken-2">mdi-medical-bag</v-icon>
        </v-btn>

        <v-btn icon @click="showBuyHistory(item)">
          <v-icon color="primary darken-2">mdi-currency-usd</v-icon>
        </v-btn>
        <!-- Ya hay problemas por eliminar empleados y realmente no tiene sentido eliminar estos registros-->
        <!-- <v-btn icon @click="deleteItem(item)">
          <v-icon color="red lighten-1">mdi-delete-outline</v-icon>
        </v-btn> -->
      </template>
      <template v-slot:no-data>
        <v-btn color="primary"> No hay datos </v-btn>
      </template></v-data-table
    >
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapMutations } from "vuex";
export default {
  name: "ProductTable",
  props:['modalHistoryClinic','modalHistoryPayment'],
  data() {
    return {
      search: "",
      headers: [
        { text: "Nombre", value: "first_name" },
        { text: "Primer Apellido", value: "last_name" },
        { text: "Segundo Apellido", value: "maiden_name" },
        { text: "Genéro", value: "sex" },
        { text: "Móvil", value: "mobil_phone" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapFields({
      image: "clients.image",
      modal: "clients.modal",
      clients: "clients.allClients",
      client: "clients.client",
      showClient: "clients.showClient",
    }),
  },
  methods: {
    ...mapMutations({
      setClient: "SET_CLIENT",
    }),
    newItem() {
      this.image = null;
      this.modal = true;
      this.client = Object.assign({}, {
        blood_type:'NO DISPONIBLE',
        allergies:'SE DESCONOCE',
        state:'OAXACA',
        email:'',
        birthday:''});
    },
    showItem(item) {
      this.showClient = true;
      this.client = Object.assign({}, item);
      this.modal = true;
    },
    editItem(item) {
      this.client = Object.assign({}, item);
      if(!this.client.email)
        this.client.email=''
      this.modal = true;
    },
    showHistory(item) {
      this.$store.commit('SET_CONSULTATION_CLIENT',item)
      this.$emit('update:modalHistoryClinic',true)
    },
    showBuyHistory(item) {
      this.$store.dispatch("loadDataSessions", item.id);
      this.$emit('update:modalHistoryPayment',true)
    }
    /* deleteItem(item) {
      this.$snotify.confirm("Se eliminara el cliente", "Confirmar", {
        timeout: 0,
        showProgressBar: false,
        closeOnClick: false,
        position: "rightBottom",
        buttons: [
          {
            text: "Si",
            action: (toast) => {
              this.$store
                .dispatch("deleteClientAsync", item.id)
                .then(() => {
                  this.$snotify.success("Usuario dado de baja");
                })
                .catch((e) => {
                  this.$snotify.error("Error eliminando el cliente" + e);
                });
              this.$snotify.remove(toast.id);
            },
            bold: false,
          },
          {
            text: "Cancelar",
            action: (toast) => {
              this.$snotify.remove(toast.id);
            },
            bold: true,
          },
        ],
      });
    }, */
  },
  mounted() {
    this.$store.dispatch("getClientsAsync");
  },
};
</script>
