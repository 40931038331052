var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"NewSale pa-1",attrs:{"fluid":""}},[(_vm.sale)?_c('v-row',[_c('v-col',{staticClass:"pr-1 py-1",attrs:{"cols":"9"}},[_c('v-card',{attrs:{"outlined":"","height":"100%"}},[_c('v-container',{staticClass:"py-0"},[(
              _vm.sale.status.name != 'PAGADO' && _vm.sale.status.name != 'CANCELADA'
            )?_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"9"}},[_c('ProductSelectInput'),(_vm.sale.status.name == 'PREVENTA')?_c('ServiceSelectInput'):_vm._e()],1),_c('v-col',{staticClass:"d-flex align-center pb-1",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.product,"block":""},on:{"click":function($event){return _vm.AddSaleProduct()}}},[_vm._v(" Agregar ")])],1)],1):_vm._e(),(
              _vm.sale.status.name != 'PAGADO' && _vm.sale.status.name != 'CANCELADA'
            )?_c('v-divider'):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('SaleProducts')],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pl-1 py-1",attrs:{"cols":"3"}},[_c('SaleFolio',{staticClass:"mb-2"}),_c('SaleCustomer',{staticClass:"mb-2"}),_c('SaleSummary'),(_vm.sale.status.name != 'PAGADO' && _vm.sale.status.name != 'CANCELADA')?_c('v-btn',{staticClass:"mt-2 py-6",attrs:{"block":"","color":"accent darken-1","disabled":!_vm.$store.getters.getSaleDetails.length},on:{"click":function($event){return _vm.presale()}}},[_vm._v(" "+_vm._s(_vm.sale && _vm.sale.status.name == "PREVENTA" ? "PAGAR" : " Terminar Venta")+" ")]):_vm._e(),(_vm.sale.status.name != 'NUEVO')?_c('div',[(
            _vm.$store.getters.getSaleDetails.length > 4 &&
              _vm.sale.status.name != 'CANCELADA'
          )?_c('v-btn',{staticClass:"mt-2 py-6",attrs:{"block":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar Venta ")]):_vm._e(),(_vm.$store.getters.getSaleDetails.length > 4)?_c('v-btn',{staticClass:"mt-2 py-6",attrs:{"block":"","outlined":"","color":"accent darken-1"},on:{"click":function($event){return _vm.returnToCashRegister()}}},[_vm._v(" Volver a Caja ")]):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(_vm.sale && _vm.sale.status.name != 'NUEVO')?_c('v-row',[_c('v-col',{staticClass:"d-flex pb-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"accent darken-1"},on:{"click":function($event){return _vm.returnToCashRegister()}}},[_vm._v(" Volver a Caja ")]),(_vm.sale.status.name != 'CANCELADA')?_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar Venta ")]):_vm._e(),_c('v-spacer'),(
          _vm.$store.getters.getSaleDetails.length > 4 &&
            _vm.sale.status.name != 'PAGADO' &&
            _vm.sale.status.name != 'CANCELADA'
        )?_c('v-btn',{staticClass:"px-3",attrs:{"color":"accent darken-1"}},[_vm._v(" "+_vm._s(_vm.sale && _vm.sale.status.name == "PREVENTA" ? "PAGAR" : " Terminar Venta")+" ")]):_vm._e()],1)],1):_vm._e(),_c('ClientSelectModal'),_c('SaleClientModal'),_c('v-overlay',{attrs:{"value":_vm.loading,"z-index":999}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('ConfirmCancelModal',{attrs:{"redirect":"/paysales"}}),_c('PaymentModal'),_c('TicketModal',{attrs:{"redirect":"/paysales"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }