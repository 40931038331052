<template>
  <v-card outlined>
    <v-row>
      <v-col cols="6">
        <v-card class="elevation-0">
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3" size="125" tile color="blue-grey lighten-5">
              <v-icon x-large>person</v-icon>
            </v-avatar>
            <div>
              <v-card-title class="headline pl-0">{{
                sale ? sale.client_name : "... ... ..."
              }}</v-card-title>
              <v-card-subtitle class="pl-0">
                <span
                  ><v-icon small class="mr-1">email</v-icon>
                  {{ sale ? sale.client.email : "..." }}
                </span>
                <br />
                <span
                  ><v-icon small class="mr-1">phone</v-icon>
                  {{ sale ? sale.client.phone : "..." }}
                </span>
              </v-card-subtitle>
              <v-card-actions class="pl-0">
                <v-btn
                  text
                  color="accent darken-2"
                  small
                  :disabled="sale == null"
                  @click="show_sale()"
                >
                  Detalle Venta
                </v-btn>
                <v-btn
                  :disabled="sale == null"
                  color="amber darken-3"
                  text
                  @click="edit()"
                  >Editar</v-btn
                >
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="elevation-0">
          <div class="d-flex flex-no-wrap">
            <div>
              <v-card-title class="headline pl-0">{{
                sale ? sale.folio : "... ... ..."
              }}</v-card-title>
              <v-card-subtitle class="pl-0">
                <span class="font-weight-bold">
                  <v-icon small class="mr-1">mdi-currency-usd</v-icon>
                  {{ sale ? sale.amount : "..." }}
                </span>
              </v-card-subtitle>
              <v-card-actions class="pl-0">
                <v-btn
                  color="accent darken-1"
                  class="px-8"
                  :disabled="sale == null"
                  @click="paid()"
                >
                  Pagar
                </v-btn>
                <v-btn
                  color="error darken-1"
                  text
                  class="px-2"
                  :disabled="sale == null"
                  @click="cancel()"
                >
                  Cancelar
                </v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "SaleInfo",
  computed: {
    sale() {
      return this.$store.state.sales.paysale;
    }
  },
  methods: {
    paid() {
      this.$store.commit("openPaymentModal");
    },
    show_sale() {
      this.$store.dispatch("FetchSale", this.sale.id).then(() => {
        this.$store.commit("openSaleInfoModal");
      });
    },
    cancel() {
      this.$store.dispatch("FetchSale", this.sale.id).then(() => {
        this.$store.commit("openCancelSaleModal");
      });
    },
    edit() {
      this.$router.push({
        path: `/ventas/${this.sale.id}/create`
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
