<template>
  <v-row class="fill-height">
    <v-col>
      <header-component
        @prevmonth="$refs.calendar.prev()"
        @nextmonth="$refs.calendar.next()"
        :titulo.sync="$refs.calendar"
      ></header-component>

      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          locale="es"
          :interval-format="intervalFormat"
          :first-interval="7"
          :interval-count="12"
          :events="appointments"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:date="viewDay"
          @change="updateAppointments"
        >
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
        <card-data></card-data>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import HeaderComponent from "./Header.vue";
import CardData from "./CardData.vue";
export default {
  components: { CardData, HeaderComponent },
  data: () => ({
    ready:false
  }),
  computed: {
    ...mapFields({
      cardaAppointmentState: "appointments.cardaAppointmentState",
      nodeSelectedAppointment: "appointments.calendar.nodeSelectedAppointment",
      appointmentSelectedData: "appointments.calendar.appointmentSelectedData",
      appointments: "appointments.calendar.appointments",
      type: "appointments.calendar.type",
      focus: "appointments.calendar.modelCalendar",
    }),
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
  },
  methods: {
    intervalFormat(interval) {
      return interval.time;
    },
    getEventColor(event) {
      return event.color;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.appointmentSelectedData = event;
        this.nodeSelectedAppointment = nativeEvent.target;
        setTimeout(() => {
          this.cardaAppointmentState = true;
        }, 10);
      };
      if (this.cardaAppointmentState) {
        this.cardaAppointmentState = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    updateAppointments({ start, end }) {
      this.$store.commit("SET_DATES", { start: start.date, end: end.date });
      this.$store.dispatch("getAppointmentsByDates", null);
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
  },

  mounted() {
    this.ready = true
    this.scrollToTime()
    this.updateTime()
    this.$refs.calendar.checkChange();
  },
};
</script>
<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>