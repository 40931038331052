<template>
  <v-container class="Employees text-center">
    <h1>Empleado</h1>
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <v-card>
          <v-tabs
            background-color="primary accent-4"
            center-active
            dark
            v-model="tab"
          >
            <v-tab href="#tab-1">
              Información Personal
            </v-tab>
            <v-tab href="#tab-2">
              Usuario del Sistema
            </v-tab>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <employee-form></employee-form>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <user-form></user-form>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmployeeForm from "../components/Employee/EmployeeForm";
import UserForm from "../components/Employee/UserForm";
import EventBus from '../EventBus';

export default {
  name: "empleado",

  props: ["id"],

  components: { EmployeeForm, UserForm },

  data: () => ({
    tab: null,
  }),

  mounted() {
    if (!isNaN(this.id)) {
      this.$store.dispatch("findEmployee", this.id);
    }

    EventBus.$on("EMPLOYEES::errorMessage", error => {
        if(error.response.status == 404) {
            this.$snotify.error("No se encontró al Empleado");
        }
    });
  },
};
</script>

<style lang="scss" scoped></style>
