<template>
  <div class="ProductImageInput">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          outlined
          :class="[urlPreview || image ? '' : 'no-preview']"
          @click="image || urlPreview ? '' : openFileExplorer()"
        >
          <v-card-text v-if="urlPreview == null && image == null">
            <v-icon large>mdi-file-image</v-icon>
            <div class="mt-2">Agregar Imagen</div>
          </v-card-text>
          <v-card-text v-else-if="urlPreview == null && image != null">
            <v-img
              :src="`${image}`"
              max-width="230"
              max-height="250"
              contain
              class="transparent"
              alt="NOT FOUND"
            ></v-img>
          </v-card-text>
          <v-card-text v-else>
            <v-img
              :src="urlPreview"
              max-width="230"
              max-height="250"
              contain
              class="transparent"
              alt="NOT FOUND"
            ></v-img>
          </v-card-text>
          <v-fade-transition>
            <v-overlay
              v-if="hover && (image || urlPreview)"
              absolute
              color="grey darken-2"
              :opacity="0.6"
            >
              <v-card-text>
                <div>
                  <v-btn
                    class="ma-1"
                    small
                    fab
                    color="warning"
                    @click.stop="openFileExplorer()"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-1"
                    small
                    fab
                    color="red"
                    @click.stop="deleteImg()"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
    <input ref="imgProductInput" type="file" hidden @change="onFileChange" />
  </div>
</template>

<script>
import EventBus from "@/EventBus";

export default {
  name: "ProductImageInput",
  props: {
    image: {
      default: null
    },
    setImageCallbackBus: {
      type: Object,
      default: () => ({
        name: null,
        params: null
      })
    }
  },
  data: () => ({
    urlPreview: null,
    server: process.env.VUE_APP_API_SERVER
  }),
  mounted() {
    EventBus.$on("ProductImageInput:ClearPreview", () => {
      this.clearPreview();
    });
  },
  methods: {
    openFileExplorer() {
      this.$refs.imgProductInput.click();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        this.$emit("update:image", files[0]);
        this.urlPreview = URL.createObjectURL(files[0]);
        if (this.setImageCallbackBus.name) {
          EventBus.$emit(
            this.setImageCallbackBus.name,
            this.setImageCallbackBus.params
          );
        }
      }
    },
    deleteImg() {
      this.$emit("update:image", null);
      this.clearPreview();
    },
    clearPreview() {
      this.urlPreview = null;
    }
  }
};
</script>
<style lang="scss">
.ProductImageInput {
  height: 100%;
  .v-card,
  .v-card__text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .no-preview {
    transition: all 0.3s;
    .v-card__text,
    i {
      color: #bdbdbd !important;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    &:hover {
      cursor: pointer;
      border-color: #757575;
      .v-card__text,
      i {
        color: #757575 !important;
      }
    }
  }
}
</style>
