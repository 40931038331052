<template>
  <div class="BaseLayout fill-height">
    <v-container class="fill-height ma-0 pa-0" fluid>
      <slot></slot>
    </v-container>
    <v-footer absolute color="transparent" dark>
      <v-spacer></v-spacer>
      <p class="overline" style="opacity: 0.5;">
        <v-icon x-small>mdi-source-branch</v-icon> {{ version }}
      </p>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "BaseLayout",
  data: () => ({
    version: process.env.VUE_APP_VERSION,
  }),
};
</script>

<style lang="scss">
.BaseLayout {
  // background-image: url('../assets/background.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
