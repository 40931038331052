<template>
  <v-form ref="formUser" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-avatar class="profile" color="grey" size="164">
            <v-img
              src="https://ui-avatars.com/api/?name=Luis Vasquez&color=7F9CF5&background=EBF4FF"
            ></v-img>
          </v-avatar>
          <br />
          <v-chip class="ma-2"> # {{ employee.id }} </v-chip>
          <v-text-field
            v-model="employee.name"
            label="Nombre(s)"
            disabled
          ></v-text-field>
          <v-text-field
            v-model="employee.lastName"
            label="Primer Apellido"
            disabled
          ></v-text-field>
          <v-text-field
            v-model="employee.maidenName"
            label="Segundo Apellido"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="8">
          <h3 class="text-left">Acceso</h3>
          <v-text-field
            v-model="user.user"
            :counter="191"
            :rules="[(v) => !!v || 'El Usuario es requerido']"
            label="Usuario"
            required
          ></v-text-field>
          <v-text-field
            v-model="user.password"
            :counter="191"
            :rules="[(v) => !!v || 'La Contraseña es requerida']"
            label="Contraseña"
            required
            type="password"
          ></v-text-field>
          <v-text-field
            v-model="user.passwordConfirm"
            :counter="191"
            :rules="[(v) => !!v || 'Confirma la contraseña']"
            label="Confirmar Contraseña"
            required
            type="password"
          ></v-text-field>
          <v-divider class="mt-3"></v-divider>
          <h3 class="text-left my-3">Permisos</h3>
          <v-combobox
            v-model="user.roles"
            :items="roles"
            label="Rol en el Sistema"
            item-text="name"
            item-value="id"
            multiple
            :rules="[(v) => v.length > 0 ? true : 'El Rol es requerido']"
          ></v-combobox>
          <v-btn
            :disabled="!valid"
            color="primary"
            class="mr-4"
            @click="saveUser"
            :loading="saving"
          >
            Guardar Usuario
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import EventBus from "../../EventBus";

export default {
  data: () => ({
    roles: [],
    valid: false,
    saving: false
  }),

  computed: {
    employee() {
      return this.$store.state.employees.employee;
    },
    user() {
      return this.$store.state.employees.user;
    },
  },

  mounted() {
    this.loadRoles();
    if (this.employee.id != "" && this.employee.id > 0) {
      this.loadUser();
    }

    EventBus.$on("EMPLOYEES::userStore", () =>
      this.$snotify.success("Usuario guardado correctamente")
    );
    EventBus.$on("EMPLOYEES::errorUserMessage", (error) => {
      if (error.response.status == 500) {
        this.$snotify.error("Ocurrio un error al buscar el usuario");
      }
    });
  },

  methods: {
    loadUser() {
      this.$store.dispatch("findUser");
    },
    loadRoles() {
      axios
        .get("/api/roles")
        .then((response) => {
          this.roles = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveUser() {
      this.$refs.formUser.validate();
      if (this.valid) {
        this.saving=true
        if (this.user.id != "" && this.user.id > 0) {
          this.$store.dispatch("updateUser")
          .then(({data})=>{
            this.$store.commit('SET_ROLE', data.data)
            this.$snotify.success("Actualizado correctamente");
          })
          .catch((e) => {
            if (e.response.status == 422) {
              this.$snotify.error(JSON.stringify(Object.values(e.response.data.errors)).replaceAll(/[{}'"[\]]/g,''), "Error validando");
            } else
              this.$snotify.error(e.response.data.message,'Error');
          }).finally(()=>{this.saving=false});
        } else {
          this.$store.dispatch("storeUser")
          .then(({data})=>{
            this.$store.commit('SET_ROLE', data.data)
            this.$snotify.success("Guardado correctamente");
          })
          .catch((e) => {
            if (e.response.status == 422) {
              this.$snotify.error(JSON.stringify(Object.values(e.response.data.errors)).replaceAll(/[{}'"[\]]/g,''), "Error validando");
            } else
              this.$snotify.error(e.response.data.message,'Error');
          }).finally(()=>{this.saving=false});
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
