<template>
  <v-dialog v-model="modal" persistent max-width="650px">
    <v-card>
      <v-toolbar elevation="1" color="primary darken-1" dark>
        NUEVO CLIENTE
      </v-toolbar>
      <v-card-text class="pb-2">
        <SaleClientForm></SaleClientForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SaleClientForm from "./SaleClientForm";

export default {
  name: "SaleClientModal",
  components: {
    SaleClientForm
  },
  computed: {
    modal() {
      return this.$store.state.sales.saleClientModal;
    }
  }
};
</script>

<style lang="scss" scoped></style>
