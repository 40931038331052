<template>
  <v-app>
    <component :is="layout">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </component>
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
const default_layout = "page";
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  }
};
</script>

<style lang="scss">
@import "~vue-snotify/styles/material";
</style>