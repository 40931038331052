<template>
  <v-layout
    align-center="align-center"
    justify-center="justify-center"
    class="login"
  >
    <v-flex class="login-form text-xs-center">
      <div class="display-1 mb-3 text-center">
        <v-icon class="mr-2" large="large">work</v-icon> DERMAQUEEN
      </div>
      <v-card light="light">
        <v-card-text>
          <div class="subheading text-center">
            <template v-if="options.isLoggingIn"
              >Inicia sesión en dermaqueen</template
            >
            <template v-else>Crate a new account</template>
          </div>
          <v-form>
            <v-text-field
              v-if="!options.isLoggingIn"
              v-model="user.name"
              light="light"
              prepend-icon="person"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="form.email"
              light="light"
              prepend-icon="email"
              label="Correo"
              type="email"
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              light="light"
              prepend-icon="lock"
              label="Contraseña"
              type="password"
            ></v-text-field>
            <v-checkbox
              v-if="options.isLoggingIn"
              v-model="options.shouldStayLoggedIn"
              light="light"
              label="Mantenerme conectado ?"
              hide-details="hide-details"
            ></v-checkbox>
            <v-btn
              v-if="options.isLoggingIn"
              @click.prevent="login"
              color="primary"
              block="block"
              type="submit"
              class="mt-3"
              :loading="loading"
              >Inicia sesión</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Login",

  data: () => ({
    form: {
      email: "",
      password: "",
    },
    options: {
      isLoggingIn: true,
      shouldStayLoggedIn: true,
    },
    loading: false,
  }),
  computed: {
    ...mapFields(["user"]),
  },
  methods: {
    login() {
      this.loading=true;
      axios
        .get("/sanctum/csrf-cookie")
        .then(() => {
          return axios
            .post("/login", this.form)
            .then(() => {
              return axios
                .get("/api/user")
                .then(({ data }) => {
                  this.user = data;
                  this.$router.replace("/home");
                })
                .catch(() => {
                  this.$router.replace("/unauthorized");
                })
            })
            .catch(({ response }) => {
              let msg = "";
              switch (response.status) {
                case 422:
                  msg = "Credenciales invalidas";
                  break;
                case 419:
                  msg = "Por favor, recarga la pagina";
                  break;
                case 429:
                  msg = "Demaciados intentos fallidos, intenta mas tarde";
                  break;
                default:
                  msg = "lo sentimos ocurrio un error desconocido";
              }
              this.$snotify.error(msg, "Error", {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
              });
            })
        })
        .catch(() => {
          this.$snotify.error(
            "Revisa tu conexión o intenta mar tarde",
            "Error de red",
            {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
            }
          );
        })
        .finally(()=>{
          this.loading=false;
        });
    },
  },
  created(){
    if( this.user.id ){
      this.$router.replace("/home");
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  background: url("https://kutanea.com.mx/images/clinica-division.jpg?crc=4021312952");
}
.login-form {
  max-width: 500px;
}
</style>
