<template>
  <v-card outlined>
    <v-card-title
      :class="[
        'body-2',
        'blue-grey lighten-5',
        'px-3',
        status != 'PAGADO' ? 'py-1 justify-space-between' : 'py-3 justify-center'
      ]"
    >
      <strong class="grey--text text--darken-2">CLIENTE</strong>
      <v-btn
        v-if="status != 'PAGADO' && status != 'CANCELADA'"
        icon
        color="blue-grey lighten-2"
        @click="$store.commit('openSelectClientModal')"
      >
        <v-icon>mdi-account-search</v-icon>
      </v-btn>
    </v-card-title>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="secondary" dark>
            mdi-account
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="font-weight-bold text-uppercase subtitle-1 secondary--text text--darken-3"
          >
            {{ client.first_name }} {{ client.last_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon x-small class="mr-1">phone</v-icon
            >{{ FormatPhoneNumber(client.mobil_phone) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "SaleCustomer",
  computed: {
    client() {
      return this.$store.state.sales.sale
        ? this.$store.state.sales.sale.client
        : {};
    },
    status() {
      return this.$store.state.sales.sale
        ? this.$store.state.sales.sale.status.name
        : null;
    }
  },
  methods: {
    FormatPhoneNumber(number) {
      let cleaned = ("" + number).replace(/\D/g, "");

      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return match[1] + " " + match[2] + " " + match[3];
      }

      return "000 000 0000";
    }
  }
};
</script>

<style lang="scss" scoped></style>
