
export const EXCHANGE_MODAL_CARGA_INICIAL = 'EXCHANGE_MODAL_CARGA_INICIAL'
export const EXCHANGE_DRAWER_RIGHT = 'EXCHANGE_DRAWER_RIGHT'
export const EXCHANGE_FORM_CREATE_APPOINTMENT = 'EXCHANGE_FORM_CREATE_APPOINTMENT'

import calendarAppointments from "./calendarAppointments"
import dataForm from "./FormCreateData"


export default {
  state: {
    loadAppointmentsState: false,
    drawerRight:false,

    formCreateAppointmentState:false,
    cardaAppointmentState:false,
  },
  mutations: {
    [EXCHANGE_MODAL_CARGA_INICIAL](state) {
      state.loadAppointmentsState = !state.loadAppointmentsState
    },
    [EXCHANGE_DRAWER_RIGHT](state) {
      state.drawerRight = !state.drawerRight
    },
    [EXCHANGE_FORM_CREATE_APPOINTMENT](state) {
      let hoy = (new Date()).toLocaleDateString().split('/')
      hoy[0] = hoy[0].length==1 ? `0${hoy[0]}` : hoy[0]
      hoy[1] = hoy[1].length==1 ? `0${hoy[1]}` : hoy[1]
      state.form.appointment.date=state.calendar.modelCalendar || hoy.reverse().join('-')
      state.formCreateAppointmentState = !state.formCreateAppointmentState
    },
  },
  actions: {
  },
  getters: {
    getLoadAppointmentsState: (state) => state.loadAppointmentsState,
    getDrawerRight: (state) => state.drawerRight,
    getformCreateAppointmentState: (state) => state.formCreateAppointmentState,
  },

  modules: {
    calendar: calendarAppointments,
    form: dataForm,
  }
}
