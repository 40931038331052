<template>
  <v-autocomplete
    v-model="opciones_seleccionadas"
    :items="lisitado_elementos_con_cabezeras"
    :rules="[ reglas_opciones_seleccionadas ]"
    :label="etiqueta"
    item-text="name"
    item-value="id"
    :multiple="multiple_seleccion"
    :prepend-icon="this.icon_select"
    :hint="this.descripcion"
    persistent-hint
    @input="actualizarModelo()"
  >
    <template v-slot:selection="data">
      <v-chip
        :input-value="data.selected"
        close
        class="chip--select-multi"
        @click:close="remove(data.item)"
      >
        <v-avatar>
          <v-icon>{{data.item.avatar}}</v-icon>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <v-icon>{{data.item.avatar}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
    <template v-slot:append>
      <v-fade-transition leave-absolute>
        <v-progress-circular v-if="loading" size="24" color="info" indeterminate></v-progress-circular>

        <template v-else >
        <v-icon v-if="lisitado_elementos_con_cabezeras.length === 0">report_problem</v-icon>
        <img
          v-else
          width="24"
          height="24"
          src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
          alt
        />
        </template>
      </v-fade-transition>
    </template>
  </v-autocomplete>
</template>
<script>
import axios from "axios";
export default {
  props: [
    "modelo",
    "label",
    "resource",
    "multiple_selection",
    "content_url",
    "icon_select",
    "descripcion",
    "representacion_objeto",
    "lista_estatica"
  ],
  data() {
    return {
      loading: true,
      opciones_seleccionadas: this.modelo,

      etiqueta: this.label,
      multiple_seleccion: this.multiple_selection,

      lisitado_elementos_con_cabezeras: []
    };
  },
  methods: {
    remove(item) {
      if (Array.isArray(this.opciones_seleccionadas)) {
        const index = this.opciones_seleccionadas.indexOf(item.id);
        if (index >= 0) {
          this.opciones_seleccionadas.splice(index, 1);
        }
      } else {
        this.opciones_seleccionadas = [];
      }
    },
    reglas_opciones_seleccionadas(value) {
      if (value != null && value.length === 0) {
        this.disabled = true;
        return "Campo obligatorio";
      } else {
        return true;
      }
    },
    actualizarModelo: function() {
      this.$emit("update:modelo", this.opciones_seleccionadas);
      if( this.representacion_objeto ){
        this.$emit("update:representacion_objeto", this.lisitado_elementos_con_cabezeras.find(element => element.id == this.opciones_seleccionadas));
      }
    }
  },
  watch: {
    modelo: function(newVal ) {
      this.opciones_seleccionadas = newVal;
    }
  },
  created() {
    if (this.content_url != null && this.content_url.length > 5) {
      axios
        .get(this.content_url)
        .then(response => {
          this.loading = false;
          this.lisitado_elementos_con_cabezeras = this.resource? response.data.data :response.data;
        })
        .catch(()=> {
          this.loading = false;
        });
    }
    if(this.lista_estatica){
      this.lisitado_elementos_con_cabezeras = this.lista_estatica;
    }
  },
  mounted: function() {
    if(this.lista_estatica){
      this.loading = false;
    }
    if(this.content_url){
      setTimeout(() => {
        this.loading = false
      }, 7000)
    }

  }
};
</script>
