<template>
  <v-card outlined>
    <v-container class="py-0">
      <form-service></form-service>
    </v-container>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Servicio</th>
            <th class="text-left">Estatus</th>
            <th class="text-left">Cantidad</th>
            <th class="text-left">Usados</th>
            <th class="text-left">Precio</th>
            <th class="text-left">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in car" :key="item.id">
            <td> <!-- service -->
              <v-list-item two-line class="px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-bold text-uppercase text-caption"
                  >
                    {{ item.product_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-btn
                      text
                      color="blue-grey lighten-3"
                      small
                      @click="delete_service(index)"
                    >
                      Eliminar
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </td>
            <td>{{ item.status }}</td>
            <td> <!-- quantity -->
              <v-text-field
                v-model="item.quantity"
                single-line
                outlined
                hide-details
                dense
                type="number"
                min="1"
                class="item-quantity"
                :readonly="item.session_id"
                style="width: 80px"
              >
              </v-text-field>
            </td>
            <td> <!-- uses -->
              <v-text-field
                v-model="item.uses"
                outlined
                hide-details
                dense
                type="number"
                min="1"
                :max="item.quantity"
                class="item-quantity"
                :readonly="item.session_id"
                style="width: 80px"
                @keyup="checkUsesVal(index)"
              >
              </v-text-field>
            </td>
            <td>${{ item.product_price }}</td>
            <td>${{ item.quantity * item.product_price }}</td>
          </tr>
        </tbody>
        <tfoot>
          {{
            car.length
          }}
          servicios aplicados
        </tfoot>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
// import { mapFields } from "vuex-map-fields";
import moment from "moment";
import FormService from "@/components/Medication/FormService";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ServicesTable",

  components: { FormService },

  props: {
    dateAppointment: {
      type: String,
      default: () => "",
    },
    clientId: {
      type: Number,
      default: () => 0,
    },
    details: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    search: "",
    dialog: false,
    product: null,
    isLoading: false,
    headers: [
      { text: "Servicio", value: "product_name" },
      { text: "Estatus", value: "status" },
      { text: "Cantidad", value: "quantity" },
      { text: "Usados", value: "uses" },
      { text: "Precio ($)", value: "product_price" },
      { text: "Total", value: "total", filterable: false },
    ],
  }),

  computed: {
    ...mapFields({
      car: "medication.cart",
    }),
  },

  watch: {},

  mounted() {
  },

  methods: {
    formatAppointment() {
      return moment(this.dateAppointment)
        .format("DD/MMM/YYYY hh:mm")
        .replace(".", "")
        .toUpperCase();
    },
    attendAppointment() {
      let actives = this.details.filter((item) => item.status != "Eliminado");
      console.log(actives);
      if (actives.length <= 0) {
        this.$snotify.error("Debe haber al menos un servicio activo");
        return false;
      }
    },
    delete_service(index) {
      this.car.splice(index,1)
    },
    checkUsesVal(index) {
      let item = this.car[index]
      if (parseInt(item.uses) > parseInt(item.quantity)) {
        item.uses=item.quantity
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.text-center-header {
  text-align: center !important;
}
.item-quantity {
  .v-text-field__slot input {
    text-align: center;
    width: 50px;
  }
}
table {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>