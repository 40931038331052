<template>
    <v-row justify="center">
      <v-date-picker max-width="200px" no-title v-model="focus" @change="setModeDay"></v-date-picker>
    </v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "calendarselector",

  components: { },

  computed: {
    ...mapFields({
      focus: "appointments.calendar.modelCalendar",
      type: "appointments.calendar.type",
    }),
  },
  methods: {
    setModeDay(){
      this.type = 'day'
    }
  }
};
</script>
