<template>
  <v-row>
    <v-col cols="6" lg="3">
      <v-card outlined shaped>
        <v-card-text>
          <div>Ventas Totales</div>
          <p class="title text--primary ma-0">
            {{ getTotalSalesReport }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" lg="3">
      <v-card outlined shaped>
        <v-card-text>
          <div>Detalles Totales</div>
          <p class="title text--primary ma-0">
            {{ getTotalDetailSalesReport }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" lg="3">
      <v-card outlined shaped>
        <v-card-text>
          <div>Importe de Ventas</div>
          <p class="title text--primary ma-0">
            $ {{ round_out(getSalesAmount) }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" lg="3">
      <v-card outlined shaped>
        <v-card-text>
          <div>Margen Total</div>
          <p class="title text--primary ma-0">
            $ {{ round_out(getTotalMargin) }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InformationCards",
  computed: {
    ...mapGetters([
      "getTotalSalesReport",
      "getTotalDetailSalesReport",
      "getSalesAmount",
      "getTotalMargin"
    ]),
    loading() {
      return this.$store.state.sales.loading;
    }
  },
  methods: {
    round_out(num) {
      return Number.parseFloat(num).toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped></style>
