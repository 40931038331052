<template>
  <v-container class="Home text-center">
    <h1>Bienvenido</h1> 
    <h1>{{$store.state.user.user}}</h1>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
