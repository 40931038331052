<template>
  <v-container class="Sales" fluid>
    <div v-if="sale">
      <h1 class="text-center mb-0">
        {{ sale.status.name != "NUEVO" ? "Detalle Venta" : "Nueva Venta" }}
      </h1>
      <v-row v-if="sale.status.name != 'NUEVO'">
        <v-col cols="12" class="d-flex justify-center py-0">
          <v-chip
            small
            text-color="white"
            :color="colorStatus(sale.status.name)"
          >
            {{ sale.status.name }}
          </v-chip>
        </v-col>
      </v-row>
    </div>
    <h1 v-else class="text-center mb-0">
      ...
    </h1>
    <NewSale></NewSale>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import NewSale from "@/components/Sales/NewSale/Index";

export default {
  name: "CreateSale",
  components: {
    NewSale
  },
  computed: {
    ...mapFields({
      sale: "sales.sale"
    })
  },
  methods: {
    colorStatus(status) {
      switch (status) {
        case "NUEVO":
          return "green";
        case "PREVENTA":
          return "orange";
        case "PAGADO":
          return "blue";
        case "CANCELADA":
          return "red";
        default:
          return "grey";
      }
    }
  }
};
</script>

<style lang="scss"></style>
