<template>
  <v-dialog v-model="$store.state.lots.modal" persistent max-width="680px">
    <v-card>
      <v-toolbar elevation="1" color="primary darken-1" dark>
        NUEVO LOTE
      </v-toolbar>
      <v-card-text>
        <LotForm></LotForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LotForm from "./LotForm";

export default {
  name: "LotModal",
  components: {
    LotForm
  }
};
</script>

<style lang="scss" scoped></style>
