<template>
  <v-row justify="center">
    <v-dialog :value="modalFormCreate" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ id ? "Editar" : "Crear" }} cita</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col>
                  <v-dialog v-model="modal" persistent max-width="1000px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          text
                          x-small
                          class="mr-0 ml-auto"
                          @click="loadDefaultClientData"
                        >
                          Agregar cliente
                        </v-btn>
                      </v-row>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Cliente</span>
                      </v-card-title>
                      <v-card-text>
                        <ClientForm :onSave="onAddNewClient"></ClientForm>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <v-autocomplete
                    v-model="client"
                    :items="clients"
                    item-text="fullName"
                    item-value="id"
                    label="Cliente"
                    hint="Buscar por nombre o telefono"
                    :filter="customSearch"
                    persistent-hint
                    @change="getServices"
                    class="mt-o pt-0"
                    :rules="[(v) => !!v || 'Campo requerido']"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.fullName"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.mobil_phone"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template> </v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-autocomplete
                    v-model="employee"
                    :items="employees"
                    item-text="dataWork"
                    item-value="id"
                    label="Empleado"
                    :rules="[(v) => !!v || 'Campo requerido']"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <selector-fecha
                    :modelo.sync="date"
                    label="Fecha"
                    :rules="[(v) => !!v || 'Campo requerido']"
                  ></selector-fecha>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Hora inicial"
                    v-model="startTime"
                    v-mask="'##:##'"
                    placeholder="HH:MM"
                    :rules="[(v) => !!v || 'Campo requerido', v => validateHour(v) || 'hora no laboral, formato 24H']"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Hora final"
                    v-model="endTime"
                    v-mask="'##:##'"
                    placeholder="HH:MM"
                    :rules="[(v) => !!v || 'Campo requerido', v => validateHour(v) || 'hora no laboral, formato 24H']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-autocomplete
                    v-model="servicesSelected"
                    :items="services"
                    item-text="name"
                    item-value="id"
                    multiple
                    chips
                    label="Servicios"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-text-field
                    v-model="observation"
                    label="Observaciones"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="executeCancel">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="decideAction" :loading="isSaving">
            {{ id ? "Actualizar" : "Guardar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";
import ClientForm from "../../Client/ClientForm";

export default {
  components: {
    ClientForm,
  },
  computed: {
    ...mapGetters({
      modalFormCreate: "getformCreateAppointmentState",
    }),
    ...mapFields({
      employees: "appointments.form.employees",
      clients: "appointments.form.clients",
      services: "appointments.form.services",

      id: "appointments.form.appointment.id",
      client: "appointments.form.appointment.client_id",
      employee: "appointments.form.appointment.employee_id",
      date: "appointments.form.appointment.date",
      startTime: "appointments.form.appointment.hour",
      endTime: "appointments.form.appointment.hour_end",
      servicesSelected: "appointments.form.appointment.products",
      observation: "appointments.form.appointment.observation",

      modal: "clients.modal",
      infoClient: "clients.client",
    }),
  },
  data: () => ({
    isSaving:false
  }),
  methods: {
    ...mapActions({
      storeAppointment: "storeAppointment",
      updateAppointment: "updateAppointment",
      getEmployees: "getEmployeesForm",
      getClients: "getClientsForm",
      getServices: "getServicesForm",
    }),
    ...mapMutations({
      exchangeForm: "EXCHANGE_FORM_CREATE_APPOINTMENT",
      setDefault: "SET_DEFAULT",
    }),
    customSearch(item, queryText) {
      const textOne =
        item.mobil_phone.toLowerCase() + item.fullName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    decideAction() {
      if( this.$refs.form.validate() ){
        this.isSaving = true
        this.id ? this.executeUpdate() : this.executeStore();
      }
    },
    executeCancel() {
      this.exchangeForm();
      this.setDefault();
    },
    executeUpdate() {
      this.updateAppointment()
        .then(() => {
          this.$snotify.success("Actualizado correctamente");
          this.$refs.form.reset();
          this.exchangeForm();
          this.setDefault();
        })
        .catch(() => {
          this.$snotify.error("Error al actualizar la cita");
        }).then(()=>{this.isSaving=false});
    },
    executeStore() {
      this.storeAppointment()
        .then(() => {
          this.$snotify.success("guardado correctamente");
          this.$refs.form.reset();
          this.exchangeForm();
          this.setDefault();
        })
        .catch(() => {
          this.$snotify.error("Error al insertar la cita");
        }).then(()=>{this.isSaving=false});
    },
    onAddNewClient() {
      this.client = this.clients[0].id
      this.getServices()
    },
    validateHour(hour){
      let aux = hour.split(':')
      let h=aux[0]
      let m=aux[1]
      if( parseInt(h)<8 || parseInt(h)>19  )
        return "fuera del horario laboral"
      if( m=='' )
        return "ingrece los minutos"
      if( parseInt(m)<0 || parseInt(m)>59)
        return "minutos no validos"
      return true
    },
    remove (item) {
      const index = this.servicesSelected.indexOf(item.id)
      if (index >= 0) this.servicesSelected.splice(index, 1)
    },
    loadDefaultClientData(){
      this.infoClient = Object.assign({}, {
        blood_type:'NO DISPONIBLE',
        allergies:'SE DESCONOCE',
        state:'OAXACA',
        email:'',
        birthday:''});
    }
  },
  created() {
    this.getEmployees();
    this.getClients();
  },
};
</script>
