<template>
  <v-dialog
    v-model="$store.state.lots.detailModal"
    persistent
    max-width="720px"
  >
    <v-card class="LotDetailModal">
      <v-toolbar elevation="1" color="primary darken-1" dark>
        <v-toolbar-title>DETALLE LOTE</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            $store.commit('closeDetailModalLot');
            $store.commit('SET_PRODUCT', {});
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="my-2 font-weight-bold">Lote</div>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    Código
                  </th>
                  <th class="text-center">
                    Stock Inicial
                  </th>
                  <th class="text-center">
                    Stock Actual
                  </th>
                  <th class="text-center">
                    Precio
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">{{ lot.code }}</td>
                  <td class="text-center">{{ lot.stock }}</td>
                  <td class="text-center">{{ lot.current_stock }}</td>
                  <td class="text-center">$ {{ lot.price }}</td>
                </tr>
                <tr>
                  <th class="text-center">Fecha de caducidad</th>
                  <td class="text-center" colspan="2">
                    {{
                      lot.expiration_date
                        ? dateFormat(lot.expiration_date)
                        : "---"
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <div class="mt-2 font-weight-bold">Producto</div>
        <ProductInfoCard :product="product"></ProductInfoCard>

        <div class="my-2 font-weight-bold">Sucursales</div>
        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="lot.branches"
            :items-per-page="3"
            class="elevation-0"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageOptions: [3]
            }"
          ></v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductInfoCard from "./ProductInfoCard";
import moment from "moment";
moment.locale("es-mx");

export default {
  name: "LotDetailModal",
  components: {
    ProductInfoCard
  },
  data: () => ({
    headers: [
      { text: "Sucursal", value: "name" },
      { text: "Stock", value: "stock" }
    ]
  }),
  computed: {
    lot() {
      return this.$store.state.lots.lot;
    },
    product() {
      return this.$store.state.products.product;
    }
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("LL");
    }
  }
};
</script>

<style lang="scss">
.LotDetailModal {
  table {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
