<template>
  <v-container fluid class="CashRegister pa-0">
    <v-row>
      <v-col cols="12">
        <SaleInfo></SaleInfo>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-tabs v-model="tab" background-color="transparent">
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <PaySalesTable></PaySalesTable>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>...</v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <TicketModal></TicketModal>
    <PaymentModal></PaymentModal>
    <v-overlay :value="loading" :z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import SaleInfo from "./SaleInfo";
import PaySalesTable from "./PaySalesTable";
import TicketModal from "../TicketModal";
import PaymentModal from "./PaymentModal";

export default {
  name: "CashRegister",
  components: {
    SaleInfo,
    PaySalesTable,
    TicketModal,
    PaymentModal
  },
  data: () => ({
    tab: null,
    items: ["Preventas"]
  }),
  computed: {
    ...mapFields({
      loading: "sales.loading" || "sales.updateSaleStatus"
    })
  }
};
</script>

<style lang="scss" scoped></style>
