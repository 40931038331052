<template>
  <v-container class="Branches text-center">
    <h1>Sucursales</h1>
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-btn
              class="ml-2"
              depressed
              color="primary"
              @click="createBranch"
              >Nuevo</v-btn
            >
          </v-card-title>
          <v-data-table :headers="headers" :items="branches" :search="search">
            <template v-slot:item.actions="{ item }">
              <v-btn icon depressed color="secondary" @click="editBranch(item)"><v-icon>mdi-pencil</v-icon></v-btn>
              <v-btn icon depressed color="error" @click="deleteBranch(item)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="modal"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Nueva sucursal</span>
        </v-card-title>
        <v-card-text>
          <BranchForm v-if="modal"></BranchForm>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EventBus from "../EventBus";
import BranchForm from "../components/Branch/BranchForm";

export default {
  name: "Branch",

  components: { BranchForm },

  data: () => ({
    modal: false,
    search: "",
    headers: [
      {
        text: "#",
        align: "start",
        filterable: false,
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "Dirección", value: "address" },
      { text: "Acciones", value: "actions", filterable: false },
    ],
  }),

  computed: {
    branches() {
      return this.$store.state.branches.branches;
    },
  },

  mounted() {
    this.loadBranches();
    
    EventBus.$on("Branches::modalHide", () => {
    //   this.$snotify.success("Empleado actualizado correctamente");
      this.modal = false;
      this.$store.commit("RESET_DATA");
    });
    EventBus.$on("Branches::branchCreated", () => {
      this.$snotify.success("Sucursal creada correctamente");
      this.modal = false;
      this.$store.commit("RESET_DATA");
    });
    EventBus.$on("Branches::branchUpdated", () => {
      this.$snotify.success("Sucursal actualizada correctamente");
      this.modal = false;
      this.$store.commit("RESET_DATA");
    });
    EventBus.$on("Branches::branchDeleted", () => {
      this.$snotify.success("Sucursal eliminada correctamente");
    });
  },

  methods: {
    loadBranches() {
      this.$store.dispatch("getBranches");
    },
    createBranch() {
      this.$store.commit("RESET_DATA");
      this.modal = true;
    },
    editBranch(branch) {
      this.$store.commit("EDIT_BRANCH", branch)
      this.modal = true;
    },
    deleteBranch(branch) {
      this.$store.commit("EDIT_BRANCH", branch)
      this.$store.dispatch("deleteBranch");
    }
  }

}
</script>

<style lang="scss" scoped></style>
