import axios from "axios";

export const SET_SALE = "SET_SALE";
export const SET_SALES = "SET_SALES";
export const SET_PAYSALES = "SET_PAYSALES";
export const SET_PAYSALE = "SET_PAYSALE";
export const ADD_PRODUCT_SALE = "ADD_PRODUCT_SALE";
export const CREATE_NEW_SALE = "CREATE_NEW_SALE";
export const SET_TICKET = "SET_TICKET";
export const SET_PAYMENT_TYPES = "SET_PAYMENT_TYPES";
export const SET_SALES_REPORT = "SET_SALES_REPORT";

export default {
  state: {
    sales: [],
    paysales: [],
    sale: null,
    paysale: null,
    saleDetails: [],
    saleProduct: null,
    newSaleLoading: null,
    loading: null,
    saveDetailLoading: 0,
    updateSaleStatusLoading: false,
    saleInfoModal: false,
    selectClientModal: false,
    saleClient: {},
    saleClientModal: false,
    modalTicket: false,
    ticketb64: null,
    paymentTypesLoading: false,
    paymentTypes: [],
    paymentModal: false,
    salesreport: [],
    saleCancelModal: false
  },
  mutations: {
    startSaveDetailLoading(state) {
      state.saveDetailLoading += 1;
    },
    stopSaveDetailLoading(state) {
      state.saveDetailLoading -= 1;
    },
    startSaleLoading(state) {
      state.loading = true;
    },
    stopSaleLoading(state) {
      state.loading = false;
    },
    startNewSaleLoading(state) {
      state.newSaleLoading = true;
    },
    stopNewSaleLoading(state) {
      state.newSaleLoading = false;
    },
    startUpdateSaleStatusLoading(state) {
      state.updateSaleStatus = true;
    },
    stopUpdateSaleStatusLoading(state) {
      state.updateSaleStatus = false;
    },
    startPaymentTypesLoading(state) {
      state.paymentTypesLoading = true;
    },
    stopPaymentTypesLoading(state) {
      state.paymentTypesLoading = false;
    },
    openSaleInfoModal(state) {
      state.saleInfoModal = true;
    },
    closeSaleInfoModal(state) {
      state.saleInfoModal = false;
    },
    openSelectClientModal(state) {
      state.selectClientModal = true;
    },
    closeSelectClientModal(state) {
      state.selectClientModal = false;
    },
    openSaleClientModal(state) {
      state.saleClientModal = true;
    },
    closeSaleClientModal(state) {
      state.saleClientModal = false;
    },
    openModalTicket(state) {
      state.modalTicket = true;
    },
    closeModalTicket(state) {
      state.modalTicket = false;
    },
    openPaymentModal(state) {
      state.paymentModal = true;
    },
    closePaymentModal(state) {
      state.paymentModal = false;
    },
    openCancelSaleModal(state) {
      state.saleCancelModal = true;
    },
    closeCancelSaleModal(state) {
      state.saleCancelModal = false;
    },
    [SET_SALE](state, sale) {
      state.sale = sale;
    },
    [SET_SALES](state, sales) {
      state.sales = sales;
    },
    [SET_PAYSALES](state, sales) {
      state.paysales = sales;
    },
    [SET_PAYSALE](state, sale) {
      state.paysale = sale;
    },
    [SET_TICKET](state, b64) {
      state.ticketb64 = b64;
    },
    [SET_PAYMENT_TYPES](state, paymentTypes) {
      state.paymentTypes = paymentTypes;
    },
    [SET_SALES_REPORT](state, salesreport) {
      state.salesreport = salesreport;
    }
  },
  actions: {
    LoadSales({ commit }) {
      commit("startSaleLoading");
      return axios
        .get(`/api/sales`)
        .then(r => {
          commit("SET_SALES", r.data.data);
          commit("stopSaleLoading");
        })
        .catch(e => {
          console.log(e);
          commit("stopSaleLoading");
        });
    },
    LoadPaymentTypes({ commit }) {
      commit("startPaymentTypesLoading");
      return axios
        .get(`/api/paymentTypes`)
        .then(r => {
          commit("SET_PAYMENT_TYPES", r.data);
          commit("stopPaymentTypesLoading");
        })
        .catch(e => {
          console.log(e);
          commit("stopPaymentTypesLoading");
        });
    },
    CreateNewSale({ commit }) {
      commit("startNewSaleLoading");
      return axios
        .post(`api/sales`, {
          type: "PRODUCTO",
          amount: 0,
          module: "FA",
          payment: 0
        })
        .then(r => {
          commit("stopNewSaleLoading");
          return r.data;
        })
        .catch(e => {
          commit("stopNewSaleLoading");
          console.error(e);
          return {
            error: true,
            msg: e
          };
        });
    },
    FetchSale({ commit }, id) {
      commit("startSaleLoading");
      return axios
        .get(`api/sales/${id}`)
        .then(r => {
          commit("stopSaleLoading");
          commit("SET_SALE", r.data.data);
          return r.data;
        })
        .catch(e => {
          commit("stopSaleLoading");
          console.error(e);
          return {
            error: true,
            msg: e
          };
        });
    },
    AddSaleDetail({ commit, state }, data) {
      commit("startSaveDetailLoading");
      return axios
        .post(`/api/sales/${state.sale.id}/detail`, data)
        .then(r => {
          commit("stopSaveDetailLoading");
          return r.data;
        })
        .catch(e => {
          console.log(e);
          commit("stopSaveDetailLoading");
          return {
            error: true,
            msg: e
          };
        });
    },
    UpdateStock({ commit }, data) {
      commit("startSaveDetailLoading");
      return axios
        .put(`/api/saledetails/${data.id}/stock`, data.detail)
        .then(r => {
          console.log(r);
          commit("stopSaveDetailLoading");
          return r;
        })
        .catch(e => {
          console.log(e);
          commit("stopSaveDetailLoading");
          return {
            error: true,
            msg: e
          };
        });
    },
    UpdateStatus({ commit }, data) {
      commit("startUpdateSaleStatusLoading");
      return axios
        .put(
          `/api/sales/${data.id}/${data.status}`,
          data.status == "paid" ? data.data : {}
        )
        .then(r => {
          console.log(r);
          commit("stopUpdateSaleStatusLoading");
          return r;
        })
        .catch(e => {
          console.log(e);
          commit("stopUpdateSaleStatusLoading");
          return {
            error: true,
            msg: e
          };
        });
    },
    LoadPaySales({ commit }) {
      commit("startSaleLoading");
      return axios
        .get(`/api/salestopay`)
        .then(r => {
          commit("SET_PAYSALES", r.data.data);
          commit("stopSaleLoading");
        })
        .catch(e => {
          console.log(e);
          commit("stopSaleLoading");
        });
    },
    UpdateClient({ commit }, data) {
      commit("startSaleLoading");
      return axios
        .put(`/api/sales/${data.id}/client`, { client_id: data.client })
        .then(r => {
          console.log(r);
          commit("stopSaleLoading");
          return r;
        })
        .catch(e => {
          console.log(e);
          commit("stopSaleLoading");
          return {
            error: true,
            msg: e
          };
        });
    },
    SaveSaleClient({ commit }, data) {
      commit("startSaleLoading");
      return axios
        .post(`/api/saleclient`, data)
        .then(r => {
          console.log(r);
          commit("stopSaleLoading");
          return r;
        })
        .catch(e => {
          console.log(e);
          commit("stopSaleLoading");
          return {
            error: true,
            msg: e
          };
        });
    },
    FecthTicket({ commit }, idventa) {
      commit("startSaleLoading");
      return axios
        .get(`/api/documents/${idventa}`)
        .then(r => {
          console.log(r);
          commit("stopSaleLoading");
          return r;
        })
        .catch(e => {
          console.log(e);
          commit("stopSaleLoading");
          return {
            error: true,
            msg: e
          };
        });
    },
    FecthSalesReport({ commit }, data) {
      commit("startSaleLoading");
      return axios
        .post(`api/salesreport/branch/${data.branchId}`, data.data)
        .then(r => {
          console.log(r);
          commit("SET_SALES_REPORT", r.data);
          commit("stopSaleLoading");
          return r;
        })
        .catch(e => {
          console.log(e);
          commit("stopSaleLoading");
          return {
            error: true,
            msg: e
          };
        });
    },
    ReloadCashRegister({ commit, dispatch }) {
      dispatch("LoadPaySales");
      commit("SET_PAYSALE", null);
    }
  },
  getters: {
    getSales: state =>
      state.sales.map((e, i) => ({
        ...e,
        index: i + 1
      })),
    getSaleDetails: state => {
      if (state.sale) {
        if (state.sale.details.length > 4)
          state.sale.details.push({
            price: "TOTAL",
            amount: state.sale.amount
          });
        return state.sale.details;
      }
      return [];
    },
    getPaySales: state => state.paysales,
    getPaymentTypes: state => state.paymentTypes,
    getCashSales: state =>
      state.salesreport ? state.salesreport.cash_sales : [],
    getTerminalSales: state =>
      state.salesreport ? state.salesreport.terminal_sales : [],
    getTotalCashSales: (state, getters) =>
      getters.getCashSales
        ? [
            {
              margin: getters.getCashSales.reduce(
                (acc, cur) => acc + cur.margin,
                0
              ),
              amount_total: getters.getCashSales.reduce(
                (acc, cur) => acc + cur.amount_total,
                0
              )
            }
          ]
        : [
            {
              product_purchase_price: 0,
              product_sale_price: 0,
              margin: 0
            }
          ],
    getTotalTerminalSales: (state, getters) =>
      getters.getTerminalSales
        ? [
            {
              margin: getters.getTerminalSales.reduce(
                (acc, cur) => acc + cur.margin,
                0
              ),
              amount_total: getters.getTerminalSales.reduce(
                (acc, cur) => acc + cur.amount_total,
                0
              )
            }
          ]
        : [
            {
              product_purchase_price: 0,
              product_sale_price: 0,
              margin: 0
            }
          ],
    getTotalDetailSalesReport: (state, getters) =>
      getters.getCashSales && getters.getTerminalSales
        ? getters.getCashSales.length + getters.getTerminalSales.length
        : 0,
    getTotalMargin: (state, getters) =>
      getters.getTotalCashSales[0].margin +
      getters.getTotalTerminalSales[0].margin,
    getTotalSalesReport: state =>
      state.salesreport ? state.salesreport.total_sales : 0,
    getSalesAmount: state =>
      state.salesreport ? state.salesreport.sales_amount || 0 : 0
  }
};
