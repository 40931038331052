<template>
  <div class="Files">
    <v-card elevation="0">
      <v-card-text>
        <h2>Estudios previos</h2>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-list flat>
                <v-subheader>Estudios de gabinete</v-subheader>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, i) in $store.getters.cabinetStudies"
                    :key="i"
                    @click="selectFile(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-file</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="$store.getters.cabinetStudies.length == 0">
                    <v-list-item-content>
                      <v-list-item-title
                        >No se han subido estudios</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-list flat>
                <v-subheader>Estudios de laboratorio</v-subheader>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, i) in $store.getters.laboratoryStudies"
                    :key="i"
                    @click="selectFile(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-file</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="$store.getters.laboratoryStudies.length == 0"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >No se han subido estudios</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <template v-if="!readOnly">
          <h2 class="mb-4">Subir estudios</h2>
          <v-col cols="12">
            <v-file-input
              chips
              dense
              counter
              multiple
              show-size
              truncate-length="15"
              clearable
              accept="image/png, image/jpeg, image/bmp, application/pdf"
              label="Estudios de laboratorios"
              placeholder="Selecciona los archivos"
              v-model="laboratory"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-file-input
              chips
              dense
              counter
              multiple
              show-size
              truncate-length="15"
              clearable
              accept="image/png, image/jpeg, image/bmp, application/pdf"
              label="Estudios de gabinete"
              placeholder="Selecciona los archivos"
              v-model="cabinet"
            ></v-file-input>
          </v-col>
        </template>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showFile">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <v-row class="justify-space-between">
            {{ fileShowed.name }}
            <v-icon @click="showFile = false">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <embed :src="fileShowed.url" style="width: 100%; min-height: 600px" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "Files",
  props:['readOnly'],
  data() {
    return {
      showFile: false,
      fileShowed: {},
    };
  },
  mounted() {
    this.$store.dispatch("fetchStudies");
  },
  computed: {
    ...mapFields({
      laboratory: "consultation.files.laboratory",
      cabinet: "consultation.files.cabinet",
    }),
  },
  methods: {
    selectFile(file) {
      this.showFile = true;
      this.fileShowed = file;
    },
  },
};
</script>

<style></style>
