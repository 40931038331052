import Vue from "vue";
import Vuex from "vuex";
import { getField, updateField } from "vuex-map-fields";
import VuexPersistence from 'vuex-persist'

import exampleModule from "../store/example";
import employeesModule from "./employees";
import appointmentsModule from "./Appointments/appointments";
import transfers from "./Transfers/transfers";
import clientsModule from "./Clients/clients";
import consultationModule from "./consultation";
import productsModule from "./products";
import branchesModule from "./branches";
import lotsModule from "./lots";
import salesModule from "./sales";
import medication from "./medication";

Vue.use(Vuex);

const persistence = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({user: state.user}),
  modules: []
})

export default new Vuex.Store({
  state: {
    drawerRight: false,
    user: {}
  },
  getters: {
    getField,
    checkUser: state => state.user.id !== undefined,
    hasPermission: state => permission => state.user.all_permissions.includes(permission),
    hasAnyPermission: state => permissions => permissions.map(p => state.user.all_permissions.includes(p) ).reduce((result,current) => result || current, false),
    hasAllPermissions: state => permissions => permissions.map(p => state.user.all_permissions.includes(p) ).reduce((result,current) => result && current, Boolean(permissions.length) ),
  },
  mutations: {
    updateField,
    setUser(state, payload){
      Vue.set(state,'user',payload)
    }
  },
  actions: {
    eraseUser({commit}){
      commit('setUser',{});
    }
  },
  modules: {
    example: exampleModule,
    employees: employeesModule,
    clients: clientsModule,
    consultation: consultationModule,
    appointments: appointmentsModule,
    products: productsModule,
    branches: branchesModule,
    lots: lotsModule,
    sales: salesModule,
    transfers: transfers,
    medication: medication
  },
  plugins: [persistence.plugin]
});
