<template>
  <v-form
    class="SaleClientForm"
    ref="SaleClientForm"
    v-model="valid"
    lazy-validation
  >
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="client.first_name"
            :rules="[v => !!v || 'Campo requerido']"
            label="Nombre(s)"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="client.last_name"
            :rules="[v => !!v || 'Campo requerido']"
            label="Primer Apellido"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="client.maiden_name"
            :rules="[v => !!v || 'Campo requerido']"
            label="Segundo Apellido"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="2">
          <v-autocomplete
            v-model="client.sex"
            :rules="[v => !!v || 'Campo requerido']"
            :items="gender"
            label="Genero"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="client.birthday"
            label="Fecha de Nacimiento"
            v-mask="'####-##-##'"
            :rules="dateRules"
            hint="YYYY-MM-DD"
            persistent-hint
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="client.mobil_phone"
            :counter="10"
            :rules="[v => !!v || 'Campo requerido']"
            label="Celular"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn class="mr-4" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn
            color="primary darken-1"
            :loading="$store.state.products.saveLoading"
            :disabled="$store.state.products.saveLoading"
            @click="store()"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import moment from "moment";

export default {
  name: "SaleClientForm",
  data: () => {
    const validbirthdate = date => {
      var birthdate = moment(date, "YYYY-MM-DD", true).format("YYYY-MM-DD");
      var max = moment.max(moment(date), moment()).format("YYYY-MM-DD");
      var min = moment
        .min(moment(date), moment("1900-01-01"))
        .format("YYYY-MM-DD");
      if (birthdate === max || birthdate === min) {
        return false;
      }
      return true;
    };
    return {
      gender: ["M", "F"],
      valid: true,
      dateRules: [
        v => !!v || "Campo requerido",
        v => /\d{4}-\d{2}-\d{2}/.test(v) || "Formato Incorrecto (YYYY-MM-DD)",
        v => validbirthdate(v) || "Fecha Invalida."
      ]
    };
  },
  computed: {
    ...mapFields({
      client: "sales.saleClient"
    })
  },
  methods: {
    store() {
      if (this.$refs.SaleClientForm.validate()) {
        this.$store.dispatch("SaveSaleClient", this.client).then(r => {
          if (!r.error) {
            console.log(r.data.data.id);
            this.$store
              .dispatch("UpdateClient", {
                id: this.$route.params.id,
                client: r.data.data.id
              })
              .then(r => {
                if (!r.error) {
                  this.$store.dispatch("FetchSale", this.$route.params.id);
                } else {
                  this.$snotify.error("Error al Actualizar Cliente");
                }
              });
            this.$store.commit("closeSaleClientModal");
          } else {
            this.$snotify.error("Ocurrio un error");
            if (r.msg.response && r.msg.response.data) {
              Object.keys(r.msg.response.data.errors).forEach(e => {
                r.msg.response.data.errors[e].forEach(m => {
                  this.$snotify.error(m, { timeout: 5000 });
                });
              });
            }
          }
        });
      }
    },
    cancel() {
      this.client = {};
      this.$refs.SaleClientForm.resetValidation();
      this.$store.commit("closeSaleClientModal");
    }
  }
};
</script>

<style lang="scss" scoped></style>
