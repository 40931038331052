<template>
  <v-speed-dial fixed v-model="fab" :bottom="bottom" :right="right">
    <template v-slot:activator>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" @click="exchangeForm" dark fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>add</v-icon>
          </v-btn>
        </template>
        <span>Crear cita</span>
      </v-tooltip>
    </template>
  </v-speed-dial>
</template>

<script>

import { mapMutations } from 'vuex'
export default {
  data: () => ({
    direction: "top",
    fab: false,
    right: true,
    bottom: true,
    title: "Crear cita",
  }),

  methods: {
    ...mapMutations({
      exchangeForm: "EXCHANGE_FORM_CREATE_APPOINTMENT",
    }),
  },
};
</script>
