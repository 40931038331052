<template>
  <v-container class="SalesReport" fluid>
    <h1 class="text-center mb-1">Reporte de Ventas</h1>
    <BranchAndDate></BranchAndDate>
    <InformationCards></InformationCards>
    <v-card outlined class="mb-2">
      <v-card-title
        class="blue-grey lighten-5 grey--text text--darken-2 body-2 font-weight-bold"
        >DETALLE DE VENTAS REALIZADAS EN EFECTIVO</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <SalesDetailTable
          :sales="$store.getters.getCashSales"
          :total="$store.getters.getTotalCashSales"
        ></SalesDetailTable>
      </v-card-text>
    </v-card>
    <v-card outlined>
      <v-card-title
        class="blue-grey lighten-5 grey--text text--darken-2 body-2 font-weight-bold"
        >DETALLE DE VENTAS REALIZADAS EN TERMINAL</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <SalesDetailTable
          :sales="$store.getters.getTerminalSales"
          :total="$store.getters.getTotalTerminalSales"
        ></SalesDetailTable>
      </v-card-text>
    </v-card>
    <v-overlay :value="loading" :z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import SalesDetailTable from "@/components/ReportSales/SalesDetailTable";
import BranchAndDate from "@/components/ReportSales/BranchAndDate";
import InformationCards from "@/components/ReportSales/InformationCards";

export default {
  name: "SalesReport",
  components: {
    SalesDetailTable,
    BranchAndDate,
    InformationCards
  },
  mounted() {
    this.$store.dispatch("FecthSalesReport", {
      branchId: 1,
      data: {}
    });
  },
  computed: {
    loading() {
      return this.$store.state.sales.loading;
    }
  }
};
</script>

<style lang="scss">
.SalesReport {
  .ag-theme-alpine .ag-root-wrapper {
    border: 0;
  }
}
</style>
