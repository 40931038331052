var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SalesTable"},[_c('v-card',[_c('v-app-bar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","disabled":_vm.$store.state.sales.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"color":"accent darken-3","text":"","disabled":_vm.$store.state.sales.loading}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('span',[_vm._v("Ventas por Página")])])]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","color":"accent darken-3","disabled":_vm.$store.state.sales.loading},on:{"click":function($event){return _vm.$store.dispatch('LoadSales')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-update")])],1)]}}])},[_c('span',[_vm._v("Recargar")])])],1),_c('v-col',[_c('v-text-field',{attrs:{"clearable":"","flat":"","solo":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Buscar","disabled":_vm.$store.state.sales.loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"accent darken-1","disabled":_vm.$store.state.sales.loading},on:{"click":function($event){return _vm.create_new_sale()}}},[_vm._v(" Nueva Venta ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.getters.getSales,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","page":_vm.page,"search":_vm.search,"loading":_vm.$store.state.sales.loading},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.folio",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.folio))])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.client_name))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.amount)+" ")]}},{key:"item.status_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","color":_vm.colorStatus(item.status_name),"text-color":"white"}},[_vm._v(" "+_vm._s(item.status_name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('SaleOptions',{attrs:{"sale":item}})]}},{key:"no-results",fn:function(){return [_c('v-row',{staticClass:"mt-3",attrs:{"align-content":"center","justify":"center"}},[_c('v-alert',{attrs:{"outlined":"","color":"secondary","border":"left","icon":"mdi-alert-decagram-outline"}},[_vm._v(" Ningún elemento coincide con la búsqueda ")])],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"mt-3",attrs:{"align-content":"center","justify":"center"}},[_c('v-alert',{attrs:{"outlined":"","color":"secondary","border":"left","icon":"mdi-alert-circle"}},[_vm._v(" No hay datos disponibles ")])],1)]},proxy:true}],null,true)})],1),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"disabled":_vm.$store.state.lots.loading,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }