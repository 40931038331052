import axios from "axios";

export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_BRANCH_PRODUCTS = "SET_BRANCH_PRODUCTS";

const getFormData = (object, method = "post") => {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  if (method == "put") {
    formData.append("_method", "put");
  }
  return formData;
};

export default {
  state: {
    products: [],
    branchProducts: [],
    categories: [],
    loading: false,
    modal: false,
    drawer: false,
    saveLoading: false,
    product: {},
    keys: [
      "name",
      "barcode",
      "description",
      "presentation",
      "price",
      "type",
      "category_id",
      "retail_price",
      "wholesale_price",
      "image"
    ]
  },
  mutations: {
    startProductsLoading(state) {
      state.loading = true;
    },
    stopProductsLoading(state) {
      state.loading = false;
    },
    startProductSaveLoading(state) {
      state.saveLoading = true;
    },
    stopProductSaveLoading(state) {
      state.saveLoading = false;
    },
    openModalNewProduct(state) {
      state.product = {};
      state.modal = true;
    },
    openModalProduct(state) {
      state.modal = true;
    },
    closeModalProduct(state) {
      state.modal = false;
    },
    openDrawerProduct(state) {
      state.drawer = true;
    },
    closeDrawerProduct(state) {
      state.drawer = false;
    },
    [SET_PRODUCT](state, product) {
      state.product = product;
    },
    [SET_PRODUCTS](state, products) {
      state.products = products;
    },
    [SET_CATEGORIES](state, categories) {
      state.categories = categories;
    },
    [SET_BRANCH_PRODUCTS](state, products) {
      state.branchProducts = products;
    }
  },
  actions: {
    LoadProducts({ commit }) {
      commit("startProductsLoading");
      return axios
        .get(`/api/products`)
        .then(r => {
          commit("SET_PRODUCTS", r.data.data);
          commit("stopProductsLoading");
        })
        .catch(e => {
          console.log(e);
          commit("stopProductsLoading");
        });
    },
    StoreProduct({ commit, state }, method) {
      commit("startProductSaveLoading");
      return axios
        .post(
          `/api/products${method == "post" ? "" : "/" + state.product.id}`,
          getFormData(
            Object.fromEntries(
              Object.entries(state.product).filter(([key]) =>
                state.keys.includes(key)
              )
            ),
            method
          )
        )
        .then(r => {
          commit("stopProductSaveLoading");
          console.log(r.data);
          return r.data;
        })
        .catch(e => {
          console.log(e);
          commit("stopProductSaveLoading");
          return {
            error: true,
            msg: e
          };
        });
    },
    LoadCategories({ commit }) {
      commit("startProductsLoading");
      return axios
        .get(`/api/categories`)
        .then(r => {
          commit("SET_CATEGORIES", r.data);
          commit("stopProductsLoading");
        })
        .catch(e => {
          console.log(e);
          commit("stopProductsLoading");
        });
    },
    FetchProduct({ commit }, id) {
      commit("startProductsLoading");
      return axios
        .get(`/api/products/${id}`)
        .then(r => {
          commit("SET_PRODUCT", r.data.data);
          commit("stopProductsLoading");
        })
        .catch(e => {
          console.log(e);
          commit("stopProductsLoading");
        });
    },
    LoadBranchProduct({ commit }) {
      commit("startProductsLoading");
      return axios
        .get(`/api/branchproducts`)
        .then(r => {
          commit("SET_BRANCH_PRODUCTS", r.data);
          commit("stopProductsLoading");
        })
        .catch(e => {
          console.log(e);
          commit("stopProductsLoading");
        });
    }
  },
  getters: {
    getProducts: state => state.products,
    getBranchProducts: state => state.branchProducts,
    getCategories: state => state.categories,
    getOnlyProducts: state => state.products.filter(f=>f.type=='PRODUCTO')
  }
};
