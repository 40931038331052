import axios from "axios";

export const SET_CART = "SET_CART";
export const SET_APPOIMENT_ID = "SET_APPOIMENT_ID";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_ONE_PRODUCT = "ADD_ONE_PRODUCT";
export const SUBTRACT_ONE_PRODUCT = "SUBTRACT_ONE_PRODUCT";
export const REMOVE_ONE_PRODUCT = "REMOVE_ONE_PRODUCT";
export const CLEAN_DATA = "CLEAN_DATA";
export const SET_CLIENT_SESSIONS = "SET_CLIENT_SESSIONS";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const SET_CLIENT_SERVICES = "SET_CLIENT_SERVICES";
export const SET_CLIENT_PRODUCTS = "SET_CLIENT_PRODUCTS";
export const ALTER_QUANTITY_PRODUCT = "ALTER_QUANTITY_PRODUCT";
export const ALTER_USES_PRODUCT = "ALTER_USES_PRODUCT";

export default {
  state: {
    appoiment_id: null,
    cart: [],
    cartback: "",
    sessions: [],
    serviceHistory: [],
    productHistory: [],
  },
  mutations: {
    [SET_CART](state, payload) {
      state.cart = payload;
      state.cartback = JSON.stringify(payload);
    },
    [SET_APPOIMENT_ID](state, payload) {
      state.appoiment_id = payload;
    },
    [ADD_PRODUCT](state, payload) {
      let index = state.cart.findIndex(item => item.product_id == payload.product_id);
      if(index >= 0 && payload.product_price > 0) {
        state.cart[index].quantity += payload.quantity;
      } else
        state.cart.push(payload);
    },
    [ADD_ONE_PRODUCT](state, payload) {
      let index = state.cart.findIndex(item => item.product_id == payload.product_id && item.product_price == payload.product_price);
      state.cart[index].quantity++;
    },
    [SUBTRACT_ONE_PRODUCT](state, payload) {
      let index = state.cart.findIndex(item => item.product_id == payload.product_id && item.product_price == payload.product_price);
      if(state.cart[index].quantity == 1) {
        if(state.cart[index].session_id) {
          let i = state.sessions.findIndex(el => el.id == state.cart[index].session_id);
          state.sessions[i].status = "Pendiente";
        }
        state.cart.splice(index, 1);
      }
      else{
        state.cart[index].quantity--;
      }
    },
    [REMOVE_ONE_PRODUCT](state, payload) {
      let index = state.cart.findIndex(item => item.product_id == payload.product_id && item.product_price == payload.product_price);
      if(state.cart[index].session_id) {
        let i = state.sessions.findIndex(el => el.id == state.cart[index].session_id);
        state.sessions[i].status = "Pendiente";
      }
      state.cart.splice(index, 1);
    },
    [CLEAN_DATA](state) {
      state.cart = JSON.parse(state.cartback);
      state.sessions = [];
      state.serviceHistory = [];
      state.productHistory = [];
    },
    [SET_CLIENT_SESSIONS](state, payload) {
      state.sessions = payload;
    },
    [CHANGE_STATUS](state, payload){
      let index = state.sessions.findIndex(el => el.product_id == payload.product_id);
      state.sessions[index].status = payload.status;
    },
    [SET_CLIENT_SERVICES](state, payload) {
      state.serviceHistory = payload;
    },
    [SET_CLIENT_PRODUCTS](state, payload) {
      state.productHistory = payload;
    },
    [ALTER_QUANTITY_PRODUCT](state,payload){
      let index = state.cart.findIndex(item => item.product_id == payload.product_id && item.product_price == payload.product_price);
      state.cart[index].quantity=payload.value;
    },
    [ALTER_USES_PRODUCT](state,payload){
      let index = state.cart.findIndex(item => item.product_id == payload.product_id && item.product_price == payload.product_price);
      state.cart[index].uses=payload.value;
    }
  },
  actions: {
    createSaleMedication({ state, commit }, clientId) {
      return axios.post(`/api/clients/${clientId}/sessions`, { services: state.cart }).then(response => {
        commit("CLEAN_DATA", response.data.data);
        return response.data;
      }).catch(error => {
        this.$snotify.error("Ocurrio un error");
        console.log(error)
      })
    },
    loadDataSessions({ commit }, clientId) {
      return axios.get(`/api/clients/${clientId}/sessions`).then(response => {
        commit("SET_CLIENT_SESSIONS", response.data.sessions);
        commit("SET_CLIENT_SERVICES", response.data.salesServices.map(item => item.details).flat());
        commit("SET_CLIENT_PRODUCTS", response.data.salesProducts.map(item => item.details).flat());
      }).catch(error => {
        this.$snotify.error("Ocurrio un error");
        console.log(error)
      })
    }
  },
  getters: {
    medicationcart: state => state.cart,
    newservices: state => state.cart.filter(item => !item.session_id).length,
    oldservices: state => state.cart.filter(item => item.session_id > 0).length,
    sessions: state => state.sessions,
    sessionClient: state => state.serviceHistory,
    productClient: state => state.productHistory,
    total: state => state.cart.map(item => (item.quantity * item.product_price)).reduce((acc, value) => (acc + value), 0)
  },
};
