<template>
  <v-form class="ProductForm" ref="ProductForm" v-model="valid" lazy-validation>
    <v-container class="pa-0">
      <v-row>
        <v-col cols="5">
          <ProductImageInput
            :image.sync="form.image"
            :setImageCallbackBus="{ name: 'ProductForm:setFocusName' }"
          ></ProductImageInput>
        </v-col>
        <v-col cols="7">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                :items="items"
                label="Tipo"
                v-model="form.type"
                :disabled="$store.state.products.saveLoading"
                :rules="[v => !!v || 'Tipo es obligatorio.']"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="form.name"
                label="Nombre"
                :rules="[v => !!v || 'Nombre es obligatorio.']"
                required
                autofocus
                :disabled="$store.state.products.saveLoading"
                ref="productName"
                rows="2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="py-0" v-if="form.type=='PRODUCTO'">
              <v-text-field
                v-model="form.barcode"
                label="Código de barras"
                :rules="[v => !!v || 'Código de barras es obligatorio.']"
                required
                :disabled="$store.state.products.saveLoading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="form.price"
                label="Precio de compra"
                required
                :rules="[v => !!v || 'Precio de Compra es obligatorio.']"
                :disabled="$store.state.products.saveLoading"
                prefix="$"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.retail_price"
            label="Precio Menudeo"
            required
            :rules="[v => !!v || 'Precio Menudeo es obligatorio.']"
            :disabled="$store.state.products.saveLoading"
            prefix="$"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.wholesale_price"
            label="Precio Mayoreo"
            required
            :rules="[v => !!v || 'Precio Mayoreo es obligatorio.']"
            :disabled="$store.state.products.saveLoading"
            prefix="$"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-autocomplete
        :items="$store.getters.getCategories"
        label="Categoria"
        v-model="form.category_id"
        item-text="name"
        item-value="id"
        :disabled="$store.state.products.saveLoading"
        :rules="[v => !!v || 'Categoria es obligatorio.']"
      ></v-autocomplete>
      <v-textarea
        v-model="form.presentation"
        label="Presentación"
        rows="2"
        :disabled="$store.state.products.saveLoading"
        v-if="form.type=='PRODUCTO'"
      ></v-textarea>
      <v-textarea
        v-model="form.description"
        label="Descripción"
        rows="2"
        :disabled="$store.state.products.saveLoading"
      ></v-textarea>

      <v-row class="d-flex justify-end ma-0 pa-0">
        <v-btn
          class="mr-4"
          @click="cancel()"
          :disabled="$store.state.products.saveLoading"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary darken-1"
          :loading="$store.state.products.saveLoading"
          :disabled="$store.state.products.saveLoading"
          @click="store()"
        >
          {{ form.id ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import EventBus from "@/EventBus";
import ProductImageInput from "./ProductImageInput";

export default {
  name: "ProductForm",
  components: {
    ProductImageInput
  },
  data: () => ({
    items: ["PRODUCTO", "SERVICIO"],
    valid: true
  }),
  computed: {
    form() {
      return this.$store.state.products.product;
    }
  },
  mounted() {
    this.$store.dispatch("LoadCategories");
    EventBus.$on("ProductForm:setFocusName", () => {
      this.setFocusName();
    });
    EventBus.$on("ProductForm:resetValidation", () => {
      this.resetValidationForm();
    });
  },
  methods: {
    cancel() {
      this.clearForm();
      this.$store.commit("closeModalProduct");
    },
    store() {
      if (this.$refs.ProductForm.validate()) {
        this.$store
          .dispatch("StoreProduct", this.form.id ? "put" : "post")
          .then(r => {
            if (!r.error) {
              this.$store.dispatch("LoadProducts");
              if (this.form.id) {
                this.$store.commit("closeModalProduct");
                this.$snotify.info("Producto Actualizado");
              } else {
                this.$snotify.success("Producto Agregado");
              }
              this.clearForm();
            } else {
              console.log("Error al guardar");
              this.$snotify.error("Ocurrio un error");
              if (r.msg.response && r.msg.response.data) {
                Object.keys(r.msg.response.data.errors).forEach(e => {
                  r.msg.response.data.errors[e].forEach(m => {
                    this.$snotify.error(m);
                  });
                });
              }
            }
          });
      }
    },
    clearForm() {
      this.resetValidationForm();
      EventBus.$emit("ProductImageInput:ClearPreview");
    },
    resetValidationForm() {
      Object.keys(this.form).forEach(e => {
        this.form[e] = null;
      });
      if (this.$refs.ProductForm) this.$refs.ProductForm.resetValidation();
    },
    setFocusName() {
      this.$refs.productName.focus();
    }
  }
};
</script>

<style lang="scss">
.ProductForm {
  #img-product-path.no-preview {
    transition: all 0.3s;
    .v-card__text,
    i {
      color: #bdbdbd !important;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    &:hover {
      cursor: pointer;
      border-color: #757575;
      .v-card__text,
      i {
        color: #757575 !important;
      }
    }
  }
}
</style>
