<template>
  <div class="PageLayout">
    <v-app-bar app clipped-right color="primary" dark>
      <v-app-bar-nav-icon @click.native="menuAction" />
      <v-toolbar-title>{{ companyName }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="toggleFullScreen()">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
      <ProfileMenu></ProfileMenu>

      <v-app-bar-nav-icon
        v-if="drawerView == true"
        @click.stop="drawerAction"
        class="ml-2"
      />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :expand-on-hover="miniVariant"
      app
      color="secondary darken-3"
      dark
      @mouseover.native="hoverDrawer"
      @mouseleave.native="leaveDrawer"
    >
      <v-list :shaped="!miniVariant || hoverMini" dense nav class="py-0">
        <v-list-item two-line :class="miniVariant && 'px-0'">
          <v-list-item-avatar>
            <img src="../assets/logo.png" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ companyName }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item-group v-model="item" color="accent">
          <span v-for="item in items" :key="item.title">
            <v-list-item
              link
              @click="goTo(item.path)"
              v-if="$store.getters.hasAnyPermission(item.access)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </span>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <p class="overline mb-0 pa-2 white--text" style="opacity: 0.5;">
          <v-icon x-small v-if="!miniVariant || hoverMini"
            >mdi-source-branch</v-icon
          >
          {{ version }}
        </p>
      </template>
    </v-navigation-drawer>

    <v-main class="fill-height" fluid>
      <slot></slot>
    </v-main>

    <v-footer
      app
      absolute
      color="secondary darken-3"
      class="white--text"
      :inset="true"
      dark
    >
      <span>{{ companyName }} &copy; {{ new Date().getFullYear() }} </span>
    </v-footer>
  </div>
</template>

<script>
import ProfileMenu from "@/components/Utils/ProfileMenu.vue";
export default {
  name: "PageLayout",
  components: {
    ProfileMenu
  },
  data: () => ({
    drawer: null,
    drawerRight: false,
    color: "secondary",
    miniVariant: null,
    hoverMini: false,
    fullScreen: false,
    windowSize: {
      x: 0,
      y: 0
    },
    item: null,
    items: [
      {
        title: "Sucursales",
        icon: "domain",
        path: "/sucursales",
        access: ["branches.show"]
      },
      {
        title: "Empleados",
        icon: "mdi-account-clock-outline",
        path: "/empleados",
        access: ["employees.show"]
      },
      {
        title: "Citas",
        icon: "calendar_today",
        path: "/citas",
        access: ["appointments.show"]
      },
      {
        title: "Clientes",
        icon: "account_box",
        path: "/clientes",
        access: ["clients.show"]
      },
      // { title: "Consulta", icon: "history", path: "/clientes/1/consultas", access:['medical.show consultation']},
      {
        title: "Tratamientos",
        icon: "medical_services",
        path: "/tratamientos",
        access: ["medical_consultations.show","tratamiento.estetico"]
      },
      // { title: "Consultas", icon: "mdi-medical-bag", path: "/consultas" },
      {
        title: "Productos",
        icon: "list_alt",
        path: "/productos",
        access: ["products.show"]
      },
      {
        title: "Lotes",
        icon: "inventory",
        path: "/lotes",
        access: ["lots.show"]
      },
      {
        title: "Ventas",
        icon: "shopping_cart",
        path: "/ventas",
        access: ["sales.show"]
      },
      {
        title: "Caja",
        icon: "point_of_sale",
        path: "/paysales",
        access: ["payments.show"]
      },
      {
        title: "Transferencias",
        icon: "transform",
        path: "/transferencias",
        access: ["transfers.show"]
      },
      {
        title: "Reporte de Ventas",
        icon: "mdi-file-chart",
        path: "/reportedeventas",
        access: ["sales_report.create"]
      }
    ],
    version: process.env.VUE_APP_VERSION,
    companyName: process.env.VUE_APP_NAME
  }),
  mounted() {
    this.onResize();
  },
  computed: {
    title() {
      return this.$route.meta.title || this.$route.name;
    },
    drawerView() {
      return this.$route.meta.drawer || false;
    }
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    menuAction() {
      if (this.windowSize.x <= 1264) {
        this.drawer = !this.drawer;
      } else {
        this.miniVariant = !this.miniVariant;
      }
    },
    hoverDrawer() {
      if (this.miniVariant) {
        this.hoverMini = true;
      }
    },
    leaveDrawer() {
      if (this.miniVariant) {
        this.hoverMini = false;
      }
    },
    drawerAction() {
      this.$store.state.drawerRight = !this.$store.state.drawerRight;
    },
    goTo(ruta) {
      this.$router.push(ruta).catch(err => {
        err;
      });
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.PageLayout {
  width: 100%;
  height: 100%;
  .v-toolbar__extension {
    padding: 0px !important;
  }
}
</style>
