<template>
  <v-sheet>
    <v-toolbar flat>
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
        hoy
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="$emit('prevmonth')">
        <v-icon small> mdi-chevron-left </v-icon>
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="$emit('nextmonth')">
        <v-icon small> mdi-chevron-right </v-icon>
      </v-btn>
      <v-toolbar-title v-if="titulo">
        {{titulo.title}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
            <span>{{ typeToLabel[type] }}</span>
            <v-icon right> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="type = 'day'">
            <v-list-item-title v-html="typeToLabel.day">  </v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'week'">
            <v-list-item-title v-html="typeToLabel.week"></v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'month'">
            <v-list-item-title v-html="typeToLabel.month"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </v-sheet>
</template>
<script>
import { mapFields } from "vuex-map-fields";
export default {
  props:["titulo"],
  data: () => ({
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Dia",
    },
  }),
  computed: {
    ...mapFields({
      type: "appointments.calendar.type",
      focus:"appointments.calendar.modelCalendar"
    }),
  },
  methods: {
    setToday() {
      this.focus = "";
    },
  },
};
</script>