<template>
  <v-data-iterator
    :items="products"
    :search="search"
    :sort-by="sortBy.toLowerCase()"
    :sort-desc="sortDesc"
    :items-per-page="itemsPerPage"
    :page="page"
  >
    <template v-slot:header>
      <v-toolbar dark color="primary" class="mb-1">
        <v-text-field
          v-model="search"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
        ></v-text-field>
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn large depressed color="primary" @click='drawer =  true'>
              <v-icon>shopping_cart</v-icon>
            </v-btn>
          <v-spacer></v-spacer>
          <v-btn-toggle v-model="sortDesc" mandatory>
            <v-btn large depressed color="primary" :value="false">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn large depressed color="primary" :value="true">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-toolbar>
    </template>

    <template v-slot:default="props">
      <v-row>
        <v-col v-for="item in props.items" :key="item.id" cols="12">
          <ProductItem :product="item"></ProductItem>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import ProductItem from "./ProductItem";

export default {
  components: { ProductItem },
  data() {
    return {
      page: 1,
      itemsPerPage: 2,
      search: "",
      filter: {},
      sortDesc: false,
      sortBy: "name",
    };
  },
  computed: {
    ...mapFields({
      products: "transfers.products",
      drawer:"transfers.drawer"
    }),
  },
  methods: {},
};
</script>

<style>
</style>