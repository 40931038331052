<template>
  <v-card
    class="mx-auto"
  >
    <v-card-text >
      <div> {{title}} </div>
      <p class="display-1 text--primary">
        {{amount}}
      </p>
      <p>CLINICA</p>
    </v-card-text>
  </v-card>
</template>
<script>

  export default {
    props:["title","amount"],
    data: () => ({
      
    }),
  }
</script>