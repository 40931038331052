<template>
  <v-container class="Sales" fluid>
    <h1 class="text-center mb-0">Caja</h1>
    <CashRegister></CashRegister>
    <SaleInfoModal></SaleInfoModal>
    <ConfirmCancelModal callback="ReloadCashRegister"></ConfirmCancelModal>
  </v-container>
</template>

<script>
import CashRegister from "@/components/Sales/CashRegister/Index";
import SaleInfoModal from "@/components/Sales/SaleInfoModal";
import ConfirmCancelModal from "@/components/Sales/ConfirmCancelModal";

export default {
  name: "PaySales",
  components: {
    CashRegister,
    SaleInfoModal,
    ConfirmCancelModal
  }
};
</script>

<style lang="scss" scoped></style>
