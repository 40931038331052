<template>
  <v-flex>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="date"
          :label="etiqueta"
          prepend-icon="event"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        v-model="date"
        min="2010-01-01"
        locale="es-419"
        @change="actualizarModelo()"
      ></v-date-picker>
    </v-menu>
  </v-flex>
</template>
<script>
export default {
  props: ["modelo", "label"],
  data() {
    return {
      date: this.modelo,
      menu: false,
      etiqueta: this.label,
    };
  },
  watch: {

    modelo: function (newVal) {
      this.date = newVal;
    },
  },
  methods: {
    actualizarModelo: function (date) {
      this.$refs.menu.save(date);
      this.$emit("update:modelo", this.date);
    },
  },
};
</script>
