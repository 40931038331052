<template>
  <v-card outlined>
    <v-card-title class="justify-center body-2 blue-grey lighten-5 px-4 py-3">
      <strong class="grey--text text--darken-2">FOLIO</strong>
    </v-card-title>
    <v-card-text
      class="py-1 font-weight-bold text-uppercase subtitle-1 text-center primary--text text--darken-1"
    >
      {{ $store.state.sales.sale ? $store.state.sales.sale.folio : "00000" }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SaleFolio"
};
</script>

<style lang="scss" scoped></style>
