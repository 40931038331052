<template>
  <v-card class="ProductItem" :style="[colorBorder, colorHover]" outlined>
    <v-chip class="rounded-sm float-left" label x-small :color="colorChip">
      {{ product.type }}
    </v-chip>
    <div class="d-flex justify-center align-center w-100 mt-8">
      <v-avatar :color="colorImg" size="164" tile>
        <v-img
          v-if="product.image"
          :src="`${product.image}`"
          alt="NOT FOUND"
        ></v-img>
        <v-icon v-else x-large>mdi-image-off</v-icon>
      </v-avatar>
    </div>
    <v-card-subtitle class="text-center ma-1 pa-0 text-caption">
      {{ product.category }}
    </v-card-subtitle>
    <v-card-title class="pt-0">
      <p class="product-name">
        {{ product.name }}
      </p>
    </v-card-title>

    <v-card-subtitle class="pb-0 subtitle-2">
      $ {{ product.price }}
    </v-card-subtitle>

    <v-card-text class="caption pb-0">
      {{ product.description }}
    </v-card-text>
    <template v-for="lot in product.lots">
      <v-layout :key="lot.id">
        <v-row justify="center">
          <v-col cols="10">
            <v-text-field
              v-model="lot.transfer"
              :label="labelLot(lot)"
              :disabled ="!emptyStock(lot)" 
              dense
              outlined
              :hint="'Lote: '+lot.code"
              persistent-hint
            >
              <template v-slot:append-outer>
                <v-icon :disabled="conditions" @click="sendToCart(lot)">
                  mdi-send
                </v-icon>
              </template></v-text-field
            >
          </v-col>
        </v-row>
      </v-layout>
    </template>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
    },
  },
  data: () => ({
    server: process.env.VUE_APP_API_SERVER,
    rules: {
      required: (value) => !!value || "Inserte una cantidad a transferir.",
    },
  }),
  computed: {
    ...mapFields({
      branchOld: "transfers.branchFromID",
      branchNew: "transfers.branchToID",
      drawer:"transfers.drawer"
    }),
    ...mapMutations({
      addToCart: "ADD_TO_CAR_TRANSFER", // `this.$store.commit('ADD_TO_CAR_TRANSFER', amount)` -> this.addToCart(amount)
    }),
    conditions() {
      return this.branchNew == null || this.branchOld === this.branchNew;
    },
    colorBorder() {
      return {
        borderColor: `var(${
          this.product.type == "PRODUCTO"
            ? "--v-primary-darken2"
            : "--v-accent-darken2"
        })`,
      };
    },
    colorChip() {
      return this.product.type == "PRODUCTO"
        ? "primary darken-2"
        : "accent darken-2";
    },
    colorHover() {
      return {
        "--color-hvr": `var(${
          this.product.type == "PRODUCTO"
            ? "--v-primary-darken2"
            : "--v-accent-darken2"
        })`,
      };
    },
    colorImg() {
      return this.product.image ? "transparent" : "blue-grey lighten-5";
    },
  },
  methods: {
    labelLot(lot) {
      return lot.stock > 0 ? `Stock: ${lot.stock}` : "Sin inventario";
    },
    emptyStock(lot) {
      return lot.stock > 0? true: false;
    },
    sendToCart(lot) {
      if (lot.transfer) {
        let loteProducto = {};
        loteProducto.quantity = lot.transfer;
        loteProducto.stock = lot.transfer;
        loteProducto.current_stock = lot.transfer;
        loteProducto.lot_id = lot.id;
        loteProducto.branch_id = this.branchNew;
        loteProducto.branch_old_id = this.branchOld;
        loteProducto.branch_new_id = this.branchNew;

        this.$store.commit("ADD_TO_CAR_TRANSFER", Object.assign({}, lot));
        axios
          .post(`/api/products/transfer/${lot.pivot_id}`, loteProducto)
          .then((response) => {
            lot.transfer = null;
            lot.stock = response.data;
            this.drawer =true;
            this.$snotify.success("Agregado correctamente")
          })
          .catch((e) => this.$snotify.error("Error" + e));
      } else {
        this.$snotify.warning("Escriba un numero a transferir");
      }
    },
  },
};
</script>

<style lang="scss">
.ProductItem {
  --color-hvr: var(--v-primary-darken2);

  margin-right: auto;
  margin-left: auto;
  transition: all 0.5s;

  &:hover {
    box-shadow: 0px 0px 8px var(--color-hvr);
  }

  &:focus {
    box-shadow: 0px 0px 8px var(--color-hvr);
  }

  .v-card__text {
    max-height: 72px !important;
    text-align: justify;
    display: block;
    display: -webkit-box;
    line-height: 1.1;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .product-name {
    max-height: 40px !important;
    text-align: justify;
    margin-bottom: 0;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.1;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    display: block;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }
}
</style>
