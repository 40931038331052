<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="3">
          <v-avatar class="profile" color="grey" size="164">
            <v-img
              src="https://ui-avatars.com/api/?name=Luis Vasquez&color=7F9CF5&background=EBF4FF"
            ></v-img>
          </v-avatar>
          <br />
          <v-chip class="ma-2"> # {{ employee.id }} </v-chip>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="employee.name"
            :counter="191"
            :rules="[(v) => !!v || 'El Nombre es requerido']"
            label="Nombre(s)"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="employee.lastName"
            :counter="191"
            :rules="[(v) => !!v || 'El Primer Apellido es requerido']"
            label="Primer Apellido"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="employee.maidenName"
            :counter="191"
            :rules="[(v) => !!v || 'El Segundo Apellido es requerido']"
            label="Segundo Apellido"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-combobox
            v-model="employee.sex"
            :items="sexItems"
            label="Genero"
            :rules="[(v) => !!v || 'El género es requerido']"
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="employee.birthday"
            :rules="[(v) => !!v || 'La Fecha de Nacimiento es requerida']"
            label="Fecha de Nacimiento"
            required
            @click="dialog = true"
            @focus="dialog = true"
          ></v-text-field>
          <v-dialog v-model="dialog" width="500">
            <v-date-picker
              v-model="employee.birthday"
              label="Fecha de Nacimiento"
            ></v-date-picker>
            <v-btn color="primary" @click="dialog = false">
              Ok
            </v-btn>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="employee.bloodType"
            :items="bloodTypes"
            :rules="[(v) => !!v || 'El Tipo de Sangre es requerido']"
            label="Tipo de Sangre"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="employee.allergies"
            :rules="[(v) => !!v || 'Las Alergías son requeridas']"
            label="Alergias"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="employee.address"
            :counter="191"
            :rules="[(v) => !!v || 'La Dirección es requerida']"
            label="Dirección"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="employee.phone"
            :counter="10"
            :rules="[(v) => !!v || 'El Teléfono es requerido']"
            label="Teléfono"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="employee.mobilPhone"
            :counter="10"
            :rules="[(v) => !!v || 'El Teléfono Móvil es requerido']"
            label="Teléfono Móvil"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="employee.email"
            :counter="191"
            :rules="[(v) => !!v || 'El Email es requerido']"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="employee.state"
            :items="states"
            label="Estado"
            item-text="name"
            :rules="[(v) => !!v || 'El Estado es requerido']"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="employee.maritalStatus"
            :items="maritalItems"
            :rules="[(v) => !!v || 'El Estado Civil es requerido']"
            label="Estado Civil"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="employee.employment"
            :counter="191"
            :rules="[(v) => !!v || 'El Puesto es requerido']"
            label="Puesto"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="employee.branch_id"
            :rules="[(v) => !!v || 'La Sucursal es requerida']"
            label="Sucursal"
            required
            :items="branches"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
      </v-row>
      <v-btn :disabled="!valid" color="primary" class="mr-4" @click="validate" :loading="saving">
        Guardar
      </v-btn>
    </v-container>
    <!-- <v-btn color="error" class="mr-4" @click="reset">
                    Reset Form
                  </v-btn>
                  <v-btn color="warning" @click="resetValidation">
                    Reset Validation
                  </v-btn> -->
  </v-form>
</template>

<script>
import EventBus from "../../EventBus";

export default {
  name: "EmployeeForm",

  data: () => ({
    dialog: false,
    valid: true,
    sexItems: ["Masculino", "Femenino"],
    states: [
      { key: "AGS", name: "AGUASCALIENTES" },
      { key: "BC", name: "BAJA CALIFORNIA" },
      { key: "BCS", name: "BAJA CALIFORNIA SUR" },
      { key: "CHI", name: "CHIHUAHUA" },
      { key: "CHS", name: "CHIAPAS" },
      { key: "CMP", name: "CAMPECHE" },
      { key: "CMX", name: "CIUDAD DE MEXICO" },
      { key: "COA", name: "COAHUILA" },
      { key: "COL", name: "COLIMA" },
      { key: "DGO", name: "DURANGO" },
      { key: "GRO", name: "GUERRERO" },
      { key: "GTO", name: "GUANAJUATO" },
      { key: "HGO", name: "HIDALGO" },
      { key: "JAL", name: "JALISCO" },
      { key: "MCH", name: "MICHOACAN" },
      { key: "MEX", name: "ESTADO DE MEXICO" },
      { key: "MOR", name: "MORELOS" },
      { key: "NAY", name: "NAYARIT" },
      { key: "NL", name: "NUEVO LEON" },
      { key: "OAX", name: "OAXACA" },
      { key: "PUE", name: "PUEBLA" },
      { key: "QR", name: "QUINTANA ROO" },
      { key: "QRO", name: "QUERETARO" },
      { key: "SIN", name: "SINALOA" },
      { key: "SLP", name: "SAN LUIS POTOSI" },
      { key: "SON", name: "SONORA" },
      { key: "TAB", name: "TABASCO" },
      { key: "TLX", name: "TLAXCALA" },
      { key: "TMS", name: "TAMAULIPAS" },
      { key: "VER", name: "VERACRUZ" },
      { key: "YUC", name: "YUCATAN" },
      { key: "ZAC", name: "ZACATECAS" },
    ],
    maritalItems: [
      "SOLTERO(A)",
      "CASADO(A)",
      "UNIÓN LIBRE",
      "DIVORCIADO(A)",
      "VIUDO(A)",
      "SEPARADO",
    ],
    bloodTypes: ["ORH+", "ORH-", "A+", "A-", "B+", "B-", "AB+", "AB-"],
    saving: false,
  }),

  computed: {
    employee() {
      return this.$store.state.employees.employee;
    },
    branches() {
      return this.$store.state.branches.branches;
    },
  },

  mounted() {
    EventBus.$on("EMPLOYEES::employeeUpdated", () => {
      this.$snotify.success("Empleado actualizado correctamente");
    });
    EventBus.$on("EMPLOYEES::errorMessage", () => {
      this.$snotify.error("Ocurrio un error al actualizar el empleado");
    });
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.saving=true;
        if (this.employee.id != "" && this.employee.id > 0) {
          this.$store.dispatch("updateEmployee")
          .then(({data}) => {
            this.$store.commit('SET_EMPLOYEE', data.data)
            this.$snotify.success("Actualizado correctamente");
          })
          .catch((e) => {
            if (e.response.status == 422) {
              this.$snotify.error(JSON.stringify(Object.values(e.response.data.errors)).replaceAll(/[{}'"[\]]/g,''), "Error validando");
            } else
              this.$snotify.error(e.response.data.message,'Error');
          })
          .finally(()=>{this.saving=false});
        } else {
          this.$store.dispatch("storeEmployee")
          .then(({data})=>{
            this.$store.commit('SET_EMPLOYEE', data.data)
            this.$snotify.success("Guardado correctamente");
          })
          .catch((e) => {
            if (e.response.status == 422) {
              this.$snotify.error(JSON.stringify(Object.values(e.response.data.errors)).replaceAll(/[{}'"[\]]/g,''), "Error validando");
            } else
              this.$snotify.error(e.response.data.message,'Error');
          })
          .finally(()=>{this.saving=false});
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
