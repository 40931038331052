import axios from "axios";

export const SET_LOTS = "SET_LOTS";
export const SET_LOT = "SET_LOT";

export default {
  state: {
    lots: [],
    loading: false,
    saveLoading: false,
    modal: false,
    detailModal: false,
    lot: {}
  },
  mutations: {
    startLotsLoading(state) {
      state.loading = true;
    },
    stopLotsLoading(state) {
      state.loading = false;
    },
    startLotsLoadingSave(state) {
      state.saveLoading = true;
    },
    stopLotsLoadingSave(state) {
      state.saveLoading = false;
    },
    openModalLot(state) {
      state.modal = true;
    },
    closeModalLot(state) {
      state.modal = false;
    },
    openDetailModalLot(state) {
      state.detailModal = true;
    },
    closeDetailModalLot(state) {
      state.detailModal = false;
    },
    [SET_LOTS](state, lots) {
      state.lots = lots;
    },
    [SET_LOT](state, lot) {
      state.lot = lot;
    }
  },
  actions: {
    LoadLots({ commit }) {
      commit("startLotsLoading");
      return axios
        .get(`/api/lots`)
        .then(r => {
          commit("SET_LOTS", r.data.data);
          commit("stopLotsLoading");
        })
        .catch(e => {
          console.log(e);
          commit("stopLotsLoading");
        });
    },
    StoreLot({ commit }, lot) {
      commit("startLotsLoadingSave");
      return axios
        .post(`/api/lots`, lot)
        .then(r => {
          commit("stopLotsLoadingSave");
          return r.data;
        })
        .catch(e => {
          commit("stopLotsLoadingSave");
          return {
            error: true,
            msg: e
          };
        });
    },
    updateStockLot(state, payload){
      return axios
      .put(`api/lots/${payload.id}/stock`,{newStock : payload.stock})
    }
  },
  getters: {
    getLots: state =>
      state.lots.map((e, i) => ({
        ...e,
        index: i + 1
      }))
  }
};
