<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="branch.name"
            :counter="191"
            :rules="[(v) => !!v || 'El nombre es requerido']"
            label="Nombre"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="branch.address"
            :counter="191"
            :rules="[(v) => !!v || 'La dirección es requerida']"
            label="Dirección"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="branch.rfc"
            :counter="12"
            :rules="[(v) => !!v || 'El RFC es requerido', (v)=> v && v.length == 12 || 'RFC de persoma moral 12 caracteres']"
            label="RFC"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="branch.phone"
            :counter="10"
            :rules="[(v) => !!v || 'El Teléfono es requerido', (v)=> v && v.length == 10 || 'Telefono a 10 digitos']"
            label="Teléfono"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn class="mr-4" @click="cancelForm">
        Cancelar
      </v-btn>
      <v-btn :disabled="!valid" color="primary" class="mr-4" @click="storeForm" :loading="saving">
        Guardar
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import EventBus from "../../EventBus/index";

export default {
  name: "BranchForm",

  data: () => ({
    valid: false,
    saving: false,
  }),

  computed: {
    branch() {
      return this.$store.state.branches.branch;
    },
  },

  methods: {
    cancelForm() {
      EventBus.$emit("Branches::modalHide");
    },
    storeForm() {
      this.$refs.form.validate();
      if (this.valid) {
        this.saving=true
        if (this.branch.id != "" && this.branch.id > 0) {
          this.$store.dispatch("updateBranch").then(()=>{this.saving=false});
        } else {
          this.$store.dispatch("storeBranch").then(()=>{this.saving=false});
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>