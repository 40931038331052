<template>
  <v-form @submit.prevent="">
    <v-col cols="12">
      <h4>Topografía</h4>
    </v-col>
    <v-col cols="12">
      <ckeditor
        :editor="CKEditor.editor"
        :config="CKEditor.editorConfig"
        v-model="physicalExploration.topography"
        :disabled="readOnly"
      ></ckeditor>
    </v-col>
    <v-col cols="12">
      <h4>Morfología</h4>
    </v-col>
    <v-col cols="12">
      <ckeditor
        :editor="CKEditor.editor"
        :config="CKEditor.editorConfig"
        v-model="physicalExploration.morphology"
        :disabled="readOnly"
      ></ckeditor>
    </v-col>
    <v-col cols="12">
      <h4>Restos de piel y anexos</h4>
    </v-col>
    <v-col cols="12">
      <ckeditor
        :editor="CKEditor.editor"
        :config="CKEditor.editorConfig"
        v-model="physicalExploration.remnants_skin_and_attachments"
        :disabled="readOnly"
      ></ckeditor>
    </v-col>
  </v-form>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "ckeditor5-build-classic-spanish-advanced";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PhysicalExploration",
  props:['readOnly'],
  components: {
    ckeditor: CKEditor.component,
  },
  mounted() {
    this.$store.dispatch("fetchPhysicalExploration");
  },
  computed: {
    ...mapFields({
      physicalExploration: "consultation.physicalExploration",
    }),
  },
  data() {
    return {
      CKEditor: {
        editor: ClassicEditor,
        editorConfig: {
          removePlugins: [
            // "Essentials",
            "CKFinderUploadAdapter",
            "Alignment",
            "Autoformat",
            // "Bold",
            "Italic",
            "Underline",
            "Strikethrough",
            "BlockQuote",
            "CKFinder",
            "EasyImage",
            "Heading",
            "Image",
            "ImageCaption",
            "ImageStyle",
            "ImageToolbar",
            "ImageUpload",
            "Indent",
            "Link",
            // "List",
            "MediaEmbed",
            // "Paragraph",
            "PasteFromOffice",
            "Table",
            "TableToolbar",
            "TableProperties",
            "TableCellProperties",
            "Font",
          ],
          toolbar: [
            "bold",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },
};
</script>

<style></style>
